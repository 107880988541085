import { Helmet } from "react-helmet-async";
import { useRecoilValue } from "recoil";

import { APP_NAME } from "../../../constants";

import { DocumentTitleState } from "../../../state/DocumentTitle";

export interface DocumentTitleProps {}

/**
 * Set the document title using `react-helmet` using the
 * value in the DocumentTitleAtom.
 */
export const DocumentTitle = (props: DocumentTitleProps) => {
  const title = useRecoilValue(DocumentTitleState);
  return (
    <Helmet>
      <title>
        {title === APP_NAME || title.length === 0
          ? APP_NAME
          : `${title} - ${APP_NAME}`}
      </title>
    </Helmet>
  );
};
