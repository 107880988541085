import { Link as InternalLink } from "react-router-dom";
import clsx from "clsx";

import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import { APP_NAME } from "../../../constants";
import version from "../../../version.json";

import useStyles from "./styles";

/**
 * Show application name and version information.
 */
export const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className={clsx(classes.links, classes.text)}>
        <Typography variant="body2">
          {APP_NAME} v{version.version}
        </Typography>
        <span className={classes.spacer}>•</span>
        <Typography
          variant="body2"
          className={clsx(classes.link, classes.text)}
          component={InternalLink}
          to="/changelog"
        >
          Change Log
        </Typography>
        {/* <span className={classes.spacer}>•</span> */}
        {/* <Typography
          variant="body2"
          className={clsx(classes.link, classes.text)}
          component={InternalLink}
          to="/tos"
        >
          Terms of Use
        </Typography> */}
      </div>
      <Typography variant="body2" className={classes.text}>
        &copy; {new Date().getFullYear()}{" "}
        <Link className={classes.text} href="https://monash.edu">
          Monash University
        </Link>
      </Typography>
    </footer>
  );
};
