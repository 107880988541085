import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";

import { dateUtils } from "../../../../utils";

export interface TOS2021MAYProps {
  open?: boolean;
  onClose?: (response: string) => void;
}

/**
 * Terms of service
 * 2021 21 May
 */
export const TOS2021MAY = function (props: TOS2021MAYProps) {
  const { open, onClose } = props;
  return (
    <Dialog open={Boolean(open)} maxWidth="sm">
      <DialogTitle>Terms Of Service</DialogTitle>
      <DialogContent>
        <DialogContentText>
          MSend is not to be used to send any{" "}
          <b>Personally Identifiable Information</b> (PII) including any information
          that can be used to distinguish or trace an individual's identity.
        </DialogContentText>
        <DialogContentText>
          These requirements are inline with the Terms Of Service of{" "}
          <b>Twillo SendGrid</b>, a service used by MSend. By using MSend you fully
          accept their Terms Of Service, which can be found here:{" "}
          <Link href="https://www.twilio.com/legal/tos">
            www.twilio.com/legal/tos
          </Link>
        </DialogContentText>
        <DialogContentText variant="body2" color="textSecondary">
          Last updated {dateUtils.format(new Date("2021-05-19"), "d MMM YYYY")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onClose?.("accepted")}
        >
          ACCEPT
        </Button>
      </DialogActions>
    </Dialog>
  );
};
