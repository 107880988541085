import { useParams } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import ReturnIcon from "@material-ui/icons/Undo";

import { CLogger } from "../../../utils";

import { useSnack } from "../../../hooks/snack";
import { useBoolean } from "../../../hooks/state";
import { useBackToDraft } from "../../../hooks/actions/email";

import ConfirmDialog from "../../dialogs/ConfirmDialog";

import { useActionStyles } from "../styles";

const log = CLogger.category("components/editorToolbar/EditorCancelReviewAction");

export interface EditorCancelReviewActionProps {
  /**
   * Disable this action.
   */
  disabled?: boolean;
}

/**
 * This action moves an email currently in review mode back to draft mode.
 */
export const EditorCancelReviewAction = ({
  disabled,
}: EditorCancelReviewActionProps) => {
  const classes = useActionStyles();

  const { emailId } = useParams();
  const { pushClosableSnack, pushErrorSnack } = useSnack();
  const backToDraft = useBackToDraft(emailId);

  const [show, open, close] = useBoolean();

  /**
   * Create a new draft and redirect the user to view the draft.
   */
  const backToDraftHandler = async () => {
    try {
      await backToDraft();
      pushClosableSnack("Email moved back to draft mode.");
    } catch (error: any) {
      pushErrorSnack(`Failed to move email back to draft. ${error.message}`);
      log({
        level: "error",
        message: `Error while moving email back to draft: ${error.message}`,
        data: error,
      });
    }
  };

  return (
    <>
      <Tooltip title="Back to draft" placement="bottom">
        <div>
          <IconButton
            className={classes.button}
            classes={{ disabled: classes.disabled }}
            disabled={disabled}
            onClick={open}
          >
            <ReturnIcon />
          </IconButton>
        </div>
      </Tooltip>
      <ConfirmDialog
        title="Back to draft?"
        open={show}
        confirmLabel="Back to draft"
        onConfirm={() => {
          backToDraftHandler?.();
          close();
        }}
        onClose={close}
      >
        Are you sure you want to put this email back in draft mode? This will allow
        you to edit the email but it will need to be reviewed again before it can be
        sent.
      </ConfirmDialog>
    </>
  );
};
