import clsx from "clsx";

import { CHANGELOG } from "../../../constants";
import { dateUtils } from "../../../utils";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import PageLayout from "../../../components/core/PageLayout";
import PageTitle from "../../../components/core/PageTitle";
import Footer from "../../../components/core/Footer";

import useStyles from "./styles";

/**
 * Shows the system changelog.
 */
export const ChangelogPage = () => {
  const classes = useStyles();
  return (
    <PageLayout>
      <div>
        <PageTitle width="md">Change Log</PageTitle>
        <Container maxWidth="md">
          {CHANGELOG.map(({ id, type, date, features, changes, fixes }, index) => (
            <Paper key={id} className={classes.paper}>
              <Typography variant={type === "patch" ? "h6" : "h5"}>
                <b>Release v{id}</b>
                {index === 0 && (date ? " ✨" : " ⏳")}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {date ? dateUtils.format(date, "dddd, D MMMM YYYY") : "Unreleased"}
              </Typography>
              {[
                { key: "Feature", items: features },
                { key: "Change", items: changes },
                { key: "Fix", items: fixes },
              ]
                .filter((x) => x.items)
                .map(({ key, items }) => (
                  <div key={key}>
                    <List disablePadding>
                      {items!.map((item) => (
                        <ListItem
                          key={item}
                          disableGutters
                          className={classes.listItem}
                        >
                          <ListItemText
                            primaryTypographyProps={{
                              className: classes.listItemText,
                            }}
                            primary={
                              <>
                                <Typography
                                  variant="caption"
                                  component="span"
                                  className={clsx(
                                    classes.tag,
                                    classes[key.toLowerCase()]
                                  )}
                                >
                                  {key}
                                </Typography>
                                <span>{item}</span>
                              </>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                ))}
            </Paper>
          ))}
        </Container>
      </div>
      <Footer />
    </PageLayout>
  );
};
