import { TagConfig } from "../constants";

export class TagUtils {
  /**
   * Determine if a user can see a tag based on their groups.
   *
   * @param config The tag config.
   * @param groups The user's groups.
   */
  static allowTagBasedOnGroups(config: TagConfig, groups: string[]) {
    if (!config.restrictTo) return true;
    return config.restrictTo.every((group) => groups.includes(group));
  }

  /**
   * Convert a tag config mapping to a list of filter options.
   *
   * @param mapping The mapping of the enum values to `TagConfig`.
   */
  static enumMappingToFilterOptions<T extends string>(
    mapping: { [key in T]: TagConfig },
    groups: string[] = []
  ) {
    return Object.keys(mapping)
      .filter((tag) => this.allowTagBasedOnGroups(mapping[tag as T], groups))
      .map((tag) => ({
        key: tag as T,
        value: tag as T,
        label: mapping[tag as T].label,
      }));
  }
}
