import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

// import useStyles from "./styles";

export interface AccessHelpDialogProps {
  /**
   * Whether the dialog is open.
   *
   * @default false
   */
  open?: boolean;

  /**
   * Callback function to close the dialog.
   */
  onClose?: () => void;
}

/**
 * Shows a help message to tell users how to access MSend.
 */
export function AccessHelpDialog(props: AccessHelpDialogProps) {
  const { open = false, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>How to access MSend</DialogTitle>
      <DialogContent>
        <DialogContentText>
          MSend is available to Monash staff on a team-by-team basis. If you team is
          already using MSend, please contact your team leader for access.
        </DialogContentText>
        <DialogContentText>
          Otherwise, if you would like to start using MSend for your team, please
          contact{" "}
          <Link href="https://servicedeskonline.monash.edu">Service Desk</Link> to
          start the onboarding process.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CLOSE</Button>
      </DialogActions>
    </Dialog>
  );
}
