import loadable from "@loadable/component";
import LinearProgress from "@material-ui/core/LinearProgress";

/**
 * Dynamically loaded version of `AdminPage`.
 */
const LoadablePage = loadable(() => import(/* webpackChunkName: "Admin" */ "."), {
  fallback: <LinearProgress />,
});

export default LoadablePage;
