import { useState, useEffect } from "react";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Alert from "@material-ui/lab/Alert";

import LockIcon from "@material-ui/icons/Lock";

import { APP_NAME } from "../../../constants";
import { EnvUtils, SignInUtils } from "../../../utils";

import { useBoolean } from "../../../hooks/state";

import MockLoginDialog from "../../../components/dialogs/MockLoginDialog";
import AccessHelpDialog from "../../../components/dialogs/AccessHelpDialog";

import useStyles from "./styles";

export interface SignInPageProps {
  /**
   * Show a message that the user must login to see this page.
   */
  showLoginMessasge?: boolean;
}

/**
 * Shows a button for the user to sign in.
 */
export const SignInPage = ({ showLoginMessasge }: SignInPageProps) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [errors, setErrors] = useState<{ code: string; description: string }[]>([]);
  const [showMock, openMock, closeMock] = useBoolean();
  const [showAccessHelp, openAccessHelp, closeAccessHelp] = useBoolean();

  // Parse any errors found in the URL.
  useEffect(() => {
    const { errors } = parse(window.location.search);
    if (Array.isArray(errors)) {
      setErrors(
        errors
          .filter((error): error is string => typeof error === "string")
          .map((error) => {
            const [code, description] = error.split(",");
            return { code, description };
          })
      );
    } else if (typeof errors === "string") {
      const [code, description] = errors.split(",");
      setErrors([{ code, description }]);
    }
  }, [setErrors]);

  /**
   * Redirects the user to the login URL.
   */
  const redirectToLogin = () => {
    SignInUtils.saveRedirectPath(pathname);
    window.location.href = "/login";
  };

  return (
    <Grid container>
      <Grid item xs={12} md={7} className={classes.login}>
        <Paper className={classes.paper}>
          <img
            src="/images/monashlogo.png"
            alt="monash university"
            className={classes.logo}
          />
          <Typography variant="h5" className={classes.title}>
            Welcome to <b>{APP_NAME}</b>
          </Typography>
          {showLoginMessasge && (
            <Alert
              variant="standard"
              severity="info"
              icon={<LockIcon />}
              className={classes.alert}
            >
              You must sign in to see this page.
            </Alert>
          )}
          {errors?.map((error) => (
            <Alert
              key={error.code}
              variant="standard"
              severity="error"
              className={classes.alert}
            >
              {error.description}{" "}
              <Typography variant="caption" color="textSecondary">
                {error.code}
              </Typography>
            </Alert>
          ))}
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={redirectToLogin}
          >
            Login via Monash SSO
          </Button>
          {EnvUtils.development && (
            <Button
              className={classes.mock}
              variant="outlined"
              color="primary"
              size="large"
              fullWidth
              onClick={openMock}
            >
              Mock Login (development only)
            </Button>
          )}
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.subtitle}
          >
            Need access to MSend?{" "}
            <Link href="#" onClick={openAccessHelp}>
              Find out how.
            </Link>
          </Typography>
          <Divider className={classes.divider} />
          <Typography variant="body2" color="textSecondary">
            &copy; {new Date().getFullYear()}{" "}
            <Link href="https://monash.edu">Monash&nbsp;University</Link>
          </Typography>
        </Paper>
      </Grid>
      <Hidden smDown>
        <Grid item md={5} className={classes.graphic}>
          <img src="/images/emails.svg" alt="email" className={classes.image} />
        </Grid>
      </Hidden>
      <MockLoginDialog open={showMock} onClose={closeMock} />
      <AccessHelpDialog open={showAccessHelp} onClose={closeAccessHelp} />
    </Grid>
  );
};
