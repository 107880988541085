import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    circular: {
      margin: theme.spacing(2),
    },
    linear: {
      width: 100,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    loading: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);
