import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { HorizontalScrollerProps } from "./HorizontalScroller";

const DEFAULT_FADE_WIDTH = 60;

export default makeStyles<Theme, HorizontalScrollerProps>((theme) =>
  createStyles({
    container: {
      maxWidth: "100%",
      position: "relative",
    },
    scroller: ({ fadeWidth = DEFAULT_FADE_WIDTH }) => ({
      "overflowX": "scroll",
      "&::after": {
        content: "''",
        position: "absolute",
        width: fadeWidth < 0 ? 0 : fadeWidth > 100 ? 0 : fadeWidth,
        height: "100%",
        top: 0,
        right: 0,
        background: `linear-gradient(90deg, rgba(0,0,0,0) 0%, ${theme.palette.background.default} 100%)`,
      },
    }),
    content: {
      display: "flex",
      position: "relative",
    },
    spacer: ({ fadeWidth = DEFAULT_FADE_WIDTH }) => ({
      minWidth: fadeWidth < 0 ? 0 : fadeWidth > 100 ? 0 : fadeWidth,
      height: 1,
    }),
  })
);
