import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

// import useStyles from "./styles";

export interface RawDataDialogProps {
  /**
   * Whether the dialog is open.
   *
   * @default false
   */
  open?: boolean;

  /**
   * Callback function to close the dialog.
   */
  onClose?: () => void;

  /**
   * The title to show on the dialog.
   * @default "Raw Data"
   */
  title?: React.ReactNode;

  /**
   * The data to display.
   */
  data?: any;
}

/**
 * This dialog shows a JSON object in its raw form.
 */
export const RawDataDialog = ({
  open = false,
  onClose,
  title = "Raw Data",
  data,
}: RawDataDialogProps) => {
  // const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CLOSE</Button>
      </DialogActions>
    </Dialog>
  );
};
