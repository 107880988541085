import { LIST_MAX } from "../constants";

export class TextUtils {
  /**
   * Return the appropriate singular or plural version of the
   * word based on the given count.
   *
   * If no `plural` is given, it is automatically assumed that
   * a `s` can be appended for its plural form.
   *
   * @param count The count.
   * @param word The singular version of the word.
   * @param plural The pluralised version of the word.
   */
  public static plural(
    count: number,
    word: string,
    plural: string = `${word}s`
  ): string {
    return count === 1 ? word : plural;
  }

  /**
   * Concatenates the count and the word, putting the word in
   * the appropriate plural form using the `pluralise` function.
   *
   * @param count The count.
   * @param word The singular version of the word.
   * @param plural The pluralised version of the word.
   */
  public static pluralWithCount(
    count: number,
    word: string,
    plural: string = `${word}s`
  ): string {
    return `${count} ${TextUtils.plural(count, word, plural)}`;
  }

  /**
   * Capitalise the first letter of the given string.
   *
   * @param word The word to capitalise.
   */
  public static capitalise(word: string): string {
    return (word[0] || "").toUpperCase() + word.slice(1);
  }

  /**
   * Create a greeting according to the current time. If a subject
   * is given, it will be appended to the greeting with a comma.
   * The greeting can also be customised if given as the second
   * argument.
   *
   * @param subject The subject to greet. Optional.
   * @param greeting The greeting phrase. Optional.
   */
  public static greeting(subject?: string, greeting?: string): string {
    let fullGreeting = greeting || "";

    // If there is no greeting, use one based on time
    if (!greeting) {
      const hour = new Date().getHours();
      fullGreeting = "Good evening";
      if (hour < 12) fullGreeting = "Good morning";
      else if (hour < 17) fullGreeting = "Good afternoon";
    }

    // Return the greeting with the subject if given
    if (subject) return `${fullGreeting}, ${subject}`;
    else return fullGreeting;
  }

  /**
   * Truncates a piece of text if it is longer than the specified length.
   *
   * @param text The text to truncate.
   * @param length The length to truncate after. Default of 100.
   * @param truncateToken The token to append to the end of the text to indicate truncation. Default of "...".
   */
  public static truncate(
    text: string,
    length: number = 100,
    truncateToken: string = "..."
  ) {
    if (text.length <= length - truncateToken.length) return text;
    return text.substring(0, length + truncateToken.length) + truncateToken;
  }

  /**
   * Replace all spaces in the given text with non-breaking space characters.
   *
   * @param text The text to transform.
   */
  public static withNonBreakingSpaces(text: string) {
    return text.replace(/ /, String.fromCharCode(160));
  }

  /**
   * Given a space-seperated name, get the initials.
   *
   * @param name The name to get initials for.
   */
  public static initials(name: string) {
    return name
      .toUpperCase()
      .split(" ")
      .slice(0, 2)
      .map((name) => name[0])
      .join("");
  }

  /**
   * Return the value of nullable statistic up to a certain limit.
   *
   * @param value A nullable stat value.
   * @param limit The maximum limit.
   */
  public static numberUpToLimit(
    value: number | undefined,
    limit: number = LIST_MAX
  ) {
    return (value ?? 0) > LIST_MAX - 1 ? `${limit - 1}+` : `${value ?? 0}`;
  }

  /**
   * Convert the given number in bytes into the most suitable unit.
   *
   * @param bytes The number of bytes.
   * @param decimalPlaces The number of decimal places to use.
   */
  public static dataSize(bytes: number, decimalPlaces: number = 1) {
    if (bytes < 1000) return `${bytes} B`;
    const KB = bytes / 1000;
    if (KB < 1000) return `${KB.toFixed(decimalPlaces)} KB`;
    const MB = KB / 1000;
    if (MB < 1000) return `${MB.toFixed(decimalPlaces)} MB`;
    const GB = MB / 1000;
    return `${GB.toFixed(decimalPlaces)} GB`;
  }

  /**
   * Pad a number with leading zeros if it shorter than the specified length.
   *
   * @param value The number to pad.
   * @param length The number of characters to end up with.
   * @param character The character to pad with. Default is "0".
   */
  public static lpad(value: number, length: number, character: string = "0") {
    let padding = "";
    for (
      let i = 0;
      i < length - Math.floor(Math.log10(value <= 0 ? 1 : value)) - 1;
      i++
    ) {
      padding += character;
    }
    return `${padding}${value}`;
  }

  /**
   * Format a recipient's name and email.
   *
   * @param recipient.name The name of the recipient.
   * @param recipient.email The email of the recipient.
   */
  public static formatEmailRecipient(
    recipient: { name?: string; email?: string } | undefined
  ) {
    const { name = "", email = "" } = recipient ?? {};
    return `${name}${email ? ` <${email}>` : ""}`;
  }
}
