import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    container: {
      margin: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        margin: theme.spacing(4),
      },
    },
  })
);
