import clsx from "clsx";

import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Button from "@material-ui/core/Button";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeferedIcon from "@material-ui/icons/HourglassFull";
import BounceIcon from "@material-ui/icons/Warning";
import DroppedIcon from "@material-ui/icons/RemoveCircle";

import useStyles, { stepIconStyles as useStepIconStyles } from "./styles";

import { StepIconProps } from "@material-ui/core/StepIcon";
import { EmailRecipient } from "../../../../../types";

export interface RecipientStatusListItemProps {
  style?: React.CSSProperties;

  /**
   * The recipient to display the status for.
   */
  recipient?: EmailRecipient;

  /**
   * Handler for when the recipient is clicked.
   */
  onClick?: (recipientId: string) => void;
}

// Get the step index to use based on the given events
function getActiveStepIndex(events: string[]) {
  if (events.includes("open")) return 2;
  if (events.includes("dropped")) return 1;
  if (events.includes("bounce")) return 1;
  if (events.includes("deferred")) return 1;
  if (events.includes("delivered")) return 1;
  if (events.includes("processed")) return 0;
  return -1;
}

// Returns a function that renders an icon that
// accepts props as a StepIcon
function getStepIcon(icon = CheckCircleIcon) {
  let Icon = icon;
  return function (props: StepIconProps) {
    const classes = useStepIconStyles();
    const { active, completed, error } = props;
    return (
      <div className={classes.root}>
        {completed || active ? (
          <Icon
            className={clsx(classes.base, {
              [classes.success]: !error && icon !== DeferedIcon,
              [classes.wait]: !error && icon === DeferedIcon,
              [classes.error]: error,
            })}
          />
        ) : (
          <div className={clsx(classes.circle)} />
        )}
      </div>
    );
  };
}

// Get the details on what to render for the intermediate step
function getIntermediateStep(events: string[]) {
  if (events.includes("dropped"))
    return {
      label: "Dropped",
      error: true,
      Icon: getStepIcon(DroppedIcon),
    };
  if (events.includes("bounce"))
    return {
      label: "Bounced",
      error: true,
      Icon: getStepIcon(BounceIcon),
    };
  if (events.includes("deferred") && !events.includes("delivered"))
    return {
      label: "Deferred",
      error: false,
      Icon: getStepIcon(DeferedIcon),
    };
  return {
    label: "Received",
    error: false,
    Icon: getStepIcon(),
  };
}

/**
 * Display a recipient's status email delivery as list item.
 */
export function RecipientStatusListItem(props: RecipientStatusListItemProps) {
  const { recipient, style, onClick } = props;
  const classes = useStyles();

  const { id = "", name, email, events = [] } = recipient ?? {};

  const steps = [
    { label: "Sent", Icon: getStepIcon(), error: false },
    getIntermediateStep(events),
    { label: "Opened", Icon: getStepIcon(), error: false },
  ];

  return (
    <Button
      key={id}
      style={style}
      className={classes.item}
      onClick={() => onClick?.(id)}
    >
      <ListItemText primary={name} secondary={email} />
      <ListItemSecondaryAction className={classes.action}>
        <Stepper
          className={classes.stepper}
          activeStep={getActiveStepIndex(events)}
          alternativeLabel
        >
          {steps.map(({ label, Icon, error }) => (
            <Step key={label}>
              <StepLabel
                classes={{ label: classes.label }}
                StepIconProps={{ error }}
                StepIconComponent={Icon}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </ListItemSecondaryAction>
    </Button>
  );
}
