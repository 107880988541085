import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { EmailUtils } from "../../../utils";
import { VALID_EMAIL_STATES } from "../../../constants";

import { useDocumentTitle } from "../../../state/DocumentTitle";
import { useSetNavigationLocation } from "../../../state/NavigationLocation";

import { useEnforceEmailPath } from "../../../hooks/path";

import AppLayout from "../../../components/core/AppLayout";
import Editor from "../../../components/editor/Editor";
import EmailListFromState from "../../../components/containers/EmailListFromState";
import EditorSidebar from "../../../components/editorSidebar/EditorSidebar";
import EmailToolbar from "../../../components/editorToolbar/EditorToolbar";

import NotFoundPage from "../../core/NotFoundPage";

import useStyles from "./styles";

/**
 * Renders the email list and the currently selected email for editing.
 */
export const EditorV2Page = () => {
  const classes = useStyles();
  const { state = "", emailId = "" } = useParams();

  const title = useMemo(() => EmailUtils.getTitle(state), [state]);
  const location = useMemo(() => EmailUtils.getLocation(state), [state]);

  useDocumentTitle(title);
  useSetNavigationLocation(location);
  useEnforceEmailPath();

  if (!VALID_EMAIL_STATES.includes(state)) return <NotFoundPage />;
  if (state === "view" && !emailId) return <NotFoundPage />;

  return (
    <AppLayout
      toolbarContent={emailId && <EmailToolbar state={state} />}
      drawerContent={<EmailListFromState />}
      openDrawerCaption="Show email list"
      closeDrawerCaption="Collapse email list"
    >
      <div className={classes.editor}>
        <Editor mode={state as any} emailId={emailId} />
        <EditorSidebar mode={state as any} emailId={emailId} />
      </div>
    </AppLayout>
  );
};
