import { useCallback } from "react";
import { FirestoreUtils } from "../../utils";

import { useCurrentUser } from "../../state/FirebaseAuthState";

import {
  Email,
  EmailReview,
  EmailContent,
  EmailAttachment,
  EmailAttachmentPart,
} from "../../../../types";

/**
 * This hook returns a function that can create a draft email
 * with the current user as the owner.
 */
export const useCreateDraft = () => {
  const user = useCurrentUser();
  return useCallback(async () => {
    if (!user) return;
    const ref = FirestoreUtils.createColRef<Email>("emails").doc();
    const draft: Email = {
      id: ref.id,
      owner: user.uid,
      editors: [user.uid],
      reviewers: [],
      contentKeys: [],
      status: "draft",
      version: 0,
      created: FirestoreUtils.timestamp(),
      modified: FirestoreUtils.timestamp(),
    };
    await ref.set(draft);
    return draft;
  }, [user]);
};

/**
 * This hook creates a function that can be used to submit
 * the current email for review.
 *
 * @param emailId The id of the email to submit.
 */
export const useSubmitForReview = (emailId?: string) => {
  return useCallback(async () => {
    if (!emailId) return;
    const ref = FirestoreUtils.createDocRef<Email>(`emails/${emailId}`);
    await ref.set(
      {
        status: "review",
        modified: FirestoreUtils.timestamp(),
        version: FirestoreUtils.increment(1),
      },
      { merge: true }
    );
  }, [emailId]);
};

/**
 * This hook creates a function that moves the specified email
 * back to draft mode.
 *
 * @param emailId The id of the email to update.
 */
export const useBackToDraft = (emailId?: string) => {
  return useCallback(async () => {
    if (!emailId) return;
    const ref = FirestoreUtils.createDocRef<Email>(`emails/${emailId}`);
    await ref.set(
      { status: "draft", modified: FirestoreUtils.timestamp() },
      { merge: true }
    );
  }, [emailId]);
};

/**
 * This hook creates a function that submits a review for the
 * specified email.
 *
 * @param emailId The id of the email to submit a review for.
 */
export const useSubmitReview = (emailId?: string) => {
  const user = useCurrentUser();
  return useCallback(
    async (approved: boolean, comments?: string) => {
      if (!emailId) return;
      if (!user) return;

      // Get email for version
      const emailRef = FirestoreUtils.createDocRef<Email>(`emails/${emailId}`);
      const snapshot = await emailRef.get();
      const email = snapshot.data();
      if (!email) throw new Error(`Failed to get data for <email|${emailId}>.`);

      const reviewRef = FirestoreUtils.createDocRef<EmailReview>(
        `emails/${emailId}/reviews/${FirestoreUtils.docId()}`
      );
      await reviewRef.set({
        id: reviewRef.id,
        approved,
        comments: comments ?? "",
        reviewedBy: user.uid,
        reviewerName: user.displayName ?? "",
        reviewedOn: FirestoreUtils.timestamp(),
        version: email.version,
      });
    },
    [emailId, user]
  );
};

/**
 * This hook creates a function that duplicates the contents
 * of the specified email to a new draft email.
 *
 * @param emailId The id of the email to duplicate.
 */
export const useDuplicateEmail = (emailId?: string) => {
  const user = useCurrentUser();
  return useCallback(async () => {
    if (!emailId) return;
    if (!user) return;

    // Create new ref for duplicate email
    const newRef = FirestoreUtils.createColRef<Email>("emails").doc();

    // Create draft
    const draft: Email = {
      id: newRef.id,
      owner: user.uid,
      editors: [user.uid],
      reviewers: [],
      contentKeys: [],
      status: "draft",
      version: 0,
      created: FirestoreUtils.timestamp(),
      modified: FirestoreUtils.timestamp(),
      preview: {
        subject: "Duplicating...",
        body: "Duplicating...",
      },
    };
    await newRef.set(draft);

    // Get content
    const contents = await FirestoreUtils.createColRef<EmailContent>(
      `emails/${emailId}/contents`
    ).get();

    // Copy content to new email
    await Promise.all(
      contents.docs.map(async (doc) => {
        const content = doc.data();
        const ref = FirestoreUtils.createDocRef<EmailContent>(
          `emails/${newRef.id}/contents/${content.id}`
        );
        await ref.set({
          ...content,
          modified: FirestoreUtils.timestamp(),
        });
      })
    );

    // Get attachments
    const attachments = await FirestoreUtils.createColRef<EmailAttachment>(
      `emails/${emailId}/attachments`
    ).get();

    // Copy attachments to new email
    await Promise.all(
      attachments.docs.map(async (doc) => {
        // Create attachment
        const attachmentRef = FirestoreUtils.createDocRef<EmailAttachment>(
          `emails/${newRef.id}/attachments/${doc.id}`
        );
        await attachmentRef.set({
          ...doc.data(),
          modified: FirestoreUtils.timestamp(),
        });

        // Create attachment parts
        const parts = await FirestoreUtils.createColRef<EmailAttachmentPart>(
          `${doc.ref.path}/parts`
        ).get();
        await Promise.all(
          parts.docs.map(async (doc) => {
            const ref = FirestoreUtils.createDocRef<EmailAttachmentPart>(
              `emails/${newRef.id}/attachments/${attachmentRef.id}/parts/${doc.id}`
            );
            await ref.set(doc.data());
          })
        );
      })
    );
  }, [user, emailId]);
};

/**
 * This hook creates a function that deletes the specified email.
 *
 * @param emailId The id of the email to delete.
 */
export const useDeleteEmail = (emailId?: string) => {
  return useCallback(async () => {
    if (!emailId) return;
    const ref = FirestoreUtils.createDocRef<Email>(`emails/${emailId}`);
    await ref.delete();
  }, [emailId]);
};
