import { useState, useEffect, useRef } from "react";

/**
 * This hook returns an object with the setter and getter for
 * a value field and error field.
 *
 * @param defaultValue The default value for the field. Value is used to reset when the `reset` flag changes from `false` to `true`.
 * @param reset A flag for triggering when the field value should be reset.
 * @returns `[value, setValue, error, setError]`
 */
export const useInputField = <T = string>(
  defaultValue: T,
  reset: boolean = false
): [T, (value: T) => void, string, (error: string) => void] => {
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState("");
  const resetRef = useRef(reset);

  // Reset fields when reset changes to `true`
  useEffect(() => {
    // Keep resetRef in sync with the actual reset value
    if (resetRef.current && !reset) {
      resetRef.current = reset;
    }

    // If the reset flag is `true`, reset values
    if (!resetRef.current && reset) {
      resetRef.current = reset;
      setValue(defaultValue);
      setError("");
    }
  }, [setValue, setError, reset, resetRef, defaultValue]);

  // If the value is updated, reset the error
  useEffect(() => {
    setError("");
  }, [value]);

  return [value, setValue, error, setError];
};
