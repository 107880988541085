import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { Color } from "../../../constants";

import {
  DRAWER_WIDTH_DESKTOP as NAV_WIDTH_DESKTOP,
  DRAWER_WIDTH_MOBILE as NAV_WIDTH_MOBILE,
} from "../../../components/core/NavigationDrawer";

import { AppLayoutProps } from "./AppLayout";

export const APP_BAR_SPACING = 8;

const DRAWER_WIDTH_DESKTOP = 360;
const DRAWER_WIDTH_MOBILE = 240;

export default makeStyles<Theme, AppLayoutProps>((theme) =>
  createStyles({
    // App Bar
    appBar: ({ appBarHeightSpacing = APP_BAR_SPACING }) => ({
      height: theme.spacing(appBarHeightSpacing),
      background: Color.GRADIENT_SHALLOW,
      border: "none",
      transition: theme.transitions.create(["padding", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen,
      }),
      paddingLeft: NAV_WIDTH_DESKTOP,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: NAV_WIDTH_MOBILE,
      },
    }),
    appBarContent: {
      height: "100%",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    appBarShift: ({
      drawerWidthDesktop = DRAWER_WIDTH_DESKTOP,
      drawerWidthMobile = DRAWER_WIDTH_MOBILE,
    }) => ({
      paddingLeft: drawerWidthDesktop + NAV_WIDTH_DESKTOP,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: drawerWidthMobile + NAV_WIDTH_MOBILE,
      },
    }),

    // Button
    menuButton: {
      color: theme.palette.primary.contrastText,
    },

    // Toolbar
    toolbar: {
      display: "flex",
      alignItems: "center",
    },

    // Drawer
    drawer: ({
      drawerWidthDesktop = DRAWER_WIDTH_DESKTOP,
      drawerWidthMobile = DRAWER_WIDTH_MOBILE,
    }) => ({
      flexShrink: 0,
      width: drawerWidthDesktop + NAV_WIDTH_DESKTOP,
      [theme.breakpoints.down("sm")]: {
        zIndex: `${theme.zIndex.drawer} !important` as any,
        width: drawerWidthMobile + NAV_WIDTH_MOBILE,
      },
    }),
    drawerPaper: ({
      drawerWidthDesktop = DRAWER_WIDTH_DESKTOP,
      drawerWidthMobile = DRAWER_WIDTH_MOBILE,
    }) => ({
      marginLeft: NAV_WIDTH_DESKTOP,
      width: drawerWidthDesktop,
      [theme.breakpoints.down("sm")]: {
        marginLeft: NAV_WIDTH_MOBILE,
        width: drawerWidthMobile,
      },
    }),

    // Content
    content: {
      marginTop: theme.spacing(8),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentShift: ({
      drawerWidthDesktop = DRAWER_WIDTH_DESKTOP,
      drawerWidthMobile = DRAWER_WIDTH_MOBILE,
    }) => ({
      marginLeft: drawerWidthDesktop,
      [theme.breakpoints.down("sm")]: {
        marginLeft: drawerWidthMobile,
      },
    }),
  })
);
