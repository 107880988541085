import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    loadingWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "absolute",
    },
    full: {
      height: "100vh",
      paddingTop: "24vh",
      width: "100%",
    },
    logoContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    progress: {
      width: "80%",
    },
    logo: {
      width: 200,
    },
    text: {
      marginTop: theme.spacing(2),
      textAlign: "center",
    },
    title: {
      marginBottom: theme.spacing(1),
    },
  })
);
