import { useCallback } from "react";

import { FirestoreUtils } from "../../utils";

import { useCurrentUser } from "../../state/FirebaseAuthState";

import { AlertView, NotificationAlert } from "../../../../types";

/**
 * This hook returns a function that can be used
 * to create an alert view for the current user.
 */
export const useCreateAlertView = () => {
  const user = useCurrentUser();
  return useCallback(
    async (id: string) => {
      if (!user) return;
      const ref = FirestoreUtils.createDocRef<AlertView>(
        `users/${user.uid}/alerts/${id}`
      );
      // Only create the alert if it does not exist
      if (!(await ref.get()).exists) {
        await ref.set({
          id,
          created: FirestoreUtils.timestamp(),
        });
      }
    },
    [user]
  );
};

/**
 * This hook returns a function that can be used to
 * acknowledge an alert for the current user..
 */
export const useAcknowledgeAlert = () => {
  const user = useCurrentUser();
  return useCallback(
    async (id: string, response?: string) => {
      if (!user) return;
      await FirestoreUtils.createDocRef<AlertView>(
        `users/${user.uid}/alerts/${id}`
      ).set(
        {
          acknowledged: FirestoreUtils.timestamp(),
          ...(response ? { response } : null),
        },
        { merge: true }
      );
    },
    [user]
  );
};

/**
 * This hook returns a function that can be used to create an alert.
 */
export const useCreateAlert = () => {
  const user = useCurrentUser();
  return useCallback(
    async (
      alertOptions: Pick<
        NotificationAlert,
        "message" | "title" | "priority" | "acknowledgeText" | "variant"
      > & { expiry: Date | null }
    ) => {
      if (!user) return;
      const { expiry, ...options } = alertOptions;
      const ref = FirestoreUtils.createColRef<NotificationAlert>("alerts").doc();
      await ref.set({
        id: ref.id,
        created: FirestoreUtils.timestamp(),
        createdBy: user.uid,
        type: "notification",
        expiry: expiry ? FirestoreUtils.timestamp(expiry) : null,
        ...options,
      });
    },
    [user]
  );
};

/**
 * This hook returns a function that can be used to delete an alert.
 */
export const useDeleteAlert = () => {
  return useCallback(async (alertId: string) => {
    await FirestoreUtils.createDocRef<NotificationAlert>(
      `alerts/${alertId}`
    ).delete();
  }, []);
};
