import { useCallback } from "react";

import { useFirestore } from "../../../contexts/firebase/FirebaseContext";

import { useDocument, useDocumentData } from "../../../hooks/firebase";
import { useLogError } from "../../../hooks/log";

import { useEmailData } from "./utils";

import { EmailContent } from "../../../../../types";

/**
 * Retrieve the email document.
 *
 * @param emailId The id of the email to get.
 */
export const useEmailDocument = (emailId?: string) => {
  const [document, loading, error] = useDocument(emailId && `emails/${emailId}`);
  useLogError(
    `Error while retrieving <email|${emailId}>.`,
    "hook-useEmailDocument",
    error
  );
  return [document, loading, error] as const;
};

/**
 * Retrieve the contents for the specified email.
 *
 * @param emailId The id of the email to retrieve content for.
 */
export const useEmailContents = (emailId?: string) =>
  useEmailData<EmailContent>(emailId, "contents");

/**
 * Retrieve content for the specified email by id.
 *
 * @param emailId The id of the email to retrieve content for.
 * @param contentId The id of the content to retrieve.
 */
export const useEmailContentById = (emailId?: string, contentId?: string | null) => {
  const [content, loading, error] = useDocumentData<EmailContent>(
    emailId && contentId && `emails/${emailId}/contents/${contentId}`
  );
  useLogError(
    `Error while retrieving ${contentId} content from <email|${emailId}>.`,
    "hook-useEmailContentById",
    error
  );
  return [content, loading, error] as const;
};

/**
 * Returns a function that fetches the content for the email once.
 *
 * @param emailId The id of the email to retrieve the content for.
 * @param contentId The id of the content to retrieve.
 */
export const useFetchEmailContentById = (
  emailId?: string,
  contentId?: string | null
) => {
  const store = useFirestore();
  return useCallback(async () => {
    if (!store) return undefined;
    if (!emailId) return undefined;
    if (!contentId) return undefined;
    const snapshot = await store
      .doc(`emails/${emailId}/contents/${contentId}`)
      .get();
    return snapshot.data() as EmailContent;
  }, [store, emailId, contentId]);
};
