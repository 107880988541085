import moment from "moment/moment";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Fade from "@material-ui/core/Fade";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeferredIcon from "@material-ui/icons/HourglassFull";
import BounceIcon from "@material-ui/icons/Warning";
import DroppedIcon from "@material-ui/icons/RemoveCircle";

import { TextUtils, SendGridUtils } from "../../../utils";

import {
  useEmailRecipient,
  useEmailRecipientEvents,
} from "../../../hooks/data/email";
import { useBooleanWithState } from "../../../hooks/state";

import RawDataDialog from "../../../components/dialogs/RawDataDialog";

import useStyles from "./styles";

import { WebhookEvent } from "../../../../../types";

// import { sampleEvents } from "./sample";

export interface RecipientStatusDialogProps {
  /**
   * Whether the dialog is open.
   *
   * @default false
   */
  open?: boolean;

  /**
   * Callback function to close the dialog.
   */
  onClose?: () => void;

  /**
   * The id of the email the recipient is in.
   */
  emailId?: string;

  /**
   * The id of the recipient to get.
   */
  recipientId?: string;
}

/**
 * This dialog shows the data about a recipient's status in an
 * email using webhook event data from SendGrid.
 */
export function RecipientStatusDialog(props: RecipientStatusDialogProps) {
  const { open = false, onClose, emailId, recipientId } = props;
  const classes = useStyles();

  const [recipient, recipientLoading] = useEmailRecipient(emailId, recipientId);
  const [events, eventsLoading] = useEmailRecipientEvents(emailId, recipientId);
  const [event, showDialog, openDialog, closeDialog] =
    useBooleanWithState<WebhookEvent>();

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <Fade in={recipientLoading || eventsLoading}>
          <LinearProgress />
        </Fade>
        <DialogTitle disableTypography>
          <Typography variant="h6">
            Events for email to <b>{recipient?.name}</b>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {recipient?.email}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Click on an event for more details.</DialogContentText>
          <List>
            {(!events || events?.length === 0) && (
              <ListItem>
                <ListItemText secondary="No events" />
              </ListItem>
            )}
            {!eventsLoading &&
              events?.map((data: WebhookEvent) => {
                const {
                  event,
                  timestamp = 0,
                  reason,
                  response,
                  status,
                  email,
                } = data;
                const date = moment(timestamp * 1000);
                let icon = <CheckCircleIcon className={classes.success} />;
                if (event === "dropped")
                  icon = <DroppedIcon className={classes.error} />;
                else if (event === "bounce")
                  icon = <BounceIcon className={classes.error} />;
                else if (event === "deferred") icon = <DeferredIcon />;
                const label = TextUtils.capitalise(
                  SendGridUtils.mapEventToLabel(event)
                );
                return (
                  <ListItem
                    key={event + timestamp}
                    button
                    onClick={() => openDialog(data)}
                    className={classes.listItem}
                  >
                    <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
                    <ListItemText
                      primary={
                        <>
                          <b>{label}</b> – {email}
                        </>
                      }
                      secondary={reason ?? response ?? status ?? event}
                      secondaryTypographyProps={{
                        component: "div",
                        className: classes.text,
                      }}
                    />
                    <ListItemSecondaryAction className={classes.listItemTime}>
                      <ListItemText
                        secondary={
                          <>
                            <div>{date.format("DD MMM YYYY")}</div>
                            <div>{date.format("hh:mm:ss a")}</div>
                          </>
                        }
                        secondaryTypographyProps={{
                          component: "div",
                          className: classes.time,
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>CLOSE</Button>
        </DialogActions>
      </Dialog>
      <RawDataDialog
        title="SendGrid Event"
        open={showDialog}
        onClose={closeDialog}
        data={event}
      />
    </>
  );
}
