import { useEffect } from "react";
import { useSetRecoilState } from "recoil";

import { NavigationLocationType } from ".";
import { NavigationLocationState } from "./atoms";

/**
 * Set the current navigation location to highlight the appropriate
 * link on the navigation drawer. On unmount, it resets it to `null`.
 *
 * @param location The location to set.
 */
export const useSetNavigationLocation = (location: NavigationLocationType) => {
  const setState = useSetRecoilState(NavigationLocationState);
  useEffect(() => {
    setState(location);
    return () => setState(null);
  }, [setState, location]);
};
