import { red, blueGrey } from "@material-ui/core/colors";

export const MONASH_BLUE = "#006dae";
export const MONASH_GREY = blueGrey[900];
export const ERROR = red[600];
export const GRADIENT = `linear-gradient(to top right, #007ec9, #009dfb)`;
export const GRADIENT_SHALLOW = `linear-gradient(to top right, #2d82cf, #009dfb)`;
export const SELECTED_TINT = "#3493ec12";
export const BACKGROUND = "#f8f8f8";

export const EDITOR_DEFAULT = "#555555";
export const EDITOR_LIGHT_GRAY = "#f0f0f0";
export const EDITOR_GRAY = "#888888";
export const EDITOR_RED = "#f53d3d";
export const EDITOR_GREEN = "#4caf50";
