import useStyles from "./styles";

export interface PageLayoutProps {
  children?: React.ReactNode;
}

/**
 * Wraps children in a full height flex container.
 */
export const PageLayout = ({ children }: PageLayoutProps) => {
  const classes = useStyles();
  return <div className={classes.page}>{children}</div>;
};
