import { useQueryData } from "../../hooks/firebase";
import { useLogError } from "../../hooks/log";

import { User, UserStatus } from "../../../../types";

/**
 * Retrieve the list of users in the system.
 */
export const useUsers = (status: UserStatus | "all" = "active") => {
  const [data, loading, error] = useQueryData<User>("users", [
    status === "all" ? null : { op: "where", args: ["status", "==", status] },
  ]);
  useLogError(`Error while retrieving users.`, "hook-useUsers", error);
  return [data, loading, error] as const;
};
