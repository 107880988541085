import { useEffect } from "react";
import { Link } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import ClearIcon from "@material-ui/icons/Clear";

import { useSnack } from "../../../hooks/snack";
import {
  useAcknowledgeAlert,
  useCreateAlertView,
} from "../../../hooks/actions/alert";
import { useChangelogAlertsForUser } from "../../../hooks/data/alerts";

import useStyles from "./styles";

export interface ReleaseAlertManagerProps {
  /**
   * The current version of the system.
   */
  version?: string;
}

/**
 * This component shows alerts for when there is a new release for MSend.
 */
export function ReleaseAlertManager(props: ReleaseAlertManagerProps) {
  const { version = "" } = props;
  const classes = useStyles();

  const { pushSnack, closeSnack } = useSnack();

  const createAlert = useCreateAlertView();
  const acknowledgeAlert = useAcknowledgeAlert();

  const [changelog] = useChangelogAlertsForUser(version);

  // Show new release alerts
  useEffect(() => {
    if (!changelog) return;
    createAlert(changelog.id);
    pushSnack(
      <>
        <Typography variant="body1">
          ✨ MSend v{changelog.id} has been released!
        </Typography>
      </>,
      {
        anchorOrigin: { vertical: "top", horizontal: "center" },
        persist: true,
        action: (key) => (
          <>
            <Button
              component={Link}
              size="small"
              to="/changelog"
              className={classes.acknowledge}
              onClick={() => {
                closeSnack(key);
                acknowledgeAlert(changelog.id, "acknowledged");
              }}
            >
              <b>CHANGE LOG</b>
            </Button>
            <IconButton
              size="small"
              className={classes.dismiss}
              onClick={() => {
                closeSnack(key);
                acknowledgeAlert(changelog.id, "dismissed");
              }}
              data-testid="close"
            >
              <ClearIcon />
            </IconButton>
          </>
        ),
      }
    );
  }, [changelog, pushSnack, closeSnack, classes, createAlert, acknowledgeAlert]);

  return null;
}
