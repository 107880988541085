import clsx from "clsx";
import moment from "moment/moment";

import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import LinearProgress from "@material-ui/core/LinearProgress";

import ClearIcon from "@material-ui/icons/Clear";
import AttachmentsIcon from "@material-ui/icons/AttachFile";

import { TextUtils } from "../../../utils";

import useStyles from "./styles";

import { Email } from "../../../../../types";

export interface EmailListProps {
  /**
   * The title to show at the top of the email list.
   */
  title?: string;

  /**
   * Show a loading indicator.
   */
  loading?: boolean;

  /**
   * Provide an icon to render an action button to the
   * right of the search field.
   */
  actionIcon?: React.ReactNode;

  /**
   * Provide a tooltip to show when the user hovers over
   * the action button.
   */
  actionTooltip?: string;

  /**
   * Callback for when the action button is clicked.
   */
  onAction?: () => void;

  /**
   * Disable the action button.
   */
  actionDisabled?: boolean;

  /**
   * The value to use in the search field.
   */
  search?: string;

  /**
   * Callback to set the search value.
   */
  setSearch?: (search: string) => void;

  /**
   * Callback to clear the search field. If provided,
   * a clear button will be shown when the search
   * value is not empty.
   */
  clearSearch?: () => void;

  /**
   * The placeholder text to show in the search field.
   *
   * @default "Search..."
   */
  searchPlaceholder?: string;

  /**
   * Disable the search field.
   */
  searchDisabled?: boolean;

  /**
   * The list of emails to show.
   */
  emails?: Pick<Email, "id" | "modified" | "owner" | "preview">[];

  /**
   * A list of emails that are currently selected.
   */
  selectedEmails?: string[];

  /**
   * Callback for when an email is selected.
   */
  onSelectEmail?: (emailId: string) => void;
}

/**
 * Display a list of emails for selection.
 */
export const EmailList = ({
  title,
  loading,
  actionTooltip = "",
  actionIcon,
  onAction,
  actionDisabled,
  search = "",
  setSearch = () => {},
  clearSearch,
  searchPlaceholder = "Search...",
  searchDisabled,
  emails = [],
  selectedEmails = [],
  onSelectEmail = () => {},
}: EmailListProps) => {
  const classes = useStyles();
  return (
    <div className={classes.list}>
      <div className={classes.header}>
        <div className={classes.search}>
          <TextField
            variant="outlined"
            size="small"
            placeholder={searchPlaceholder}
            fullWidth
            disabled={searchDisabled}
            InputProps={{
              endAdornment: clearSearch && search && (
                <IconButton size="small" onClick={clearSearch}>
                  <ClearIcon />
                </IconButton>
              ),
              classes: {
                adornedEnd: classes.adornedEnd,
              },
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {actionIcon && (
            <Tooltip title={actionTooltip}>
              <IconButton
                size="small"
                className={classes.action}
                onClick={onAction}
                disabled={actionDisabled}
              >
                {actionIcon}
              </IconButton>
            </Tooltip>
          )}
        </div>
        <Divider />
        <div className={classes.heading}>
          <Typography variant="button" color="textSecondary">
            {title}
          </Typography>
        </div>
        <Divider />
      </div>
      {loading && <LinearProgress />}
      <List disablePadding>
        {!loading && (emails?.length ?? 0) === 0 && (
          <div className={classes.empty}>
            <Typography variant="body2" color="textSecondary">
              No emails
            </Typography>
          </div>
        )}
        {emails.map((email) => {
          const preview = email.preview ?? {};
          const title = preview?.subject || "(no subject)";
          const body = preview?.body || "(no content)";
          return (
            <ListItem
              key={email.id}
              button
              className={clsx(classes.item, {
                [classes.selected]: selectedEmails.includes(email.id),
              })}
              onClick={() => onSelectEmail(email.id)}
            >
              <div className={classes.preview}>
                <Typography
                  variant="body1"
                  className={classes.title}
                  color={preview?.subject ? "textPrimary" : "textSecondary"}
                >
                  <b>{title}</b>
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {body}
                </Typography>
              </div>
              <ListItemSecondaryAction className={classes.modified}>
                <Typography variant="caption" color="textSecondary">
                  {moment(email.modified?.toDate()).fromNow()}
                </Typography>
                {(email.preview?.attachments ?? 0) > 0 && (
                  <Tooltip
                    title={`Email contains ${TextUtils.pluralWithCount(
                      email.preview?.attachments ?? 0,
                      "attachment"
                    )}`}
                  >
                    <AttachmentsIcon
                      color="action"
                      fontSize="small"
                      className={classes.attachment}
                    />
                  </Tooltip>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};
