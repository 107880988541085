import { atomFamily } from "recoil";

import { EmailRecipient } from "../../../../types";

export enum PersonalisationsStateType {
  UPLOAD = "UPLOAD",
  RECIPIENTS = "RECIPIENTS",
  STATUS = "STATUS",
}

/**
 * This atom is used to keep track of a list of personalisations
 * (email recipients) for rendering.
 *
 * This state is kept in Recoil to improve the performance of
 * rendering large lists if any filtering needs to occur.
 */
export const PersonalisationsState = atomFamily<
  EmailRecipient[],
  PersonalisationsStateType
>({
  key: "PersonalisationsState",
  default: [],
});

/**
 * This atom is used to keep track of the filter that
 * is currently active on the personalisations state.
 */
export const PersonalisationsFilterState = atomFamily<
  string,
  PersonalisationsStateType
>({
  key: "PersonalisationsFilterState",
  default: "",
});

/**
 * This atom is used to keep track of the event filter that
 * is currently active on the personalisations state.
 */
export const PersonalisationsEventFilterState = atomFamily<
  string,
  PersonalisationsStateType
>({
  key: "PersonalisationsEventFilterState",
  default: "",
});
