import { useQueryData } from "../../firebase";
import { useLogError } from "../../log";

import { EmailTask, EmailTaskType } from "../../../../../types";

/**
 * Get the latest task of the specified type for the email.
 *
 * @param emailId The id of the email to get the latest task for.
 * @param type The task type to get.
 * @param limit Limit the number of tasks to get.
 */
export const useLatestEmailTasks = (
  emailId?: string,
  type?: EmailTaskType,
  limit?: number
): [EmailTask[] | undefined, boolean, Error | undefined] => {
  const [tasks, loading, error] = useQueryData<EmailTask>(
    emailId ? `emails/${emailId}/tasks` : null,
    [
      type && {
        op: "where",
        args: ["type", "==", type],
      },
      {
        op: "orderBy",
        args: ["startedOn", "desc"],
      },
      typeof limit === "number" ? { op: "limit", args: [limit] } : null,
    ]
  );

  useLogError(
    `Error while retrieving latest task for <email|${emailId}>.`,
    "hook-useLatestEmailTask",
    error
  );

  return [tasks, loading, error];
};
