import { useQueryData } from "../../../hooks/firebase";

import { useLogError } from "../../../hooks/log";

import { EmailReview } from "../../../../../types";

/**
 * Retrieve the reviews for the specified email.
 * The reviews are ordered by the most recent date.
 *
 * @param emailId The id of the email to retrieve content for.
 */
export const useOrderedEmailReviews = (emailId?: string) => {
  const [data, loading, error] = useQueryData<EmailReview>(
    emailId && `emails/${emailId}/reviews`,
    [{ op: "orderBy", args: ["reviewedOn", "desc"] }]
  );
  useLogError(
    `Error while retrieving reviews for <email|${emailId}>.`,
    "hook-useOrderedEmailReviews",
    error
  );
  return [data, loading, error] as const;
};
