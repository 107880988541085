import clsx from "clsx";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import InfoIcon from "@material-ui/icons/Info";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Warning";

import useStyles from "./styles";

import { NotificationAlert } from "../../../../../types";

export interface HighPriorityAlertProps {
  /**
   * Whether to show the alert.
   */
  open?: boolean;

  /**
   * Callback for when the acknoledge button is clicked.
   */
  onClose?: (alert: NotificationAlert) => void;

  /**
   * The alert to show.
   */
  alert?: NotificationAlert | null;
}

/**
 * Show a high priority alert message in a dialog.
 */
export function HighPriorityAlert(props: HighPriorityAlertProps) {
  const { open = false, onClose, alert } = props;
  const classes = useStyles();
  if (!alert) return null;
  return (
    <Dialog open={open} maxWidth="xs" fullWidth role="alert">
      <DialogTitle disableTypography>
        <Typography
          className={classes.title}
          variant="h6"
          variantMapping={{ h6: "h2" }}
        >
          {alert.variant === "info" && (
            <InfoIcon className={clsx(classes.icon, classes.infoIcon)} />
          )}
          {alert.variant === "success" && (
            <SuccessIcon className={clsx(classes.icon, classes.successIcon)} />
          )}
          {alert.variant === "warning" && (
            <WarningIcon className={clsx(classes.icon, classes.warningIcon)} />
          )}
          {alert.variant === "error" && (
            <ErrorIcon className={clsx(classes.icon, classes.errorIcon)} />
          )}
          {alert.title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{alert?.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => onClose?.(alert)}>
          {alert.acknowledgeText || "Close"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
