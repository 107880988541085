import { useState } from "react";
import clsx from "clsx";

import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import CollapseIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";

import { useDesktopBreakpoint } from "../../../hooks/breakpoint";

import useStyles from "./styles";

export interface AppLayoutProps {
  children?: React.ReactNode;
  className?: string;
  classes?: {
    appBar?: string;
    appBarContent?: string;
    toolbar?: string;
    drawer?: string;
    drawerPaper?: string;
    content?: string;
  };

  /**
   * Content to render in the toolbar.
   */
  toolbarContent?: React.ReactNode;

  /**
   * Content to render in the drawer area.
   */
  drawerContent?: React.ReactNode;

  /**
   * Caption to show on the menu button when the drawer can be opened.
   * @default "Show more"
   */
  openDrawerCaption?: string;

  /**
   * Caption to show on the menu button when the drawer can be closed.
   * @default "Collapse"
   */
  closeDrawerCaption?: string;

  /**
   * The width of the drawer in pixels on desktop displays.
   * @default 320px
   */
  drawerWidthDesktop?: number;

  /**
   * The width of the drawer in pixels on mobile displays.
   * @default 240px
   */
  drawerWidthMobile?: number;

  /**
   * The number of spacing units to use for the app bar height.
   * @default 8
   */
  appBarHeightSpacing?: number;
}

/**
 * Renders a content area with a dynanmic side drawer and app bar.
 */
export function AppLayout(props: AppLayoutProps) {
  const {
    children,
    className,
    classes: customClasses,
    toolbarContent,
    drawerContent,
    openDrawerCaption = "Show more",
    closeDrawerCaption = "Collapse",
  } = props;

  const classes = useStyles(props);
  const desktop = useDesktopBreakpoint();
  const [open, setOpen] = useState(true);

  return (
    <div className={className}>
      <AppBar
        variant="outlined"
        className={clsx(classes.appBar, customClasses?.appBar, {
          [classes.appBarShift]: desktop && open,
        })}
      >
        <div className={clsx(classes.appBarContent, customClasses?.appBarContent)}>
          <Tooltip
            title={open ? closeDrawerCaption : openDrawerCaption}
            placement="right"
          >
            <IconButton
              onClick={() => setOpen(!open)}
              className={classes.menuButton}
            >
              {open ? <CollapseIcon /> : <MenuIcon />}
            </IconButton>
          </Tooltip>
          <div className={clsx(classes.toolbar, customClasses?.toolbar)}>
            {toolbarContent}
          </div>
        </div>
      </AppBar>
      <Drawer
        onClose={() => setOpen(false)}
        variant={desktop ? "persistent" : "temporary"}
        anchor="left"
        open={open}
        className={clsx(classes.drawer, customClasses?.drawer)}
        classes={{
          paper: clsx(classes.drawerPaper, customClasses?.drawerPaper),
        }}
      >
        {drawerContent}
      </Drawer>
      <div
        className={clsx(classes.content, customClasses?.content, {
          [classes.contentShift]: desktop && open,
        })}
      >
        {children}
      </div>
    </div>
  );
}
