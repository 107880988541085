import { useEffect, useState, useCallback } from "react";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import ReviewersIcon from "@material-ui/icons/HowToReg";

import { useBoolean } from "../../../hooks/state";
import { useSnack } from "../../../hooks/snack";
import {
  useEmailEditorsAsUsers,
  useEmailReviewerOptions,
} from "../../../hooks/data/email";
import { useOrderedEmailReviews } from "../../../hooks/data/email/review";
import {
  useRequestReview,
  useUpdateEditors,
} from "../../../hooks/actions/emailData";

import EditorSelectDialog from "../../../components/dialogs/EditorSelectDialog";

import { EmailStatus } from "../../../../../types";

export interface EditorViewReviewersActionProps {
  /**
   * The id of the email to preview.
   */
  emailId?: string;

  /**
   * Disables the action button.
   */
  disabled?: boolean;

  /**
   * The mode the email editor is in.
   */
  mode?: EmailStatus | "pending" | "view";

  /**
   * Show the dialog in read only mode.
   */
  readOnly?: boolean;
}

/**
 * This action shows the the list of reviewers for the current email.
 */
export const EditorViewReviewersAction = ({
  emailId,
  disabled,
  mode,
  readOnly,
}: EditorViewReviewersActionProps) => {
  const { pushClosableSnack } = useSnack();
  const [open, onOpen, onClose] = useBoolean();
  const [errors, setErrors] = useState<string[]>([]);

  const onSuccess = useCallback(() => {
    pushClosableSnack(`Email submitted for review.`);
    onClose();
  }, [pushClosableSnack, onClose]);

  const onError = useCallback((errors: string[]) => {
    setErrors(errors);
  }, []);

  const requestReview = useRequestReview(emailId, onSuccess, onError);
  const updateEditors = useUpdateEditors(emailId);

  const [, reviewers] = useEmailEditorsAsUsers(emailId);
  const [options] = useEmailReviewerOptions(emailId);
  const [reviews] = useOrderedEmailReviews(emailId);

  // Reset errors when the dialog opens.
  useEffect(() => {
    if (open) setErrors([]);
  }, [open]);

  return (
    <>
      <Tooltip title="Reviewers" placement="left">
        <div>
          <IconButton onClick={onOpen} disabled={disabled}>
            <ReviewersIcon />
          </IconButton>
        </div>
      </Tooltip>
      <EditorSelectDialog
        open={open}
        onClose={onClose}
        readOnly={readOnly}
        mode={mode}
        title="Reviewers"
        listTitle={readOnly ? "" : "Email reviewers"}
        emptyMessage="No reviewers added"
        searchPlaceholder="Add a reviewer"
        removeLabel="Remove reviewer"
        selected={reviewers}
        options={options}
        reviews={reviews}
        onUpdate={(reviewerIds) =>
          updateEditors(reviewerIds, {
            role: "reviewer",
            type: "user",
          })
        }
        onAction={requestReview}
        actionLabel="SUBMIT FOR REVIEW"
        errors={errors}
      >
        {readOnly
          ? "These users have access to approve this email."
          : "Add users to review your email. An email must be approved before it can be sent."}
      </EditorSelectDialog>
    </>
  );
};
