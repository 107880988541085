import clsx from "clsx";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";

import CheckIcon from "@material-ui/icons/CheckCircle";
import XIcon from "@material-ui/icons/Cancel";

import { EmailReview } from "../../../../../types";
import { dateUtils } from "../../../utils";

import useStyles from "./styles";

export interface ReviewHistoryDialogProps {
  /**
   * Whether the dialog is open.
   *
   * @default false
   */
  open?: boolean;

  /**
   * Callback function to close the dialog.
   */
  onClose?: () => void;

  /**
   * The reviews to show in the dialog.
   */
  reviews: EmailReview[];
}

export function ReviewHistoryDialog(props: ReviewHistoryDialogProps) {
  const { open = false, onClose, reviews = [] } = props;
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Review history</DialogTitle>
      <DialogContent>
        <List>
          {reviews.map(
            ({ id, approved, reviewerName, comments, reviewedOn }, index) => (
              <ListItem
                key={id}
                disableGutters
                className={clsx({ [classes.old]: index > 0 })}
              >
                <ListItemAvatar className={classes.icon}>
                  {approved ? (
                    <CheckIcon className={classes.approved} />
                  ) : (
                    <XIcon className={classes.rejected} />
                  )}
                </ListItemAvatar>
                <ListItemText
                  className={classes.text}
                  primary={
                    <>
                      <span>
                        {approved ? "Approved" : "Rejected"} by {reviewerName}
                      </span>
                      <Tooltip
                        title={dateUtils.format(
                          reviewedOn?.toDate?.(),
                          "ddd h:mma D MMM YYYY"
                        )}
                        placement="top"
                      >
                        <Typography variant="body2" color="textSecondary">
                          {dateUtils.fromNow(reviewedOn?.toDate?.())}
                        </Typography>
                      </Tooltip>
                    </>
                  }
                  primaryTypographyProps={{ className: classes.title }}
                  secondary={comments}
                />
              </ListItem>
            )
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CLOSE</Button>
      </DialogActions>
    </Dialog>
  );
}
