import { useMemo } from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";

import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import LinearProgress from "@material-ui/core/LinearProgress";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import ErrorIcon from "@material-ui/icons/Warning";
import CreatedIcon from "@material-ui/icons/HourglassEmpty";
import InProgressIcon from "@material-ui/icons/HourglassFull";
import DoneIcon from "@material-ui/icons/CheckCircle";
import Typography from "@material-ui/core/Typography";

import { TextUtils } from "../../../utils";

import useStyles from "./styles";

import { EmailTask } from "../../../../../types";

export interface EmailSendTasksDialogProps {
  /**
   * Whether the dialog is open.
   *
   * @default false
   */
  open?: boolean;

  /**
   * Callback function to close the dialog.
   */
  onClose?: () => void;

  /**
   * The email id (for display purposes).
   */
  emailId?: string;

  /**
   * A list of tasks to show. There should only be tasks
   * of type `send-emails` in this list.
   */
  tasks?: EmailTask[];
}

/**
 * This dialog shows a list of delivery tasks for the given email.
 */
export const EmailSendTasksDialog = ({
  open = false,
  onClose,
  emailId,
  tasks,
}: EmailSendTasksDialogProps) => {
  const classes = useStyles();

  const error = useMemo(() => tasks?.some((t) => t.status === "failed"), [tasks]);
  const sending = useMemo(
    () => !error && tasks?.some((t) => t.status === "in-progress"),
    [error, tasks]
  );
  const complete = !sending && !error;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle disableTypography>
        <Typography variant="h6">Email delivery summary</Typography>
        {emailId && (
          <Typography variant="caption" color="textSecondary">
            Email ID: {emailId}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent className={classes.content}>
        {sending && (
          <DialogContentText>
            This email is currently being delivered to SendGrid.
          </DialogContentText>
        )}
        {complete && (
          <DialogContentText>All emails delivered to SendGrid.</DialogContentText>
        )}
        {error && (
          <DialogContentText>
            Something went wrong during the delivery process.
          </DialogContentText>
        )}
      </DialogContent>
      <List className={classes.tasksList} disablePadding>
        {tasks?.map((task) => {
          const { id, status, statusInfo, progress, startedOn, completedOn } = task;
          const startMoment = startedOn && moment(startedOn.toDate());
          const completeMoment = completedOn && moment(completedOn.toDate());
          const duration =
            startMoment &&
            completeMoment &&
            moment.duration(completeMoment.diff(startMoment));
          return (
            <ListItem key={id} className={classes.task}>
              <div className={classes.taskInfo}>
                <ListItemIcon className={classes.taskIcon}>
                  {status === "created" && (
                    <Tooltip placement="top" title="Task pending">
                      <CreatedIcon color="action" />
                    </Tooltip>
                  )}
                  {status === "in-progress" && (
                    <Tooltip placement="top" title="Task in progress">
                      <InProgressIcon color="action" />
                    </Tooltip>
                  )}
                  {status === "complete" && (
                    <Tooltip placement="top" title="Task successsful">
                      <DoneIcon className={classes.taskSuccess} />
                    </Tooltip>
                  )}
                  {status === "partially-failed" && (
                    <Tooltip placement="top" title="Task partially failed">
                      <ErrorIcon className={classes.taskPartial} />
                    </Tooltip>
                  )}
                  {status === "failed" && (
                    <Tooltip placement="top" title="Task failed">
                      <ErrorIcon color="error" />
                    </Tooltip>
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={statusInfo}
                  secondary={
                    duration
                      ? `Finished ${startMoment?.fromNow()} (took ${duration.get(
                          "minutes"
                        )} min ${duration.get("seconds")} sec)`
                      : `Started ${startMoment?.fromNow()}`
                  }
                />
                <ListItemSecondaryAction className={classes.taskId}>
                  <Typography variant="caption" color="textSecondary">
                    Task ID: {task.id}
                  </Typography>
                </ListItemSecondaryAction>
              </div>
              {status !== "complete" && (
                <LinearProgress
                  className={classes.taskLoading}
                  color="primary"
                  classes={{
                    colorPrimary:
                      status === "failed"
                        ? classes.loadingError
                        : status === "partially-failed"
                        ? classes.loadingPartialError
                        : undefined,
                    barColorPrimary:
                      status === "failed"
                        ? classes.loadingBarError
                        : status === "partially-failed"
                        ? classes.loadingBarPartialError
                        : undefined,
                  }}
                  variant="determinate"
                  value={(progress ?? 0) * 100}
                />
              )}
            </ListItem>
          );
        })}
      </List>
      <DialogContent className={classes.content}>
        <Typography variant="caption" color="textSecondary">
          Delivering this email was split into{" "}
          {TextUtils.pluralWithCount(tasks?.length ?? 0, "task")}.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button component={Link} to={`/emails/view/${emailId}/status`}>
          View email status
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
