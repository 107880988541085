import { useState, useMemo, useEffect } from "react";

import { Changelog, CHANGELOG } from "../../constants";

import { useAlertViews } from "../../hooks/data/user";
import { useQueryData } from "../../hooks/firebase";
import { useLogError } from "../../hooks/log";

import { useCurrentUser } from "../../state/FirebaseAuthState";

import { SystemAlert, NotificationAlert } from "../../../../types";

/**
 * Get system alerts.
 */
export const useSystemAlerts = (): [
  NotificationAlert[] | undefined,
  boolean,
  Error | undefined
] => {
  const [alerts, loading, error] = useQueryData<NotificationAlert>("alerts", [
    { op: "orderBy", args: ["expiry", "desc"] },
    { op: "orderBy", args: ["created", "desc"] },
  ]);
  useLogError(`Error retrieving system alerts.`, "hooks-useSystemAlerts", error);
  return [alerts, loading, error];
};

/**
 * This hook returns a list of system alerts the
 * current user needs to see.
 *
 * @param alerts The list of system alerts to sort through.
 */
export const useSystemAlertsForUser = (
  alerts: SystemAlert[] = []
): [Record<string, SystemAlert>, boolean] => {
  const user = useCurrentUser();
  const [alertViews, loading] = useAlertViews(user?.uid);

  const [pendingAlerts, setPendingAlerts] = useState<Record<string, SystemAlert>>(
    {}
  );

  const activeSystemAlerts = useMemo(
    () => alerts.filter((a) => !a.expiry || a.expiry > new Date()),
    [alerts]
  );

  // Check for system alerts to display
  useEffect(() => {
    if (loading) return;
    const viewedAlerts = new Set(
      alertViews?.filter((a) => a.acknowledged).map((a) => a.id) ?? []
    );

    // Find system alerts that need to be displayed
    const pending = activeSystemAlerts.filter((a) => !viewedAlerts.has(a.id));

    // Convert list to a map
    setPendingAlerts(
      pending.reduce<Record<string, SystemAlert>>((mapping, alert) => {
        mapping[alert.id] = alert;
        return mapping;
      }, {})
    );
  }, [activeSystemAlerts, alertViews, loading]);

  return [pendingAlerts, loading];
};

/**
 * This hook returns the current changelog alert to show the
 * user if it has not been viewed yet.
 *
 * @param version The current system version.
 */
export const useChangelogAlertsForUser = (
  version: string
): [Changelog | null, boolean] => {
  const user = useCurrentUser();
  const [alertViews, loading] = useAlertViews(user?.uid);

  const pendingAlert = useMemo(() => {
    if (loading) return null;
    const currentVersionAlert = CHANGELOG.find((log) => log.id === version);
    const viewed = alertViews?.find((a) => a.id === currentVersionAlert?.id);
    if (!viewed?.acknowledged) return currentVersionAlert ?? null;
    return null;
  }, [alertViews, loading, version]);

  return [pendingAlert, loading];
};

/**
 * This hook returns the notification alerts that need to
 * be shown to the current user.
 */
export const useNotificationAlertsForUser = (): [
  NotificationAlert[] | null,
  boolean
] => {
  const user = useCurrentUser();
  const [alertViews, loading] = useAlertViews(user?.uid);

  const [alerts, alertsLoading, error] = useQueryData<NotificationAlert>("alerts", [
    { op: "orderBy", args: ["expiry"] },
  ]);

  useLogError(
    `Error fetching notification alerts.`,
    "hook-useNotificationAlertsForUser",
    error
  );

  // Find alerts which need to be shown to the user
  const pendingAlerts = useMemo(() => {
    if (!alertViews) return null;
    if (!alerts) return null;

    // Collect ids of alerts which have already been seen and acknowledged
    const seen = new Set(alertViews.filter((a) => a.acknowledged).map((a) => a.id));

    const validAlerts = alerts?.filter((a) => {
      // Check if the alert has expired
      const expired = a.expiry?.toDate && a.expiry.toDate() < new Date();
      return !expired && !seen.has(a.id);
    });

    return validAlerts;
  }, [alertViews, alerts]);

  return [pendingAlerts, loading || alertsLoading];
};
