import { selectorFamily } from "recoil";

import { ReviewableEmailsState, EditableEmailsState } from "./atoms";

import { Email, EmailStatus } from "../../../../types";

/**
 * Select the emails from the emails state based on the given status.
 */
export const SelectEmailList = selectorFamily<
  [Email[] | undefined, boolean, Error | undefined],
  EmailStatus | "reviewable"
>({
  key: "EmailList",
  get: (status) => ({ get }) => {
    if (status === "reviewable") return get(ReviewableEmailsState);
    return get(EditableEmailsState(status));
  },
});
