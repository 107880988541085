import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    title: {
      display: "flex",
      alignItems: "center",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    infoIcon: {
      color: theme.palette.info.main,
    },
    successIcon: {
      color: theme.palette.success.main,
    },
    warningIcon: {
      color: theme.palette.warning.main,
    },
    errorIcon: {
      color: theme.palette.error.main,
    },
  })
);
