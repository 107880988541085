import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    listItem: {
      alignItems: "flex-start",
      borderRadius: theme.shape.borderRadius,
    },
    listItemTime: {
      pointerEvents: "none",
      top: theme.spacing(4),
    },
    time: {
      textAlign: "right",
    },
    text: {
      overflow: "auto",
      width: `calc(100% - 4rem)`,
    },
    icon: {
      minWidth: theme.spacing(5),
      marginTop: theme.spacing(1),
    },
    success: {
      color: theme.palette.success.main,
    },
    error: {
      color: theme.palette.error.main,
    },
  })
);
