import Divider from "@material-ui/core/Divider";

import { useCanEditEmail } from "../../../hooks/data/user";
import { useEmailTemplate } from "../../../hooks/data/email";

import EditorPreviewAction from "../../../components/editorSidebar/EditorPreviewAction";
import EditorViewStatusAction from "../../../components/editorSidebar/EditorViewStatusAction";
import EditorViewEditorsAction from "../../../components/editorSidebar/EditorViewEditorsAction";
import EditorViewReviewersAction from "../../../components/editorSidebar/EditorViewReviewersAction";
import EditorViewPersonalisationsAction from "../../../components/editorSidebar/EditorViewPersonalisationsAction";
import EditorDuplicateAction from "../../../components/editorSidebar/EditorDuplicateAction";
import EditorDeleteAction from "../../../components/editorSidebar/EditorDeleteAction";
import EditorAttachmentsAction from "../../../components/editorSidebar/EditorAttachmentsAction";

import useStyles from "./styles";

import { EmailStatus } from "../../../../../types";

export interface EditorSidebarProps {
  /**
   * The mode the editor should be in. This limits the controls available to the user.
   *
   * @default "draft"
   */
  mode?: EmailStatus | "pending" | "view";

  /**
   * The id of the email to preview.
   */
  emailId?: string;
}

/**
 * Displays the actions sidebar for the editor.
 */
export const EditorSidebar = ({ mode = "draft", emailId }: EditorSidebarProps) => {
  const classes = useStyles();

  const [isEditor] = useCanEditEmail(emailId);

  const [templateContent] = useEmailTemplate(emailId);
  const { template, hasTemplate } = templateContent ?? {};

  return (
    <div className={classes.toolbar}>
      <EditorPreviewAction emailId={emailId} disabled={!emailId || !template} />
      <EditorViewStatusAction
        emailId={emailId}
        disabled={!emailId || !hasTemplate}
      />
      <Divider className={classes.divider} />
      <EditorViewEditorsAction
        emailId={emailId}
        disabled={!emailId || !hasTemplate}
      />
      <EditorViewReviewersAction
        emailId={emailId}
        disabled={!emailId || !hasTemplate}
        readOnly={mode !== "draft" || !isEditor}
        mode={mode}
      />
      <EditorViewPersonalisationsAction
        emailId={emailId}
        disabled={!emailId || !hasTemplate}
        readOnly={mode !== "draft" || !isEditor}
      />
      <EditorAttachmentsAction
        emailId={emailId}
        disabled={!emailId || !hasTemplate}
        readOnly={mode !== "draft" || !isEditor}
      />
      <Divider className={classes.divider} />
      <EditorDuplicateAction emailId={emailId} disabled={!emailId || !hasTemplate} />
      {mode === "draft" && isEditor && (
        <EditorDeleteAction emailId={emailId} disabled={!emailId} />
      )}
    </div>
  );
};
