import { useEffect, useState, useCallback } from "react";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import NextIcon from "@material-ui/icons/Forward";

import { useBoolean } from "../../../hooks/state";
import { useSnack } from "../../../hooks/snack";
import {
  useCurrentEmailId,
  useEmailEditorsAsUsers,
  useEmailReviewerOptions,
} from "../../../hooks/data/email";

import { useOrderedEmailReviews } from "../../../hooks/data/email/review";

import {
  useRequestReview,
  useUpdateEditors,
} from "../../../hooks/actions/emailData";

import EditorSelectDialog from "../../../components/dialogs/EditorSelectDialog";

import { useActionStyles } from "../styles";

export interface EditorReviewActionProps {
  /**
   * Disable this action.
   */
  disabled?: boolean;
}

/**
 * This action informs the user on how to submit an email for review.
 *
 * IMPORTANT: Please
 */
export const EditorReviewAction = ({ disabled }: EditorReviewActionProps) => {
  const { pushClosableSnack } = useSnack();

  const classes = useActionStyles();
  const emailId = useCurrentEmailId();
  const [open, onOpen, onClose] = useBoolean();
  const [errors, setErrors] = useState<string[]>([]);

  const [reviews] = useOrderedEmailReviews(emailId);

  const onSuccess = useCallback(() => {
    pushClosableSnack(`Email submitted for review.`);
    onClose();
  }, [pushClosableSnack, onClose]);

  const onError = useCallback((errors: string[]) => {
    setErrors(errors);
  }, []);

  const requestReview = useRequestReview(emailId, onSuccess, onError);
  const updateEditors = useUpdateEditors(emailId);

  const [, reviewers] = useEmailEditorsAsUsers(emailId);
  const [options] = useEmailReviewerOptions(emailId);

  // Reset errors when the dialog opens.
  useEffect(() => {
    if (open) setErrors([]);
  }, [open]);

  return (
    <>
      <Tooltip title="Submit for review" placement="bottom">
        <div>
          <IconButton
            className={classes.button}
            classes={{ disabled: classes.disabled }}
            disabled={disabled}
            onClick={onOpen}
          >
            <NextIcon />
          </IconButton>
        </div>
      </Tooltip>
      <EditorSelectDialog
        open={open}
        onClose={onClose}
        mode="draft"
        title="Submit for review"
        listTitle={"Email reviewers"}
        emptyMessage="No reviewers added"
        searchPlaceholder="Add a reviewer"
        removeLabel="Remove reviewer"
        selected={reviewers}
        reviews={reviews}
        options={options}
        onUpdate={(reviewerIds) =>
          updateEditors(reviewerIds, {
            role: "reviewer",
            type: "user",
          })
        }
        actionLabel="SUBMIT FOR REVIEW"
        onAction={requestReview}
        errors={errors}
      >
        Add users to review your email. An email must be approved before it can be
        sent.
      </EditorSelectDialog>
    </>
  );
};
