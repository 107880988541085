import { NavigationLocationType } from "../state/NavigationLocation";

import { Email, EmailStatus } from "../../../types";

export class EmailUtils {
  /**
   * Get the expected navigation location type from the email path.
   * `/emails/:state/:emailId`
   *
   * @param state The `:state` param in the path.
   */
  public static getLocation(state: string): NavigationLocationType | null {
    switch (state) {
      case "draft":
        return "email-draft";
      case "pending":
        return "email-pending";
      case "approved":
        return "email-approved";
      case "sent":
        return "email-sent";
      case "review":
        return "email-review";
      default:
        return null;
    }
  }

  /**
   * Get the expected title from the email path.
   * `/emails/:state/:emailId`
   *
   * @param state The `:state` param in the path.
   */
  public static getTitle(state: string): string {
    switch (state) {
      case "draft":
        return "Drafts";
      case "pending":
        return "Pending";
      case "approved":
        return "Approved";
      case "sent":
        return "Sent";
      case "review":
        return "Review";
      case "view":
        return "";
      default:
        return "Not Found";
    }
  }

  /**
   * Get the expected email status from the email path.
   * `/emails/:state/:emailId`
   *
   * @param state The `:state` param in the path.
   */
  public static getEmailStatus(state: string): EmailStatus | "reviewable" {
    switch (state) {
      default:
      case "draft":
        return "draft";
      case "pending":
        return "review";
      case "approved":
        return "approved";
      case "sent":
        return "sent";
      case "review":
        return "reviewable";
    }
  }

  /**
   * Generate the appropriate path to view an email on based
   * on its status and the current user's id.
   *
   * @param email The email to inspect.
   * @param userId The current user's id.
   */
  public static getPath(email?: Email, userId?: string) {
    if (!email) return null;
    if (!userId) return null;

    let status: EmailStatus | "pending" = email.status;

    // If an editor is viewing an email under review AND they are
    // not also an approver, the email should be on the /pending path
    if (
      !email.reviewers.includes(userId) &&
      email.editors.includes(userId) &&
      email.status === "review"
    )
      status = "pending";

    // Emails in failed state belong to the /sent path
    if (email.status === "failed") status = "sent";

    return `/emails/${status}/${email.id}`;
  }
}
