import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import HistoryIcon from "@material-ui/icons/History";
import CheckIcon from "@material-ui/icons/CheckCircle";
import XIcon from "@material-ui/icons/Cancel";

import { EmailReview } from "../../../../../types";

import { useBoolean } from "../../../hooks/state";

import { ReviewHistoryDialog } from "./ReviewHistoryDialog";

import useStyles from "./styles";

export interface ReviewDisplayProps {
  className?: string;

  /**
   * The reviews that have been applied
   */
  reviews?: EmailReview[];

  /**
   * The mode the editor is in.
   */
  mode?: string;
}

/**
 * Renders a dialog that allows the user to select a list of users to
 * add as editors. Callback functions can be provided to customise what
 * happens when an editor is added or removed.
 */
export const ReviewDisplay = ({ className, reviews, mode }: ReviewDisplayProps) => {
  const classes = useStyles();

  const [showReviews, openReviews, closeReviews] = useBoolean(false);

  const latestReview = reviews?.[0];

  const approvalActive = mode === "approved" || mode === "sent";
  const rejectionActive = mode === "draft";

  return (
    <>
      {latestReview && (
        <Alert
          iconMapping={{
            success: <CheckIcon />,
            error: <XIcon />,
          }}
          severity={latestReview.approved ? "success" : "error"}
          className={className}
          classes={{ message: classes.reviewers }}
        >
          <div>
            <AlertTitle className={classes.reviewTitle}>
              {latestReview.approved
                ? approvalActive
                  ? "Approved by"
                  : "Previously approved by"
                : rejectionActive
                ? "Rejected by"
                : "Previously rejected by"}{" "}
              {latestReview.reviewerName}
            </AlertTitle>
            <Typography
              variant="body2"
              color={latestReview.comments ? undefined : "textSecondary"}
            >
              {latestReview.comments || <>No comment left</>}
            </Typography>
          </div>
          <Tooltip title="View review history" placement="top">
            <IconButton
              size="small"
              className={classes.reviewHistory}
              onClick={openReviews}
            >
              <HistoryIcon />
            </IconButton>
          </Tooltip>
        </Alert>
      )}
      {reviews && (
        <ReviewHistoryDialog
          open={showReviews}
          onClose={closeReviews}
          reviews={reviews}
        />
      )}
    </>
  );
};
