import { useMemo } from "react";
import clsx from "clsx";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import TokenIcon from "@material-ui/icons/More";
import CCIcon from "@material-ui/icons/PeopleOutline";
import BCCIcon from "@material-ui/icons/Group";

import { ParseWarning, ParseNotice, SharedUtils } from "../../../utils";

import { useBoolean } from "../../../hooks/state";

import useStyles from "./styles";

import { EmailRecipient } from "../../../../../types";

export interface EmailRecipientListItemProps {
  /**
   * The recipient to to show.
   */
  recipient?: EmailRecipient;

  /**
   * The warnings for the recipient.
   */
  warnings?: ParseWarning[];

  /**
   * The notices for the recipient.
   */
  notices?: ParseNotice[];

  /**
   * Show a relevant description when in read-only mode.
   */
  readOnly?: boolean;
}

/**
 * Render a recipient as a list item. This will show any
 * personalisations and warnings for the user if provided,
 * and are accessible in a dialog through icon buttons.
 */
export const EmailRecipientListItem = ({
  recipient,
  warnings,
  notices,
  readOnly,
}: EmailRecipientListItemProps) => {
  const classes = useStyles();

  const [showWarnings, openWarnings, closeWarnings] = useBoolean();
  const [showTokens, openTokens, closeTokens] = useBoolean();
  const [showCC, openCC, closeCC] = useBoolean();
  const [showBCC, openBCC, closeBCC] = useBoolean();
  const [showNotice, openNotice, closeNotice] = useBoolean();

  const blocking = useMemo(() => warnings?.some((w) => w.blocking), [warnings]);
  const { id = "", name = "", email = "" } = recipient ?? {};
  const identifier = name || email || id;

  const personalisations = useMemo(
    () => SharedUtils.generatePersonalisationTokens(recipient),
    [recipient]
  );

  return (
    <ListItem key={id} classes={{ gutters: classes.gutters }}>
      <ListItemAvatar>
        <Avatar className={classes.avatar}>{name[0]}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={name} secondary={email} />
      {(notices?.length ?? 0) > 0 && (
        <Tooltip title={`Notices for ${identifier}`} placement="left">
          <IconButton onClick={openNotice}>
            <InfoIcon color="primary" />
          </IconButton>
        </Tooltip>
      )}
      {(warnings?.length ?? 0) > 0 && (
        <Tooltip
          title={`${blocking ? "Errors" : "Warnings"} for ${identifier}`}
          placement="left"
        >
          <IconButton onClick={openWarnings}>
            <WarningIcon
              className={clsx({ [classes.warning]: !blocking })}
              color="error"
            />
          </IconButton>
        </Tooltip>
      )}
      {(recipient?.cc?.length ?? 0) > 0 && (
        <Tooltip title={`View CCs for ${identifier}`} placement="left">
          <IconButton onClick={openCC}>
            <CCIcon />
          </IconButton>
        </Tooltip>
      )}
      {(recipient?.bcc?.length ?? 0) > 0 && (
        <Tooltip title={`View BCCs for ${identifier}`} placement="left">
          <IconButton onClick={openBCC}>
            <BCCIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={`View personalisations for ${identifier}`} placement="left">
        <IconButton onClick={openTokens} edge="end">
          <TokenIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={showWarnings} onClose={closeWarnings} fullWidth maxWidth="sm">
        <DialogContent>
          <DialogContentText>
            Please review the following issues with this recipient.
          </DialogContentText>
          <List dense>
            {warnings?.map(({ id, message }) => (
              <ListItem key={`${id}${message}`}>
                <ListItemText secondary={message} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
      <Dialog open={showNotice} onClose={closeNotice} fullWidth maxWidth="sm">
        <DialogContent>
          <DialogContentText>
            Please check the following notices for this recipient.
          </DialogContentText>
          <List dense>
            {notices?.map(({ id, message }) => (
              <ListItem key={`${id}${message}`}>
                <ListItemText secondary={message} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
      <Dialog open={showTokens} onClose={closeTokens} fullWidth maxWidth="xs">
        <DialogContent>
          <DialogContentText>
            {readOnly ? "These tokens are used in" : "You can use these tokens in"}{" "}
            the email to personalise the email content for <b>{identifier}</b>.
          </DialogContentText>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">
                    Token
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">
                    Will be replaced by
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(personalisations)
                // Filter out empty personalisation tokens/
                // Hide date field
                .filter((key) => key !== "date" && personalisations[key])
                .map((key) => (
                  <TableRow key={key}>
                    <TableCell>
                      <span className={classes.select}>{`{{${key}}}`}</span>&nbsp;
                      {SharedUtils.RESERVED_TOKEN_NAMES.has(key) ? "*" : ""}
                    </TableCell>
                    <TableCell>
                      <span className={classes.select}>{personalisations[key]}</span>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.caption}
          >
            * system provided token
          </Typography>
        </DialogContent>
      </Dialog>
      <Dialog open={showCC} onClose={closeCC} fullWidth maxWidth="xs">
        <DialogContent>
          <DialogContentText>
            The email to {identifier} will also be CC'ed to the following emails.
          </DialogContentText>
          <List>
            {recipient?.cc?.map((cc) => (
              <ListItem key={cc}>
                <ListItemText primary={cc} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
      <Dialog open={showBCC} onClose={closeBCC} fullWidth maxWidth="xs">
        <DialogContent>
          <DialogContentText>
            The email to {identifier} will also be BCC'ed to the following emails.
          </DialogContentText>
          <List>
            {recipient?.bcc?.map((bcc) => (
              <ListItem key={bcc}>
                <ListItemText primary={bcc} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </ListItem>
  );
};
