import Typography from "@material-ui/core/Typography";

import { useCurrentEmailId, useEmailTemplate } from "../../../hooks/data/email";
import { useCanEditEmail } from "../../../hooks/data/user";

import EditorSubmitReviewAction from "../../../components/editorToolbar/EditorSubmitReviewAction";
import EditorCancelReviewAction from "../../../components/editorToolbar/EditorCancelReviewAction";
import EditorReviewAction from "../../../components/editorToolbar/EditorReviewAction";
import EditorSendAction from "../../../components/editorToolbar/EditorSendAction";
import EditorStatusSummaryAction from "../../../components/editorToolbar/EditorStatusSummaryAction";

import { useActionStyles } from "../styles";

export interface EditorToolbarProps {
  /**
   * The status determines the editor toolbar actions to show.
   */
  state?: string;

  /**
   * Disable buttons.
   */
  disabled?: boolean;
}

/**
 * This component renders the actions on the editor toolbar
 * based on the editor current state.
 */
export const EditorToolbar = ({ state, disabled }: EditorToolbarProps) => {
  const classes = useActionStyles();

  const emailId = useCurrentEmailId();
  const [isEditor, isReviewer] = useCanEditEmail(emailId);

  const [templateContent] = useEmailTemplate(emailId);
  const { template } = templateContent ?? {};

  if (state === "draft")
    return (
      <>
        <Typography variant="body1" className={classes.status}>
          DRAFT
        </Typography>
        {isEditor && template && <EditorReviewAction disabled={disabled} />}
      </>
    );

  if (state === "pending")
    return (
      <>
        <Typography variant="body1" className={classes.status}>
          PENDING
        </Typography>
        {isEditor && <EditorCancelReviewAction disabled={disabled} />}
      </>
    );

  if (state === "approved")
    return (
      <>
        <Typography variant="body1" className={classes.status}>
          APPROVED
        </Typography>
        {isEditor && (
          <>
            <EditorCancelReviewAction disabled={disabled} />
            <EditorSendAction disabled={disabled} />
          </>
        )}
      </>
    );

  if (state === "sent") return <EditorStatusSummaryAction disabled={disabled} />;

  if (state === "review")
    return (
      <>
        <Typography variant="body1" className={classes.status}>
          REVIEW
        </Typography>
        {isEditor && <EditorCancelReviewAction disabled={disabled} />}
        {isReviewer && <EditorSubmitReviewAction />}
      </>
    );

  return (
    <Typography variant="body1" className={classes.status}>
      {state?.toUpperCase()}
    </Typography>
  );
};
