import { memo } from "react";
import { FixedSizeList, ListChildComponentProps, areEqual } from "react-window";
import Autosizer from "react-virtualized-auto-sizer";

import EmailRecipientListItem, {
  EmailRecipientListItemProps,
} from "../../../components/editor/EmailRecipientListItem";

import { EmailRecipient } from "../../../../../types";

// import useStyles from "./styles";

export interface EmailRecipientListProps {
  /**
   * The list of recipients to show.
   */
  recipients?: EmailRecipient[];

  /**
   * This function can be used to personalise the props
   * passed to the `<EmailRecipientListItem />` component
   * for each recipient.
   */
  getProps?: (
    recipient?: EmailRecipient
  ) => Omit<EmailRecipientListItemProps, "recipient">;
}

/**
 * Renders a virtualised list of email recipients using `react-window`.
 * This allows large lists (up to hundreds of thousands) to be rendered
 * with relatively good performance on the UI.
 */
export const EmailRecipientList = ({
  recipients,
  getProps,
}: EmailRecipientListProps) => {
  // const classes = useStyles();
  return (
    <Autosizer>
      {({ width, height }) => (
        <FixedSizeList
          width={width}
          height={height}
          itemSize={56 + 8 + 8}
          itemCount={recipients?.length ?? 0}
        >
          {memo(({ index, style }: ListChildComponentProps) => {
            const recipient = recipients?.[index];
            return (
              <div key={recipient?.id} style={style as any}>
                <EmailRecipientListItem
                  recipient={recipient}
                  {...getProps?.(recipient)}
                />
              </div>
            );
          }, areEqual)}
        </FixedSizeList>
      )}
    </Autosizer>
  );
};
