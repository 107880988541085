import {
  EditorState,
  Modifier,
  SelectionState,
  ContentBlock,
  convertToRaw,
  genKey,
  ContentState,
  CharacterMetadata,
} from "draft-js";
import { Map, Iterable, List } from "immutable";

/**
 * Utilities for performing actions on DraftJS editor state.
 * Largely inspired by code from `draftjs-utils`.
 *
 * @see https://github.com/jpuri/draftjs-utils/blob/master/js/inline.js
 */
export class DraftUtils {
  /**
   * Returns collection of currently selected blocks.
   */
  public static getSelectedBlocksMap(
    editorState: EditorState
  ): Iterable<string, ContentBlock> {
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const startKey = selectionState.getStartKey();
    const endKey = selectionState.getEndKey();
    const blockMap = contentState.getBlockMap();
    return blockMap
      .toSeq()
      .skipUntil((_, k) => k === startKey)
      .takeUntil((_, k) => k === endKey)
      .concat([[endKey, blockMap.get(endKey)]]);
  }

  /**
   * Returns collection of currently selected blocks.
   *
   * @param editorState The current editor state.
   */
  public static getSelectedBlocksList(editorState: EditorState) {
    return DraftUtils.getSelectedBlocksMap(editorState).toList();
  }

  /**
   * Returns the first selected block.
   *
   * @param editorState The current editor state.
   */
  public static getSelectedBlock(editorState: EditorState) {
    return DraftUtils.getSelectedBlocksList(editorState).get(0);
  }

  /**
   * Get the key of the first entity from the current selection if there is one.
   *
   * @param editorState The current editor state.
   */
  public static getSelectionEntityKey(editorState: EditorState) {
    const selection = editorState.getSelection();
    let start = selection.getStartOffset();
    let end = selection.getEndOffset();

    if (start === end && start === 0) end = 1;
    else if (start === end) start -= 1;

    let entity: string | undefined;

    // Get first selected block
    const block = DraftUtils.getSelectedBlock(editorState);
    for (let i = start; i < end; i += 1) {
      const currentEntity = block.getEntityAt(i);
      if (!currentEntity) {
        entity = undefined;
        break;
      }
      if (i === start) {
        entity = currentEntity;
      } else if (entity !== currentEntity) {
        entity = undefined;
        break;
      }
    }

    return entity;
  }

  /**
   * Returns the range of given entity inside the block.
   *
   * @param editorState The current editor state.
   * @param entityKey The key of the entity to get the range for.
   */
  public static getEntityRange(
    editorState: EditorState,
    entityKey: string
  ): SelectionState | void {
    const block = DraftUtils.getSelectedBlock(editorState);
    let entityRange:
      | {
          start: number;
          end: number;
          text: string;
        }
      | undefined;

    block.findEntityRanges(
      (value) => value.getEntity() === entityKey,
      (start, end) => {
        entityRange = {
          start,
          end,
          text: block.get("text").slice(start, end),
        };
      }
    );

    if (entityRange) {
      const blockKey = block.getKey();
      let selectionState = SelectionState.createEmpty(blockKey).merge({
        anchorKey: blockKey,
        focusKey: blockKey,
        anchorOffset: entityRange.start,
        focusOffset: entityRange.end,
        isBackward: false,
      });
      return selectionState;
    }
  }

  /**
   * Retrieve the value of the block data with the given key in
   * the current block's block data.
   *
   * @param editorState The current editor state.
   * @param key The key of the block data to retrieve.
   */
  public static getCurrentBlockDataValue(editorState: EditorState, key: string) {
    const currentBlock = DraftUtils.getSelectedBlock(editorState);
    const blockData = currentBlock.getData();
    return blockData && blockData.get(key);
  }

  /**
   * Retrieve the current block data with the given key from the
   * currently selected block, and compare the value. If the value
   * is the same, return `undefined`.
   *
   * @param editorState The current editor state.
   * @param key The key of the block data to retrieve.
   * @param value The value to compare the current block data value to.
   */
  public static getToggleValueForBlockData(
    editorState: EditorState,
    key: string,
    value: string
  ) {
    return DraftUtils.getCurrentBlockDataValue(editorState, key) === value
      ? undefined
      : value;
  }

  /**
   * This function sets the blockData of the blocks in the current selection.
   *
   * @param editorState The current editor state.
   * @param key The key to set itn eh block data.
   * @param value The value to set in the block data.
   */
  public static mergeBlockDataOfCurrentSelection(
    editorState: EditorState,
    key: string,
    value: any
  ): EditorState {
    const content = editorState.getCurrentContent();

    // Get the current selection
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    let endKey = selection.getEndKey();

    // Find the maximum valid selection
    let target = selection;

    // Triple-click can lead to a selection that includes offset 0 of the
    // following block. The `SelectionState` for this case is accurate, but
    // we should avoid toggling block type for the trailing block because it
    // is a confusing interaction.
    if (startKey !== endKey && selection.getEndOffset() === 0) {
      const blockBefore = content.getBlockBefore(endKey);
      if (blockBefore) {
        endKey = blockBefore.getKey();
        target = target.merge({
          anchorKey: startKey,
          anchorOffset: selection.getStartOffset(),
          focusKey: endKey,
          focusOffset: blockBefore.getLength(),
          isBackward: false,
        });
      }
    }

    // Update block data
    return EditorState.push(
      editorState,
      Modifier.mergeBlockData(content, target, Map({ [key]: value })),
      "change-block-data"
    );
  }

  /**
   * Function to check if a block is of type list.
   */
  public static isListBlock(block: ContentBlock) {
    const blockType = block?.getType();
    return blockType === "unordered-list-item" || blockType === "ordered-list-item";
  }

  /**
   * Check if the content in two editor states have changed.
   *
   * @param currentState The current editor state
   * @param newState The new state to comapre against
   */
  public static didContentChange(currentState: EditorState, newState: EditorState) {
    // Check if content state references changed
    const currentContentState = currentState.getCurrentContent();
    const newContentState = newState.getCurrentContent();
    if (!currentContentState.equals(newContentState)) return true;

    // Check if inline style has changed
    const currentInlineStyle = currentState.getCurrentInlineStyle();
    const newInlineStyle = newState.getCurrentInlineStyle();
    if (
      !currentInlineStyle.equals(newInlineStyle) &&
      currentState.getLastChangeType()
    )
      return true;

    // Check if the entity map has changed
    // @see https://stackoverflow.com/questions/40911570/draftjs-triggers-content-change-on-focus
    const currentContentStateRaw = convertToRaw(currentContentState);
    const newContentStateRaw = convertToRaw(newContentState);
    const currentEntityMap = currentContentStateRaw.entityMap;
    const newEntityMap = newContentStateRaw.entityMap;

    for (const index of Object.keys(newEntityMap)) {
      // Check if the entity type has changed
      if (newEntityMap[index].type !== currentEntityMap[index]?.type) return true;

      for (const prop of Object.keys(newEntityMap[index].data)) {
        // Check if entity properties have changed
        if (newEntityMap[index].data[prop] !== currentEntityMap[index].data[prop])
          return true;
      }
    }

    return false;
  }

  /**
   * Collapse all blocks in the content state into one block.
   * This is useful for handling changes on plain text single-line fields.
   *
   * @param editorState The current editor state.
   *
   * Inspired by `draft-js-single-line-plugin` here:
   * @see https://github.com/icelab/draft-js-single-line-plugin/blob/master/src/index.js#L57
   */
  public static collapseBlocks(editorState: EditorState) {
    const contentState = editorState.getCurrentContent();
    const contentBlocks = contentState.getBlocksAsArray();

    // Don't need to do anything if we only have a single block
    const singleBlock = contentBlocks.length === 1;
    if (singleBlock) return editorState;

    let text = List<string>();
    let characterList = List<CharacterMetadata>();

    // Gather all text and characters and concatenate them
    contentBlocks.forEach((block, index) => {
      // Ignore atomic blocks
      if (block.getType() === "atomic") return;

      // Collect text and characters
      text = text.push(block.getText());
      characterList = characterList.concat(
        block.getCharacterList()
      ) as List<CharacterMetadata>;

      // Add a space between blocks
      if (index !== contentBlocks.length - 1) {
        text = text.push(" ");
        characterList = characterList.push(
          CharacterMetadata.create()
        ) as List<CharacterMetadata>;
      }
    });

    // Create new content block
    const contentBlock = new ContentBlock({
      key: genKey(),
      text: text.join(""),
      type: "unstyled",
      characterList,
      depth: 0,
    });

    // Update the editor state with the new block
    const newContentState = ContentState.createFromBlockArray([contentBlock]);

    // Create the new state as an undoable action
    editorState = EditorState.push(editorState, newContentState, "remove-range");

    // Move the selection to the end
    return EditorState.moveFocusToEnd(editorState);
  }
}
