export class SendGridUtils {
  /**
   * Map SendGrid event names to more user-friendly labels.
   *
   * @param event The SendGrid event.
   */
  static mapEventToLabel(event: string): string {
    switch (event) {
      case "processed":
        return "sent";
      case "delivered":
        return "received";
      default:
        return event;
    }
  }
}
