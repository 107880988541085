import { useRecoilValue } from "recoil";
import { EditorState } from "draft-js";
import clsx from "clsx";

import BoldIcon from "@material-ui/icons/FormatBold";
import ItalicIcon from "@material-ui/icons/FormatItalic";
import UnderlineIcon from "@material-ui/icons/FormatUnderlined";
import StrikethroughIcon from "@material-ui/icons/StrikethroughS";
import UnorderedListIcon from "@material-ui/icons/FormatListBulleted";
import OrderedListIcon from "@material-ui/icons/FormatListNumbered";
import LinkIcon from "@material-ui/icons/InsertLink";
// import SizeIcon from "@material-ui/icons/FormatSize";
// import ImageIcon from "@material-ui/icons/InsertPhoto";

import { DraftJsCurrentEntity } from "../../../../state/DraftJsEditor";

import { EditorToolbarButton } from "./EditorToolbarButton";

import { useToolbarStyles } from "../styles";

export interface EditorToolbarProps {
  classes?: {
    toolbar?: string;
  };

  /**
   * The key the rich text content is stored in in the Recoil state.
   */
  contentKey: string;

  /**
   * The current draft-js editor state.
   */
  editorState?: EditorState;

  /**
   * A function to update the draft-js editor state.
   */
  setEditorState?: (state: EditorState) => void;

  /**
   * Callback function for when an entity is requested to be created or modified.
   */
  modifyEntity?: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    entityType: string
  ) => void;
}

/**
 * This component shows the toolbar buttons for the rich text editor.
 */
export const EditorToolbar = ({
  classes: customClasses,
  contentKey,
  editorState,
  setEditorState,
  modifyEntity,
}: EditorToolbarProps) => {
  const classes = useToolbarStyles();

  const currentEntity = useRecoilValue(DraftJsCurrentEntity(contentKey));

  return (
    <div className={clsx(classes.toolbar, customClasses?.toolbar)}>
      {/* <EditorButton disabled tooltip="This feature is currently unavailable.">
        <SizeIcon fontSize="small" color="disabled" />
      </EditorButton> */}
      {/* <div className={classes.divider} /> */}
      <EditorToolbarButton
        inlineStyle="BOLD"
        tooltip="Bold"
        onChange={setEditorState}
        editorState={editorState}
      >
        <BoldIcon fontSize="small" />
      </EditorToolbarButton>
      <EditorToolbarButton
        inlineStyle="ITALIC"
        tooltip="Italic"
        onChange={setEditorState}
        editorState={editorState}
      >
        <ItalicIcon fontSize="small" />
      </EditorToolbarButton>
      <EditorToolbarButton
        inlineStyle="UNDERLINE"
        tooltip="Underline"
        onChange={setEditorState}
        editorState={editorState}
      >
        <UnderlineIcon fontSize="small" />
      </EditorToolbarButton>
      <EditorToolbarButton
        inlineStyle="STRIKETHROUGH"
        tooltip="Strikethrough"
        onChange={setEditorState}
        editorState={editorState}
      >
        <StrikethroughIcon fontSize="small" />
      </EditorToolbarButton>
      <div className={classes.divider} />
      <EditorToolbarButton
        blockType="ordered-list-item"
        tooltip="Numbered list"
        onChange={setEditorState}
        editorState={editorState}
      >
        <OrderedListIcon fontSize="small" />
      </EditorToolbarButton>
      <EditorToolbarButton
        blockType="unordered-list-item"
        tooltip="Bullet list"
        onChange={setEditorState}
        editorState={editorState}
      >
        <UnorderedListIcon fontSize="small" />
      </EditorToolbarButton>
      <div className={classes.divider} />
      <EditorToolbarButton
        tooltip="Create link"
        disabled={
          editorState?.getSelection().isCollapsed() &&
          currentEntity?.getType() !== "LINK"
        }
        entity={{
          type: "LINK",
          activeTooltip: "Edit link",
          onClick: (e) => modifyEntity?.(e, "LINK"),
        }}
      >
        <LinkIcon fontSize="small" />
      </EditorToolbarButton>
      {/* <EditorButton disabled tooltip="This feature is currently unavailable.">
        <ImageIcon fontSize="small" color="disabled" />
      </EditorButton> */}
    </div>
  );
};
