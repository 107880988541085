import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

// import useStyles from "./styles";

export interface TemplateUnavailableInfoDialogProps {
  /**
   * Whether the dialog is open.
   *
   * @default false
   */
  open?: boolean;

  /**
   * Callback function to close the dialog.
   */
  onClose?: () => void;
}

export function TemplateUnavailableInfoDialog(
  props: TemplateUnavailableInfoDialogProps
) {
  const { open = false, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Why this template is no longer available</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The template for this email is not available due to internal changes that
          resulted in some template IDs being regenerated. This issue only affects
          some older emails that have already been sent by MSend.
        </DialogContentText>
        <DialogContentText>
          All templates in your account are still available, and any new drafts will
          not be affect by this issue. If you want to duplicate this email, you will
          be able to select a working template to use with the email.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CLOSE</Button>
      </DialogActions>
    </Dialog>
  );
}
