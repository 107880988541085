import { useMemo } from "react";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { ContentType } from "../../../constants";
import { useBoolean } from "../../../hooks/state";
import { useFromAddresses, useReplyToAddresses } from "../../../hooks/data/editor";
import { useTemplatesGrouped } from "../../../hooks/data/template";
import {
  useEmailAttachments,
  useEmailDocument,
  useEmailTemplate,
} from "../../../hooks/data/email";
import { useSelectTemplate } from "../../../hooks/actions/emailData";
import { useCanEditEmail } from "../../../hooks/data/user";
import { useUserGroups } from "../../../hooks/data/userGroups";
import Loading from "../../../components/core/Loading";
import EmptyState from "../../../components/editor/EmptyState";
import TemplateEditor from "../../../components/editor/TemplateEditor";
import ContentEditor from "../../../components/editor/ContentEditor";
import SelectionEditor from "../../../components/editor/SelectionEditor";
import TemplateSelector from "../../../components/editor/TemplateSelector";
import TemplateUnavailableInfoDialog from "../../../components/dialogs/TemplateUnavailableInfoDialog";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import useStyles from "./styles";
import { fallbackTemplateString } from "./fallback";
import { EmailStatus } from "../../../../../types";
import { TextUtils } from "../../../utils";
import EditorAttachmentsAction from "../../../components/editorSidebar/EditorAttachmentsAction";

export interface EditorProps {
  /**
   * The mode the editor should be in. This limits the controls available to the user.
   *
   * @default "draft"
   */
  mode?: EmailStatus | "pending";

  /**
   * The id of the email to show in the editor.
   */
  emailId?: string;
}

/**
 * Accepts an emailId and retrieves the necessary data to render
 * an editor for the specified email.
 */
export const Editor = ({ mode = "draft", emailId }: EditorProps) => {
  const classes = useStyles();

  const selectTemplate = useSelectTemplate(emailId);
  const [email, loading] = useEmailDocument(emailId);
  const [emailAttachments, attachmentsLoading] = useEmailAttachments(emailId);
  const [groupedTemplates, , templatesLoading] = useTemplatesGrouped();
  const [templateContent, templateLoading, error] = useEmailTemplate(emailId);
  const { template, content, hasTemplate } = templateContent ?? {};

  const [groupsById] = useUserGroups();
  const [replyToOptions] = useReplyToAddresses(template?.group);
  const [fromOptions] = useFromAddresses(template?.group);
  const [isEditor] = useCanEditEmail(emailId);
  const [unavailableInfoOpen, showUnavailableInfo, hideUnavailableInfo] =
    useBoolean();

  // Extract template string
  const templateString = useMemo(
    () =>
      template?.versions?.find((v) => v.active)?.htmlContent ?? content?.html ?? "",
    [template, content]
  );

  if (!emailId) return <EmptyState />;

  if (loading || templateLoading || attachmentsLoading) {
    return (
      <div className={classes.loading}>
        <Loading title="" message="Loading content..." />
      </div>
    );
  }

  if (!email?.exists) {
    return (
      <Container maxWidth="sm" className={classes.container}>
        <Alert severity="error">Email does not exist</Alert>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="sm" className={classes.container}>
        <Alert severity="error">
          <AlertTitle>
            <b>Failed to retrieve email contents</b>
          </AlertTitle>
          {error.message}
        </Alert>
      </Container>
    );
  }

  if (!templateString) {
    if (mode === "draft") {
      return (
        <div className={classes.wrapper}>
          <Container maxWidth="md" className={classes.container}>
            {hasTemplate ? (
              <>
                <Alert severity="warning">
                  The previously selected template is{" "}
                  <Link
                    className={classes.unavailable}
                    onClick={showUnavailableInfo}
                  >
                    is no longer available
                  </Link>
                  . Please select a new template for this email.
                </Alert>
                <TemplateUnavailableInfoDialog
                  open={unavailableInfoOpen}
                  onClose={hideUnavailableInfo}
                />
              </>
            ) : (
              <Alert severity="info">Select a template for this email.</Alert>
            )}

            {templatesLoading ? (
              <LinearProgress />
            ) : (
              Object.keys(groupedTemplates).map((group) => (
                <div key={group} className={classes.group}>
                  <Typography variant="body1">
                    <b>{groupsById?.[group]?.name ?? group} templates</b>
                  </Typography>
                  <Grid container spacing={2} className={classes.grid}>
                    <TemplateSelector
                      templates={groupedTemplates[group]}
                      onSelectTemplate={selectTemplate}
                    />
                  </Grid>
                </div>
              ))
            )}
          </Container>
        </div>
      );
    } else {
      if (!hasTemplate)
        return (
          <Container maxWidth="sm" className={classes.container}>
            <Alert severity="warning">
              <AlertTitle>
                <b>No template</b>
              </AlertTitle>
              This email does not have a template selected and cannot be displayed.
            </Alert>
          </Container>
        );
    }
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.field}>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.fieldLabel}
        >
          <b>SUBJECT</b>
        </Typography>
        <div className={classes.subjectField}>
          <ContentEditor
            readOnly={mode !== "draft" || !isEditor}
            contentType={ContentType.SINGLE_LINE_TEXT}
            contentKey="subject"
          />
        </div>
      </div>
      <Divider />
      <div className={classes.field}>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.fieldLabel}
        >
          <b>FROM</b>
        </Typography>
        <SelectionEditor
          readOnly={mode !== "draft" || !isEditor}
          contentKey="from"
          options={fromOptions}
          defaultValue={fromOptions?.[0]?.value}
          placeholder="Please select a from address."
        />
      </div>
      <Divider />
      <div className={classes.field}>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.fieldLabel}
        >
          <b>REPLY TO</b>
        </Typography>
        <SelectionEditor
          readOnly={mode !== "draft" || !isEditor}
          contentKey="replyTo"
          options={replyToOptions}
          defaultValue={replyToOptions?.[0]?.value}
          placeholder="Please select a reply-to address."
        />
      </div>
      <Divider />
      {emailAttachments && emailAttachments.length > 0 && (
        <div className={classes.fieldAttachments}>
          <Typography
            component={"span"}
            variant="body2"
            color="textSecondary"
            className={classes.fieldLabel}
          >
            <EditorAttachmentsAction
              className={classes.paperClip}
              emailId={emailId}
              disabled={!emailId || !hasTemplate}
              readOnly={mode !== "draft" || !isEditor}
            />
          </Typography>
          <Tooltip
            title={`Email contains ${TextUtils.pluralWithCount(
              emailAttachments?.length ?? 0,
              "attachment"
            )}`}
            placement="bottom-start"
          >
            <div className={classes.chips}>
              {emailAttachments.map((attachment) => (
                <Chip
                  key={attachment.name}
                  label={attachment.name}
                  size={"small"}
                  variant={"outlined"}
                />
              ))}
            </div>
          </Tooltip>
        </div>
      )}
      <Divider />
      {!templateString && (
        <>
          <Alert severity="warning">
            The template this email was originally created with{" "}
            <Link className={classes.unavailable} onClick={showUnavailableInfo}>
              is no longer available
            </Link>
            . Email contents are being displayed with a fallback template.
          </Alert>
          <TemplateUnavailableInfoDialog
            open={unavailableInfoOpen}
            onClose={hideUnavailableInfo}
          />
        </>
      )}
      <TemplateEditor
        template={templateString || fallbackTemplateString}
        readOnly={mode !== "draft" || !isEditor}
      />
    </div>
  );
};
