import { useEffect } from "react";
import { useSetRecoilState } from "recoil";

import { DocumentTitleState } from "./atoms";

/**
 * Set the document title.
 */
export const useDocumentTitle = (title: string = "") => {
  const setTitle = useSetRecoilState(DocumentTitleState);
  useEffect(() => setTitle(title), [title, setTitle]);
};
