import { useCallback } from "react";
import { useParams } from "react-router-dom";

import { useFirestore } from "../../../contexts/firebase/FirebaseContext";
import { useCurrentUser } from "../../../state/FirebaseAuthState";

import { useDocumentData, useQueryData } from "../../firebase";
import { useLogError } from "../../log";

import { Email, EmailStatus } from "../../../../../types";

/**
 * Retrieve the id of the current email stored in state.
 */
export const useCurrentEmailId = () => {
  const { emailId } = useParams();
  return emailId;
};

/**
 * Retrieve the email with the specified id.
 *
 * @param emailId The id of the email to retrieve.
 */
export const useEmail = (
  emailId?: string
): [Email | undefined, boolean, Error | undefined] => {
  const [emails, loading, error] = useDocumentData<Email>(
    emailId && `emails/${emailId}`
  );
  useLogError(`Error while retrieving <email|${emailId}>.`, "hook-useEmail", error);
  return [emails, loading, error];
};

/**
 * Returns a function that fetches an email once.
 *
 * @param emailId The id of the email to retrieve.
 */
export const useFetchEmailById = (emailId?: string) => {
  const store = useFirestore();
  return useCallback(async () => {
    if (!store) return undefined;
    if (!emailId) return undefined;
    const snapshot = await store.doc(`emails/${emailId}`).get();
    return snapshot.data() as Email;
  }, [store, emailId]);
};

/**
 * Get a list of emails for the current user.
 *
 * @param options.type Get emails as editor or reviewer.
 * @param options.status The status of the email to get.
 * @param options.limit Limit the number of emails retrieved.
 */
export const useEmailList = (options?: {
  type?: "editors" | "reviewers";
  status?: EmailStatus;
  limit?: number;
}): [Email[] | undefined, boolean, Error | undefined] => {
  const { type = "editors", status, limit } = options ?? {};

  const user = useCurrentUser();

  const [emails, loading, error] = useQueryData<Email>("emails", [
    // FIXME: causes a crash when the user is in too many groups
    // as the "array-contains-any" filter is limited to 10 filters.
    // For now, we do not need this feature since emails are not
    // shared with groups. If we need this in the future, then I
    // suggest using a cloud function to query for group emails.
    // {
    //   op: "where",
    //   args: [type, "array-contains-any", [user?.uid, ...(user?.groups ?? [])]],
    // },
    {
      op: "where",
      args: [type, "array-contains", user?.uid],
    },
    status
      ? {
          op: "where",
          args: ["status", "==", status],
        }
      : null,
    {
      op: "orderBy",
      args: ["modified", "desc"],
    },
    limit
      ? {
          op: "limit",
          args: [limit],
        }
      : null,
  ]);

  useLogError("Error while retrieving emails.", "hook-useEmailList", error);

  return [emails, loading, error];
};
