import { useState, useEffect } from "react";

/**
 * This hook debounces a value that might change frequently.
 * Taken from https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci.
 *
 * @param value The value to debounce.
 * @param delay The delay time. Default is 500ms.
 */
export const useDebounced = <T>(value: T, delay: number = 500): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  // Set debouncedValue to value (passed in) after the specified delay.
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // If the given value changes, reset the timer.
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
