import { useCallback, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import LinearProgress from "@material-ui/core/LinearProgress";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";

import BackIcon from "@material-ui/icons/ChevronLeft";
import RefreshIcon from "@material-ui/icons/Refresh";

import { ColRef } from "../../../utils";

import { useDocumentTitle } from "../../../state/DocumentTitle";
import { useSetNavigationLocation } from "../../../state/NavigationLocation";

import { useFirestore } from "../../../contexts/firebase/FirebaseContext";
import { useEmail, useLatestEmailTasks } from "../../../hooks/data/email";

import NotFoundPage from "../../core/NotFoundPage";
import ForbiddenPage from "../../core/ForbiddenPage";

import PageTitle from "../../../components/core/PageTitle";
import EmailDeliveryStatusPanel from "../../../components/status/EmailDeliveryStatusPanel";
import EmailRecipientStatusPanel from "../../../components/status/EmailRecipientStatusPanel";

import useStyles from "./styles";

import { EmailRecipient } from "../../../../../types";

/**
 * This page shows the status of an eamil based on feedback from SendGrid's webhook events.
 */
export function EmailStatusPage() {
  const classes = useStyles();
  const { emailId = "" } = useParams();

  useDocumentTitle("Email Status");
  useSetNavigationLocation("email-sent");

  const [email, emailLoading, emailError] = useEmail(emailId);
  const [tasks, tasksLoading] = useLatestEmailTasks(emailId);

  const store = useFirestore();
  const [recipientsLoading, setRecipientsLoading] = useState(false);
  const [recipients, setRecipients] = useState<EmailRecipient[]>([]);

  // Fetch recipients to display on the page
  const fetchRecipients = useCallback(() => {
    if (!store) return;
    if (!emailId) return;

    setRecipientsLoading(true);

    const ref = store.collection(
      `emails/${emailId}/recipients`
    ) as ColRef<EmailRecipient>;

    ref.get().then((snap) => {
      setRecipients(snap.docs.map((doc) => doc.data()));
      setRecipientsLoading(false);
    });
  }, [store, emailId, setRecipients]);

  // Fetch recipients when we have a valid email id
  useEffect(() => {
    fetchRecipients();
    return () => {
      setRecipients([]);
    };
  }, [fetchRecipients]);

  if (emailLoading) return <LinearProgress />;

  if (emailError)
    return (
      <ForbiddenPage
        message={`Could not retrieve email status data. You may not have permission to view this content.`}
      />
    );

  if (!email) return <NotFoundPage message="The email does not exist." />;

  const { subject } = email?.preview ?? {};

  return (
    <div>
      <PageTitle width="md" disableTypography>
        {subject ? (
          <>
            <Typography variant="button" className={classes.heading}>
              <b>Email Status for</b>
            </Typography>
            <Typography variant="h4" variantMapping={{ h4: "h1" }}>
              {subject}
            </Typography>
          </>
        ) : (
          <Typography variant="h4" variantMapping={{ h4: "h1" }}>
            Email Status
          </Typography>
        )}
      </PageTitle>
      <Container maxWidth="md" className={classes.container}>
        <div className={classes.actions}>
          <Button
            className={classes.action}
            startIcon={<BackIcon />}
            component={Link}
            to={`/emails/view/${emailId}`}
          >
            Back
          </Button>
          <Tooltip title="Refresh" placement="top">
            <IconButton className={classes.action} onClick={fetchRecipients}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </div>
        <Grid container spacing={2}>
          <EmailDeliveryStatusPanel
            loading={emailLoading || tasksLoading || recipientsLoading}
            email={email}
            tasks={tasks}
            recipients={recipients}
          />
          <EmailRecipientStatusPanel
            loading={recipientsLoading}
            email={email}
            recipients={recipients}
          />
        </Grid>
      </Container>
    </div>
  );
}
