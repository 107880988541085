import { RecoilRoot } from "recoil";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { FirebaseProvider } from "./contexts/firebase/FirebaseContext";

import Theme from "./components/core/Theme";
import ErrorBoundary from "./components/core/ErrorBoundary";
import DocumentTitle from "./components/core/DocumentTitle";
import Secure from "./components/core/Secure";

import CrashPage from "./pages/core/CrashPage";
import SignOutPage from "./pages/core/SignOutPage";
import NotFoundPage from "./pages/core/NotFoundPage";
import DashboardPage from "./pages/user/DashboardPage/loadable";
import EditorPage from "./pages/user/EditorV2Page";
import EmailStatusPage from "./pages/user/EmailStatusPage";
import SystemV2Page from "./pages/system/SystemV2Page/loadable";
import SystemInfoPage from "./pages/system/SystemInfoPage";
import SystemUsersPage from "./pages/system/SystemUsersPage/loadable";
import SystemSettingsPage from "./pages/system/SystemSettingsPage/loadable";
import SystemAlertsPage from "./pages/system/SystemAlertsPage/loadable";
import SystemTemplatesPage from "./pages/system/SystemTemplatesPage/loadable";
import SystemLogsPage from "./pages/system/SystemLogsV2Page/loadable";
import SystemBackupsPage from "./pages/system/SystemBackupsPage/loadable";
import AdminPage from "./pages/admin/AdminPage/loadable";
import AdminLogsPage from "./pages/admin/AdminLogsPage/loadable";
import ReportPage from "./pages/report/ReportPage/loadable";
import ChangelogPage from "./pages/core/ChangelogPage";

const App = () => (
  <HelmetProvider>
    <Theme>
      <RecoilRoot>
        <ErrorBoundary
          onError={(error) => (
            <CrashPage title="Application crashed" error={error} />
          )}
        >
          <DocumentTitle />
          <FirebaseProvider>
            <BrowserRouter>
              <ApplicationRoutes />
            </BrowserRouter>
          </FirebaseProvider>
        </ErrorBoundary>
      </RecoilRoot>
    </Theme>
  </HelmetProvider>
);

const ApplicationRoutes = () => (
  <Routes>
    <Route path="/secure/exit" element={<SignOutPage />} />
    <Route path="/" element={<Secure />}>
      <Route index element={<Navigate to="/dashboard" />} />
      <Route path="dashboard" element={<DashboardPage />} />
      <Route path="changelog" element={<ChangelogPage />} />
      <Route path="emails">
        <Route path=":state" element={<EditorPage />} />
        <Route path=":state/:emailId" element={<EditorPage />} />
        <Route path="view/:emailId/status" element={<EmailStatusPage />} />
      </Route>
      <Route path="admin" element={<AdminPage />}>
        <Route index element={<Navigate to="logs" replace />} />
        <Route path="logs" element={<AdminLogsPage />} />
      </Route>
      <Route path="report" element={<ReportPage />} />
      <Route path="system" element={<SystemV2Page />}>
        <Route index element={<Navigate to="general" replace />} />
        <Route path="general" element={<SystemInfoPage />} />
        <Route path="users" element={<SystemUsersPage />} />
        <Route path="settings" element={<SystemSettingsPage />} />
        <Route path="alerts" element={<SystemAlertsPage />} />
        <Route path="templates" element={<SystemTemplatesPage />} />
        <Route path="logs" element={<SystemLogsPage />} />
        <Route path="backups" element={<SystemBackupsPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Route>
  </Routes>
);

export default App;
