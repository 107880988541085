import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    heading: {
      opacity: 0.8,
    },
    actions: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: theme.spacing(1),
    },
    action: {
      color: "white",
    },
    container: {
      marginBottom: theme.spacing(8),
    },
  })
);
