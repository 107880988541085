import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    icon: {
      fontSize: "1rem",
      marginLeft: theme.spacing(1),
    },
    caption: {
      display: "flex",
    },
  })
);
