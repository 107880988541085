import { useEffect, useRef, useState, useCallback } from "react";
import ReactDOM from "react-dom";

import { CLogger, TextUtils } from "../../../utils";

import ContentEditor from "../../../components/editor/ContentEditor";

// import useStyles from "./styles";

export interface TemplateEditorProps {
  /**
   * The template string to render the editor into.
   */
  template?: string;

  /**
   * Show the editor in read-only mode.
   */
  readOnly?: boolean;
}

const log = CLogger.category("components.editor.TemplateEditor");

const MOUNT_ATTEMPTS = 5;

/**
 * The email editor takes a HTML string template and mounts `<ContentEditor />`
 * components on nodes with the  `data-bms-content-type` attribute.
 */
export const TemplateEditor = ({ template, readOnly }: TemplateEditorProps) => {
  // const classes = useStyles();

  const ref = useRef<HTMLDivElement | null>();
  const [nodes, setNodes] = useState<Element[]>();

  /**
   * This function queries the element that has rendered the template for
   * any matching nodes. If it finds no nodes, it tries again up to a maximum
   * of `MOUNT_ATTEMPTS` times.
   */
  const mountContentEditors = useCallback(
    (attempts: number) => {
      // Find nodes with the required attribute in the div we injected the template into
      const nodes = ref.current?.querySelectorAll("[data-msend-type]");
      // If we failed to find any nodes, wait a little while then try again
      if (!nodes || nodes.length === 0) {
        if (attempts < MOUNT_ATTEMPTS) {
          log({
            level: "info",
            message: `Failed to mount editor on attempt ${attempts}`,
          });
          // Wait to re-render
          const timeout = setTimeout(() => mountContentEditors(attempts + 1), 500);
          // Cancel the timer on re-render
          return () => {
            clearTimeout(timeout);
          };
        }
      } else {
        // If nodes were found, remember them in state
        const nodeCollector: Element[] = [];
        nodes.forEach((node) => {
          // Remove any hbs tokens
          node.innerHTML = node.innerHTML.replace(/{{{\w+}}}/, "");
          nodeCollector.push(node);
        });
        setNodes(nodeCollector);
        log({
          level: "debug",
          message: `Found ${TextUtils.pluralWithCount(
            nodes.length,
            "node"
          )} to mount on attempt ${attempts}.`,
        });
      }
    },
    [setNodes]
  );

  // Mount content editors
  useEffect(() => {
    if (template) return mountContentEditors(0);
  }, [mountContentEditors, template]);

  return (
    <>
      <div
        ref={(el) => (ref.current = el)}
        dangerouslySetInnerHTML={{
          __html: template ?? "",
        }}
      />
      {nodes?.map((node) => {
        return ReactDOM.createPortal(
          <ContentEditor
            readOnly={readOnly}
            contentType={node.getAttribute("data-msend-type")}
            contentKey={node.getAttribute("data-msend-key")}
          />,
          node
        );
      })}
    </>
  );
};
