import { useState, useEffect, useCallback } from "react";
import { useRecoilValue } from "recoil";
import moment from "moment/moment";
import clsx from "clsx";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from "@material-ui/core/List";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Link from "@material-ui/core/Link";

import { CLogger } from "../../../utils";

import {
  PersonalisationsState,
  PersonalisationsStateType,
} from "../../../state/Personalisations";
import { TriggerType, useIncrementTrigger } from "../../../state/Triggers";

import { useBoolean } from "../../../hooks/state";
import { useDesktopBreakpoint } from "../../../hooks/breakpoint";

import EmailRecipientList from "../../../components/editor/EmailRecipientList";
import PersonalisationUploadDialog from "../../../components/dialogs/PersonalisationUploadDialog";
import PersonalisationFilterField from "../../../components/editor/PersonalisationFilterField";
import LoadingText from "../../../components/core/LoadingText";

import useStyles from "./styles";

import { EmailRecipient, EmailTask } from "../../../../../types";

const log = CLogger.category("components.dialogs.PersonalisationDialog");

export interface PersonalisationDialogProps {
  /**
   * Whether the dialog is open.
   *
   * @default false
   */
  open?: boolean;

  /**
   * Callback function to close the dialog.
   */
  onClose?: () => void;

  /**
   * Show a loading indicator.
   */
  loading?: boolean;

  /**
   * If `true`, personlisation data will be shown but all editing
   * functions will be disabled.
   */
  readOnly?: boolean;

  /**
   * The recipients to show in the dialog.
   */
  recipients?: EmailRecipient[];

  /**
   * The task to show if recipients are currently being updated.
   */
  task?: EmailTask;

  /**
   * Callback function for when the user saves recipients.
   */
  onSetRecipients?: (file: File) => Promise<void>;
}

/**
 * This dialog shows the current recipients and personalisations
 * for the selected email.
 */
export const PersonalisationDialog = ({
  open = false,
  onClose,
  loading,
  readOnly,
  recipients,
  task,
  onSetRecipients,
}: PersonalisationDialogProps) => {
  const classes = useStyles();
  const desktop = useDesktopBreakpoint();

  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [showUpload, openUpload, closeUpload] = useBoolean();

  const triggerEditorRefresh = useIncrementTrigger(
    TriggerType.EDITOR_CONTENT_REFRESH
  );

  const recipientsToShow = useRecoilValue(
    PersonalisationsState(PersonalisationsStateType.RECIPIENTS)
  );

  const noRecipients = (recipients?.length ?? 0) === 0;
  const noRecipientsToShow = (recipientsToShow?.length ?? 0) === 0;

  // Reset state when we open the dialog
  useEffect(() => {
    if (!open) return;
    setSaving(false);
    setSaved(false);
  }, [open]);

  // If a task shows up, we can remove the saved state
  useEffect(() => {
    if (task) setSaved(false);
  }, [task]);

  /**
   * This will set the given recipients for the current email.
   *
   * @param recipients The recipients to save.
   */
  const onSaveHandler = useCallback(
    async (file: File) => {
      closeUpload();
      setSaving(true);
      try {
        await onSetRecipients?.(file);
        // We use the 'saved' state to indicate to the user that their file
        // was received and that processing will begin shortly.
        setSaved(true);
      } catch (error: any) {
        log({
          level: "error",
          message: `Error while saving recipients: ${error.message}`,
          data: error,
        });
      } finally {
        setSaving(false);
      }
    },
    [closeUpload, onSetRecipients]
  );

  const started = task?.startedOn?.toDate().getTime() ?? new Date().getTime();
  const duration = (Date.now() - started) / 1000;

  const taskTimedOut = task?.status === "in-progress" && duration > 5 * 60;
  const taskInProgress = !saving && task?.status === "in-progress";
  const taskFailed = !saving && task?.status === "failed";

  // Refresh the editor when a task changes from in progress to not in progress
  // This typically indicates that a CSV has finished processing and so new tokens
  // my need to be refreshed in the editor.
  useEffect(() => {
    if (!taskInProgress) triggerEditorRefresh();
  }, [taskInProgress, triggerEditorRefresh]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!saving) onClose?.();
      }}
      maxWidth="xs"
      fullWidth
      fullScreen={!desktop}
    >
      <DialogTitle>Personalisations</DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText className={classes.text}>
          Personalisations {readOnly ? "specify" : "allow you to specify"} the
          recipients for this email and what data to render for recipient.
        </DialogContentText>
        {(saving || loading) && <LinearProgress />}
      </DialogContent>
      <Alert severity="info" className={classes.alert}>
        <b>Sending multiple emails to the same email address</b> (as a recipient, CC
        or BCC) could exceed email service provider limits, leading to potential
        issues such as blocked mailboxes.{" "}
        <Link href="https://support.google.com/a/answer/1366776?hl=en">
          More info
        </Link>
      </Alert>
      <Typography
        color="textSecondary"
        variant="button"
        className={classes.listHeading}
      >
        RECIPIENTS {recipients?.length ? `(${recipients.length})` : ""}
      </Typography>
      {saving && (
        <Alert severity="info" className={classes.alert}>
          <AlertTitle>
            <b>Uploading CSV</b>
          </AlertTitle>
          Do not close this window until upload is complete.
        </Alert>
      )}
      {saved && (
        <Alert severity="success" className={classes.alert}>
          <AlertTitle>
            <b>CSV uploaded</b>
          </AlertTitle>
          Processing will begin shortly.
        </Alert>
      )}
      {taskInProgress && !taskTimedOut ? (
        <LoadingText
          className={classes.loading}
          type="linear"
          progress={(task?.progress ?? 0) * 100}
          disableTypography
        >
          <Typography variant="body1" color="textSecondary">
            {task?.statusInfo}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Background task. You may close this window.
          </Typography>
        </LoadingText>
      ) : (
        <>
          {taskFailed && (
            <Alert severity="error" className={classes.errorAlert} >
              <AlertTitle>
                <b>Latest CSV upload failed</b>
              </AlertTitle>
              {task?.statusInfo}{" "}
              {task?.completedOn
                ? `(${moment(task?.completedOn.toDate()).fromNow()})`
                : ""}
            </Alert>
          )}
          {taskTimedOut && (
            <Alert severity="error" className={classes.alert}>
              <AlertTitle>
                <b>Processing timed out</b>
              </AlertTitle>
              Something might have gone wrong with your processing task. Please
              contact support.
            </Alert>
          )}

          <PersonalisationFilterField
            stateKey={PersonalisationsStateType.RECIPIENTS}
            className={classes.filter}
            reset={open}
            recipients={recipients}
            disabled={noRecipients}
            fullWidth
            size="small"
            placeholder={
              noRecipients
                ? "Please upload a CSV file to get started"
                : "Search recipient by name or email..."
            }
          />

          <List
            disablePadding
            className={clsx(classes.list, {
              [classes.empty]: noRecipientsToShow,
            })}
          >
            {noRecipientsToShow ? (
              loading ? (
                <Typography variant="body1" color="textSecondary">
                  Loading...
                </Typography>
              ) : (
                <>
                  <Typography variant="body1" color="textSecondary">
                    No recipients
                  </Typography>
                  {!readOnly && noRecipients && (
                    <Typography variant="body2" color="textSecondary">
                      Upload CSV to get started.
                    </Typography>
                  )}
                </>
              )
            ) : (
              <EmailRecipientList
                recipients={recipientsToShow}
                getProps={() => ({ readOnly })}
              />
            )}
          </List>
        </>
      )}
      <DialogActions>
        <Button disabled={saving} onClick={onClose}>
          CLOSE
        </Button>
        {!readOnly && (
          <Button
            variant="outlined"
            color="primary"
            disabled={saving || saved || taskInProgress}
            onClick={openUpload}
          >
            UPLOAD NEW CSV
          </Button>
        )}
      </DialogActions>
      <PersonalisationUploadDialog
        open={showUpload}
        onClose={closeUpload}
        onSave={onSaveHandler}
      />
    </Dialog>
  );
};
