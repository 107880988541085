import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    grid: {
      marginTop: theme.spacing(2),
    },
    media: {
      height: 150,
      width: "100%",
      backgroundPosition: "top",
    },
    card: {
      height: "100%",
    },
    action: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    templateInfo: {
      width: "100%",
    },
    templateName: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  })
);
