import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    loadingButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    loading: {
      width: "100%",
      top: 0,
      left: 0,
      position: "absolute",
    },
  })
);
