import { useEffect, useState } from "react";
import deepequal from "fast-deep-equal";

import { useFirestore } from "../../contexts/firebase/FirebaseContext";

import {
  Query,
  PathOrColReference,
  QueryOp,
  generateQuery,
  QuerySnapshot,
  SnapshotOptions,
} from "./utils";
import { useCollectionReference } from "./useCollection";

/**
 * Creates and caches a query reference from a path or a
 * `firestore.CollectionReference` object and a list of
 * query operations.
 *
 * @param path The path to the collection we are querying.
 * @param operations The operations to apply to the query.
 * @returns `ref`
 */
export const useQueryReference = <T>(
  path?: PathOrColReference<T>,
  operations?: (QueryOp | undefined | null)[] | null
): Query<T> | undefined => {
  const store = useFirestore();
  const [ops, setOps] = useState(operations);
  const [query, setQuery] = useState<Query<T>>();

  const ref = useCollectionReference(path);

  // Update operations if they change
  useEffect(() => {
    if (!deepequal(operations, ops)) setOps(operations);
  }, [operations, ops, setOps]);

  // Create reference
  useEffect(() => {
    if (!store) return;
    if (!path || !ref || !ops) {
      // Reset the query if the path or ref becomes empty
      setQuery(undefined);
      return;
    }
    setQuery(generateQuery(ref, ops));
  }, [store, path, ref, ops, setQuery]);

  return query;
};

/**
 * Create a query from the given collection path and query
 * operations and listen to the resulting query snapshots.
 *
 * @param path The path to the collection we are querying.
 * @param operations The operations to apply to the query.
 * @returns `[snapshot, loading, error]`
 */
export const useQuery = <T>(
  path?: PathOrColReference<T>,
  operations?: (QueryOp | undefined | null)[] | null
): [QuerySnapshot<T> | undefined, boolean, Error | undefined] => {
  const ref = useQueryReference(path, operations);

  const defaultLoading = Boolean(path && operations);
  const [loading, setLoading] = useState(defaultLoading);
  const [error, setError] = useState<Error>();
  const [snapshot, setSnapshot] = useState<QuerySnapshot<T>>();

  // Listen for query updates
  useEffect(() => {
    // If there is no ref, reset data
    if (!ref?.onSnapshot) {
      setLoading(defaultLoading);
      setSnapshot(undefined);
      setError(undefined);
      return;
    }

    let cancelled = false;
    setLoading(true);

    const unsubscribe = ref?.onSnapshot(
      (snapshot) => {
        if (cancelled) return;
        setSnapshot(snapshot);
        setLoading(false);
        setError(undefined);
      },
      (error) => {
        if (cancelled) return;
        setLoading(false);
        setError(error);
      }
    );

    return () => {
      cancelled = true;
      if (unsubscribe) unsubscribe();
    };
  }, [ref, defaultLoading, setLoading, setSnapshot, setError]);

  return [snapshot, loading, error];
};

/**
 * Create a query from the given collection path and query
 * operations and listen to the data in the query snapshot
 * results.
 *
 * @param path The path to the collection we are querying.
 * @param operations The operations to apply to the query.
 * @returns `[data, loading, error]`
 */
export const useQueryData = <T>(
  path?: PathOrColReference<T>,
  operations?: (QueryOp | undefined | null)[] | null,
  options?: SnapshotOptions
): [T[] | undefined, boolean, Error | undefined] => {
  const [snapshot, loading, error] = useQuery(path, operations);
  const [ops, setOps] = useState(options);
  const [data, setData] = useState<T[]>();

  // Update options if they change
  useEffect(() => {
    if (!deepequal(options, ops)) setOps(options);
  }, [options, ops, setOps]);

  // If the snapshot changes, extract the data
  useEffect(() => {
    setData(snapshot?.docs.map((snap) => snap.data(ops)));
  }, [setData, snapshot, ops]);

  return [data, loading, error];
};
