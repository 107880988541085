import { makeStyles, createStyles, Theme, darken } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    reviews: {
      marginBottom: theme.spacing(2),
    },
    comment: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    reject: {
      "color": theme.palette.error.contrastText,
      "backgroundColor": theme.palette.error.main,
      "&:hover": {
        backgroundColor: darken(theme.palette.error.main, 0.2),
      },
    },
    cancel: {
      marginRight: "auto",
    },
  })
);
