import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      margin: "auto",
      padding: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    property: {
      marginBottom: theme.spacing(2),
    },
  })
);
