import {
  makeStyles,
  createStyles,
  Theme,
  lighten,
  darken,
} from "@material-ui/core/styles";

import { Color } from "../../../constants";

export default makeStyles<Theme>((theme) =>
  createStyles({
    // List
    list: {
      height: "100vh",
      overflowY: "auto",
    },
    header: {
      position: "sticky",
      top: 0,
      zIndex: 1,
      backgroundColor: theme.palette.common.white,
    },

    // Search
    search: {
      padding: theme.spacing(1),
      display: "flex",
    },
    adornedEnd: {
      paddingRight: theme.spacing(1),
    },
    action: {
      marginLeft: theme.spacing(1),
      alignSelf: "center",
    },

    // Heading
    heading: {
      minHeight: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
    },

    // Empty
    empty: {
      display: "flex",
      justifyContent: "center",
      marginTop: "30vh",
    },

    // Email item
    item: {
      "borderBottom": `1px solid ${theme.palette.divider}`,
      "&:hover": {
        backgroundColor: lighten(Color.SELECTED_TINT, 0.5),
      },
    },
    selected: {
      "backgroundColor": Color.SELECTED_TINT,
      "&:hover": {
        backgroundColor: darken(Color.SELECTED_TINT, 0.2),
      },
    },
    preview: {
      height: "6rem",
      overflow: "hidden",
      width: "100%",
    },
    modified: {
      top: theme.spacing(1.5),
      transform: "unset",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    attachment: {
      marginTop: theme.spacing(1),
    },
    title: {
      lineHeight: 2,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "85%",
    },
    body: {},
  })
);
