import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useActionStyles = makeStyles<Theme>((theme) =>
  createStyles({
    button: {
      color: theme.palette.primary.contrastText,
    },
    disabled: {
      color: `#ffffffaa !important`,
    },
    status: {
      marginRight: theme.spacing(2),
    },
  })
);
