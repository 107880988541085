const DATA_URL_PREFIX_REGEX = /data:[^/]+\/[^;]+;base64,/;
const BOM_REGEX = /^77u\//;

export interface ReadBase64FileOptions {
  /**
   * By default, the data url prefix is removed. Set this to `true` to keep it.
   */
  preservePrefix?: boolean;

  /**
   * Set to `true` to remove the BOM (byte order mark). This is useful
   * when the file needs to be parsed as CSV as having a BOM causes errors
   * with the parsing when using Papaparse.
   */
  removeBOM?: boolean;
}

export class Base64FileUtils {
  /**
   * Read a file and return the result as a base64 encoded string.
   *
   * @param file The file to read.
   * @param options Read file options.
   */
  static async readFileAsBase64(file: File, options?: ReadBase64FileOptions) {
    const { preservePrefix, removeBOM } = options ?? {};
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
      reader.onload = () => {
        let result = reader.result?.toString() ?? "";
        if (!preservePrefix) result = result.replace(DATA_URL_PREFIX_REGEX, "");
        if (removeBOM) result = result.replace(BOM_REGEX, "");
        resolve(result);
      };
    });
  }

  /**
   * Split a base64 encoded string into chunks.
   *
   * @param data The base64 encoded string to split into chunks.
   * @param chunkSize The maximum size of each chunk.
   */
  static chunkFile(data: string, chunkSize: number) {
    const length = Math.ceil(data.length / chunkSize);
    const chunks: string[] = [];
    for (let i = 0; i < length; i++) {
      const start = i * chunkSize;
      chunks.push(data.slice(start, start + chunkSize));
    }
    return chunks;
  }

  /**
   * Compose chunked base64 encoded strings into a downloadable data url.
   *
   * @param type The file type.
   * @param parts The list of base64 encoded file parts.
   */
  static composeAsBase64DataUrl(type: string, parts: string[]) {
    return `data:${type};base64,${parts.join("")}`;
  }
}
