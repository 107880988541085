import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const TOOLBAR_WIDTH_DESKTOP = 60;
export const TOOLBAR_WIDTH_MOBILE = 50;

export default makeStyles<Theme>((theme) =>
  createStyles({
    toolbar: {
      backgroundColor: "#f1f1f1",
      borderLeft: `1px solid ${theme.palette.divider}`,
      flexShrink: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      width: TOOLBAR_WIDTH_DESKTOP,
      [theme.breakpoints.down("sm")]: {
        width: TOOLBAR_WIDTH_MOBILE,
      },
    },
    divider: {
      width: "100%",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);
