import { useCallback, useEffect, useState, useRef } from "react";
import { useRecoilState } from "recoil";
import {
  Editor,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  convertToRaw,
  convertFromRaw,
  RawDraftContentState,
} from "draft-js";
import "draft-js/dist/Draft.css";

import { DraftUtils, id } from "../../../utils";

import { DraftJsState } from "../../../state/DraftJsEditor";

import { useMenuAnchor } from "../../../hooks/state";

import { EditorToolbar } from "./tools/EditorToolbar";
import { EditorToolbarButtonLinkPopover } from "./tools/EditorToolbarButtonLinkPopover";
// import { decorator } from "./decorators";

import useStyles from "./styles";

export interface DraftJsEditorProps {
  /**
   * The initial content to load into the editor state.
   */
  initialContent?: RawDraftContentState;

  /**
   * Callback function to save the editor state to persistent storage.
   * WARNING: The provided function should be debounced.
   */
  onSaveContent?: (rawState: RawDraftContentState, plainText: string) => void;

  /**
   * If `true`, the editor will remove all styling.
   */
  plainText?: boolean;

  /**
   * If `true`, the editor will only allow text on a single line.
   */
  singleLine?: boolean;

  /**
   * Show the editor in read-only mode.
   */
  readOnly?: boolean;
}

const MAX_INDENT_DEPTH = 4;

/**
 * Renders a customised rich text editing area using `Draft.js`.
 */
export const DraftJsEditor = ({
  initialContent,
  onSaveContent,
  plainText,
  singleLine,
  readOnly,
}: DraftJsEditorProps) => {
  const classes = useStyles();
  const editorRef = useRef<Editor>(null);

  const [editorId] = useState(id());
  const [contentInitialised, setContentIntialised] = useState(false);
  const [editorState, _setEditorState] = useRecoilState(DraftJsState(editorId));

  const [linkAnchor, openLink, closeLink] = useMenuAnchor(
    undefined,
    editorRef.current?.focus
  );

  // Initialise content shown in the editor from the content state.
  useEffect(() => {
    if (contentInitialised) return;
    if (!initialContent) return;
    _setEditorState(
      // EditorState.createWithContent(convertFromRaw(initialContent), decorator)
      EditorState.createWithContent(convertFromRaw(initialContent))
    );
    setContentIntialised(true);
  }, [contentInitialised, initialContent, _setEditorState, setContentIntialised]);

  /**
   * This handler stores the given editor state in.
   */
  const handleSetEditorState = useCallback(
    (newEditorStateCandidate: EditorState) => {
      let newEditorState = newEditorStateCandidate;

      // If in plain text mode, collapse all blocks.
      if (singleLine)
        newEditorState = DraftUtils.collapseBlocks(newEditorStateCandidate);

      // Set editor state
      _setEditorState(newEditorState);

      // If there was a content change, persist changes
      if (
        onSaveContent &&
        DraftUtils.didContentChange(editorState, newEditorState)
      ) {
        const content = newEditorState.getCurrentContent();
        onSaveContent(convertToRaw(content), content.getPlainText());
      }
    },
    [singleLine, editorState, _setEditorState, onSaveContent]
  );

  /**
   * Handle indentation for lists using the TAB key.
   */
  const keyBindingFn = (e: React.KeyboardEvent) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const newState = RichUtils.onTab(e, editorState, MAX_INDENT_DEPTH);
      if (newState) {
        handleSetEditorState(newState);
        return "handled";
      }
      return "not-handled";
    }

    if (singleLine) {
      // If in single line mode, do not allow new lines
      if (e.key === "Enter") return "handled";
    }

    return getDefaultKeyBinding(e);
  };

  /**
   * Handle default key commands.
   */
  const handleKeyCommand = (command: string, editorState: EditorState) => {
    // Don't apply any rich styling commands in plain text mode
    if (plainText) return "not-handled";

    // Apply rich styling commands

    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      handleSetEditorState(newState);
      return "handled";
    }

    return "not-handled";
  };

  /**
   * Handle when an entity is requested to be modified.
   *
   * This is based on the current selection range. If there is an entity in
   * the current selection range, modify the entity. Otherwise, create a new
   * entity.
   *
   * @param e The click event that triggered the action.
   * @param entityType The entity type to modify.
   */
  const handleModifyEntity = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    entityType: string
  ) => {
    switch (entityType) {
      case "LINK":
        openLink(e);
    }
  };

  return (
    <>
      {!plainText && !singleLine && !readOnly && (
        <EditorToolbar
          contentKey={editorId}
          editorState={editorState}
          setEditorState={handleSetEditorState}
          modifyEntity={handleModifyEntity}
        />
      )}
      <div className={classes.editor}>
        <Editor
          ref={editorRef}
          editorState={editorState}
          stripPastedStyles={plainText}
          readOnly={readOnly}
          onChange={(state) => {
            // FIXME: Known issue
            // When link popover is shown and the user creates a link entity,
            // the editor calls the onChange handler and overwrites the change.
            // Skipping onChange when the link popover is shown overcomes this issue.
            if (linkAnchor) return;
            handleSetEditorState(state);
          }}
          keyBindingFn={keyBindingFn}
          handleKeyCommand={handleKeyCommand}
        />
      </div>

      <EditorToolbarButtonLinkPopover
        contentKey={editorId}
        anchor={linkAnchor}
        onClose={closeLink}
        setEditorState={handleSetEditorState}
      />
    </>
  );
};
