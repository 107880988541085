import { useDocumentData } from "../../hooks/firebase";
import { useLogError } from "../../hooks/log";

import {
  ReplyToSettings,
  ApplicationSettings,
  EmailSettings,
  NotificationSettings,
  FromSettings,
} from "../../../../types";

/**
 * This hook retrieves a settings document.
 *
 * @param settingName The name of the setting to retrieve.
 */
export const useSettings = <T>(
  settingName: string
): [T | undefined, boolean, Error | undefined] => {
  const [data, loading, error] = useDocumentData<T>(`settings/${settingName}`);
  useLogError(
    `Error while fetching ${settingName} settings.`,
    "hook-useSettings",
    error
  );
  return [data, loading, error];
};

/**
 * Retrieve application settings.
 */
export const useApplicationSettings = () =>
  useSettings<ApplicationSettings>("application");

/**
 * Retrieve email reply-to settings.
 */
export const useReplyToSettings = () => useSettings<ReplyToSettings>("replyTo");

/**
 * Retrieve email from settings.
 */
export const useFromSettings = () => useSettings<FromSettings>("from");

/**
 * Retrieve email settings.
 */
export const useEmailSettings = () => useSettings<EmailSettings>("email");

/**
 * Retrieve notification template settings.
 */
export const useNotificationSettings = () =>
  useSettings<NotificationSettings>("notifications");
