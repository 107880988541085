import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";

const TIMEZONE = process.env.TIMEZONE || "Australia/Melbourne";
const TIME_FORMAT = "YYYY-MM-DD HH:mm:ss.SSS";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

dayjs.tz.setDefault(TIMEZONE);

export class DateUtils {
  private _timezone: string;

  constructor(timezone: string) {
    this._timezone = timezone;
  }

  /**
   * The currently set timezone.
   */
  public get timezone() {
    return this._timezone;
  }

  /**
   * Formats a given date in the set timezone.
   *
   * @param date The date to format.
   * @param format The format string. Default value "yyyy-MM-dd HH:mm:ss.SSS".
   */
  public format(date: Date, format: string = TIME_FORMAT): string {
    return dayjs(date).tz(this.timezone).format(format);
  }

  /**
   * Return the relative time from the given date from now.
   *
   * @param date The date to show the relative time for.
   * @param suffix If `true`, returns the relative time without the suffix "ago"
   */
  public fromNow(date: Date, suffix?: boolean): string {
    return dayjs(date).fromNow(suffix);
  }
}

export const dateUtils = new DateUtils(TIMEZONE);
