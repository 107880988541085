import { useRecoilValue } from "recoil";

import { AuthenticationState, AuthLoadingState } from "./atoms";

/**
 * Get the current auth loading state.
 */
export const useAuthLoading = () => useRecoilValue(AuthLoadingState);

/**
 * Get the current user's profile. If there is no user
 * authenticated, this will return `null`.
 */
export const useCurrentUser = () => useRecoilValue(AuthenticationState);
