import { createContext } from "react";

export const FIREBASE_REGION = "australia-southeast1";

export interface FirebaseContextState {
  /**
   * `true` if Firebase has been initialised.
   */
  initialised: boolean;
}

export const INITIAL_STATE: FirebaseContextState = {
  initialised: false,
};

/**
 * Keeps track of whether Firebase has been initialised.
 */
export const FirebaseContext = createContext<FirebaseContextState>(INITIAL_STATE);
