import { useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { EmailUtils } from "../utils";

import { useCurrentUser } from "../state/FirebaseAuthState";

import { useEmail } from "../hooks/data/email";

/**
 * This hook redirects the user to the correct path when
 * viewing an email by checking its status.
 *
 * E.g. Viewing an email on the path `/emails/approved/:emailId`
 * when the given email is actually in "draft" mode will redirect
 * to `/emails/draft/:emailId`.
 */
export const useEnforceEmailPath = () => {
  const { emailId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const user = useCurrentUser();

  // Get the email's expected path
  const [email] = useEmail(emailId);
  const path = EmailUtils.getPath(email, user?.uid);

  // If the path is not correct, navigate to the right one.
  useEffect(() => {
    if (!path) return;
    if (!emailId) return;
    if (path.includes(emailId) && path !== pathname)
      navigate(path, { replace: true });
  }, [emailId, navigate, path, pathname]);
};
