import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    reviews: {
      flexShrink: 0,
    },
    children: {
      flexShrink: 0,
    },
    childrenText: {
      marginBottom: 0,
    },
    errors: {
      paddingTop: 0,
      flexShrink: 0,
    },
    list: {
      minHeight: "30vh",
      flexShrink: 0,
      marginBottom: theme.spacing(1),
    },
    item: {
      borderRadius: (theme.shape.borderRadius as number) * 2,
    },
    selectAll: {
      marginBottom: theme.spacing(1),
    },
    itemPadding: {
      "&:not(:last-child)": {
        marginBottom: theme.spacing(1),
      },
    },
    avatar: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    approved: {
      color: theme.palette.success.main,
    },
    rejected: {
      color: theme.palette.error.main,
    },
  })
);
