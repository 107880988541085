import { useLogError } from "../../../hooks/log";
import { useTemplate } from "../../../hooks/data/template";

import { useEmailContentById } from "../email";

import { EmailContent, EmailTemplate } from "../../../../../types";

/**
 * Retrieve the template of the email with the given id.
 *
 * Returns the `EmailContent` object from the email, and
 * the corresponding `EmailTemplate` object if it exists.
 *
 * @param emailId The id of the email to retrieve the template for.
 */
export const useEmailTemplate = (
  emailId?: string
): [
  { template?: EmailTemplate; content?: EmailContent; hasTemplate?: boolean },
  boolean,
  Error | undefined
] => {
  const [content, loading, error] = useEmailContentById(emailId, "template");

  useLogError(
    `Error while retrieving template content for <email|${emailId}>.`,
    "hook-useEmailTemplate",
    error
  );

  // Template id will be stored in content.text
  const templateId = content?.text;
  const [template, templateLoading, templateError] = useTemplate(templateId);

  return [
    { template, content, hasTemplate: Boolean(templateId) },
    loading || templateLoading,
    error ?? templateError,
  ];
};

/**
 * Retrieve the template string of the email with the given id.
 *
 * @param emailId The id of the email to retrieve the template for.
 */
export const useEmailTemplateString = (
  emailId?: string
): [string, boolean, Error | undefined] => {
  const [templateContent, loading, error] = useEmailTemplate(emailId);
  const { template, content } = templateContent ?? {};

  return [
    // Use the template string if available, otherwise fallback to html specified by template content.
    template?.versions.find((v) => v.active)?.htmlContent ?? content?.html ?? "",
    loading,
    error,
  ];
};
