import { EditorState, Modifier } from "draft-js";

import { DraftUtils } from "./DraftUtils";

export class DraftEntityUtils {
  /**
   * Remove an entity with the given key if it exists.
   *
   * @param editorState The current editor state.
   * @param entityKey The key of the entity to remove.
   */
  public static removeEntity(editorState: EditorState, entityKey: string) {
    const contentState = editorState.getCurrentContent();

    // Get the range of the current entity
    const selectionState = DraftUtils.getEntityRange(editorState, entityKey);

    // If a valid entity was found, remove the entity range
    if (selectionState) {
      const contentStateWithoutEntity = Modifier.applyEntity(
        contentState,
        selectionState,
        null
      );

      return EditorState.push(
        editorState,
        contentStateWithoutEntity,
        "apply-entity"
      );
    }
  }

  /**
   * Create a `LINK` entity over the currently selected range.
   *
   * @param editorState The current editor state.
   * @param currentEntityKey The key of the currently selected entity.
   * @param link
   */
  public static createLinkEntity(editorState: EditorState, link: string) {
    const contentState = editorState.getCurrentContent();

    const currentEntityKey = DraftUtils.getSelectionEntityKey(editorState);
    const contentStateWithEntity = currentEntityKey
      ? // Replace entity data if it exists
        contentState.replaceEntityData(currentEntityKey, {
          link,
        })
      : // Create entity if it doesn't exist
        contentState.createEntity("LINK", "MUTABLE", {
          link,
        });

    // Get the entity key
    const entityKey =
      currentEntityKey ?? contentStateWithEntity.getLastCreatedEntityKey();

    // Apply the entity to the editor state
    const contentStateWithAppliedEntity = Modifier.applyEntity(
      contentStateWithEntity,
      editorState.getSelection(),
      entityKey
    );

    return EditorState.push(
      editorState,
      contentStateWithAppliedEntity,
      "apply-entity"
    );
  }
}
