import { forwardRef, useEffect } from "react";
import { useRecoilState } from "recoil";

import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Badge from "@material-ui/core/Badge";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";

import FilterIcon from "@material-ui/icons/FilterList";

import {
  PersonalisationsEventFilterState,
  PersonalisationsStateType,
} from "../../../state/Personalisations";
import { useMenuAnchor } from "../../../hooks/state";

import { SendGridUtils, TextUtils } from "../../../utils";

// import useStyles from "./styles";

import { EmailRecipient } from "../../../../../types";

export interface PersonalisationEventFilterFieldProps {
  className?: string;

  /**
   * The key of the Recoil state the personalisation data to filter is stored in.
   */
  stateKey: PersonalisationsStateType;

  /**
   * Set this value to `true` to reset the value inside the filter field.
   */
  reset?: boolean;

  /**
   * if you want to disable the field, set the value to true
   */
  disabled?: boolean;

  /**
   * The function used to decide whether a recipient should be shown
   * based on the current filter value.
   */
  filterFn?: (recipient: EmailRecipient, filter: string) => boolean;
}

/**
 * Shows a filter menu for filtering the recipients to
 * show based on the events that they have.
 *
 * NOTE: does not change personalisation state. Use in
 * conjunction with a `PersonalisationFilterField`
 * component to update the personalisation list state.
 */
export const PersonalisationEventFilterField = forwardRef<
  any,
  PersonalisationEventFilterFieldProps
>((props, ref) => {
  // const classes = useStyles();

  const { className, stateKey, reset, disabled, ...forwardProps } = props;

  const [anchor, openMenu, closeMenu] = useMenuAnchor();
  const [selectedEvent, setSelectedEvent] = useRecoilState(
    PersonalisationsEventFilterState(stateKey)
  );

  // Reset filter when the reset flag is changed to true
  useEffect(() => {
    if (reset) setSelectedEvent("");
  }, [reset, setSelectedEvent]);

  return (
    // Forward ref required here so that we can render a tooltip over this component
    <>
      <IconButton
        {...forwardProps}
        ref={ref}
        className={className}
        disabled={disabled}
        onClick={openMenu}
      >
        <Badge
          color="primary"
          variant="dot"
          invisible={selectedEvent === ""}
          overlap="rectangular"
        >
          <FilterIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={closeMenu}
        MenuListProps={{ disablePadding: true }}
      >
        <ListSubheader>
          <b>FILTER BY STATUS</b>
        </ListSubheader>
        <MenuItem
          selected={!selectedEvent}
          onClick={() => {
            closeMenu();
            setSelectedEvent("");
          }}
        >
          <ListItemText primary="All" />
        </MenuItem>
        {["processed", "delivered", "bounce", "deferred", "dropped"].map((event) => (
          <MenuItem
            key={event}
            selected={selectedEvent === event}
            onClick={() => {
              closeMenu();
              setSelectedEvent(event);
            }}
          >
            <ListItemText
              primary={TextUtils.capitalise(SendGridUtils.mapEventToLabel(event))}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});
