export const APP_NAME = "MSend";
export const SYSTEM_IDENTIFIER = "SYSTEM";
export const LIST_MAX = 1000;
export const CSV_MIME = [
  "text/plain",
  "text/csv",
  "text/x-csv",
  "text/comma-separated-values",
  "text/x-comma-separated-values",
  "application/csv",
  "application/x-csv",
  "application/vnd.ms-excel",
];
export const VALID_EMAIL_STATES = [
  "view",
  "draft",
  "pending",
  "approved",
  "sent",
  "review",
];
