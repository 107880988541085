import firebase from "firebase/app";

import { FIREBASE_REGION } from "./context";

import {
  CallableFunctionId,
  HTTPFunctionResponse,
  FunctionResponse,
  FetchLogsFunctionOptions,
  FetchLogsFunctionResponse,
  ExportProgressFunctionResponse,
  FetchUserTemplatesFunctionResponse,
} from "../../../../../types";

export const getFunction = <T = never, R = FunctionResponse>(
  name: CallableFunctionId
) => {
  return firebase.app().functions(FIREBASE_REGION).httpsCallable(name) as (
    data?: T
  ) => Promise<HTTPFunctionResponse<R>>;
};

export const getFetchUserTemplates = () =>
  getFunction<{}, FetchUserTemplatesFunctionResponse>("emailTemplates-fetch");

/**
 * Get the cloud function to sync email templates.
 */
export const getSyncTemplates = () => getFunction("sendgrid-getTemplates");

/**
 * Get the cloud function to send an email.
 */
export const getSendEmail = () => getFunction<{ emailId: string }>("emails-send");

/**
 * Get the cloud function to check a back up's export progress.
 */
export const getExportProgress = () =>
  getFunction<
    { operationName: string },
    FunctionResponse<ExportProgressFunctionResponse>
  >("backup-exportProgress");

/**
 * Get the cloud function to fetch logs.
 */
export const getFetchLogs = () =>
  getFunction<FetchLogsFunctionOptions, FetchLogsFunctionResponse>("logs-fetch");
