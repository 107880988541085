import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    status: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    statusValue: {
      position: "absolute",
    },
    statusCaption: {
      textAlign: "center",
    },
    success: {
      color: theme.palette.success.main,
    },
    error: {
      color: theme.palette.error.main,
    },
  })
);
