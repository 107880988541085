import { useNavigate } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import WarningIcon from "@material-ui/icons/Warning";

import { useDocumentTitle } from "../../../state/DocumentTitle";

import PageMessage from "../../../components/core/PageMessage";

import useStyles from "./styles";

export interface ForbiddenPageProps {
  message?: string;
}

/**
 * Shows a forbidden message.
 */
export const ForbiddenPage = ({
  message = "Access denied. You do not have permission to view this page.",
}: ForbiddenPageProps) => {
  useDocumentTitle("Forbidden");

  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <PageMessage
        title="Forbidden"
        icon={WarningIcon}
        iconColor="error"
        action={
          <Button variant="outlined" onClick={() => navigate(-1)}>
            Back
          </Button>
        }
      >
        <Typography variant="body1">{message}</Typography>
      </PageMessage>
    </div>
  );
};
