import { useNavigate } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";

import { CLogger } from "../../../utils";

import { useSnack } from "../../../hooks/snack";
import { useBoolean } from "../../../hooks/state";
import { useDeleteEmail } from "../../../hooks/actions/email";

import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";

export interface EditorDeleteActionProps {
  /**
   * The id of the email to preview.
   */
  emailId?: string;

  /**
   * Disables the action button.
   */
  disabled?: boolean;
}

const log = CLogger.category("components/editorSidebarActions/EditorDeleteAction");

/**
 * This action deletes the current email.
 */
export const EditorDeleteAction = ({
  emailId,
  disabled,
}: EditorDeleteActionProps) => {
  const { pushClosableSnack, pushErrorSnack } = useSnack();
  const navigate = useNavigate();
  const deleteEmail = useDeleteEmail(emailId);

  const [open, onOpen, onClose] = useBoolean();

  /**
   * Handler for deleting the email.
   */
  const deleteHandler = async () => {
    // NOTE: emails can only be deleted by the owner.
    try {
      onClose();
      await deleteEmail();
      pushClosableSnack(`Email deleted.`);
      navigate("/emails/draft");
    } catch (error: any) {
      pushErrorSnack(`Failed to delete email. ${error.message}`);
      log({
        level: "error",
        message: `Error while deleting email: ${error.message}`,
        data: error,
      });
    }
  };

  return (
    <>
      <Tooltip title="Delete" placement="left">
        <div>
          <IconButton onClick={onOpen} disabled={disabled}>
            <DeleteIcon />
          </IconButton>
        </div>
      </Tooltip>
      <ConfirmDialog
        open={open}
        onClose={onClose}
        title="Delete email"
        confirmLabel="DELETE"
        onConfirm={deleteHandler}
        destructive
      >
        Are you sure you want to delete this email? This action is irreversible.
      </ConfirmDialog>
    </>
  );
};
