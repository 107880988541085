import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { Color } from "../../../constants";

export default makeStyles<Theme>((theme) =>
  createStyles({
    warning: {
      color: theme.palette.warning.main,
    },
    table: {
      marginBottom: theme.spacing(2),
    },
    caption: {
      marginBottom: theme.spacing(2),
    },
    avatar: {
      backgroundColor: Color.MONASH_GREY,
    },
    gutters: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    select: {
      userSelect: "all",
    },
  })
);
