import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Alert from "@material-ui/lab/Alert";

import SendIcon from "@material-ui/icons/Send";

import { CLogger } from "../../../utils";

import { useSnack } from "../../../hooks/snack";
import { useBoolean } from "../../../hooks/state";
import { getSendEmail } from "../../../contexts/firebase/FirebaseContext";

import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import LoadingText from "../../../components/core/LoadingText";

import { useActionStyles } from "../styles";

export interface EditorSendActionProps {
  /**
   * Disable this action.
   */
  disabled?: boolean;
}

const log = CLogger.category("components.editorToolbar.EditorSendAction");

/**
 * This action sends the current email.
 */
export const EditorSendAction = ({ disabled }: EditorSendActionProps) => {
  const classes = useActionStyles();

  const { emailId } = useParams();
  const { pushErrorSnack, pushClosableSnack } = useSnack();

  const [show, open, close] = useBoolean();
  const [sending, setSending] = useState(false);
  const [error, setError] = useState("");

  // Reset error when dialog optns
  useEffect(() => {
    if (show) setError("");
  }, [show]);

  /**
   * Request that the current email be sent via SendGrid.
   */
  const sendHandler = async () => {
    const sendEmail = getSendEmail();

    if (!emailId) return;
    if (!sendEmail) return;

    try {
      setSending(true);
      const { data } = await sendEmail({ emailId });
      if (data.status === "success") {
        close();
        setSending(false);
        pushClosableSnack(`Email processed for delivery.`);
        log({
          level: "info",
          message: data.message,
        });
      } else {
        setSending(false);
        setError(data.message);
        log({
          level: "error",
          message: data.message,
        });
      }
    } catch (error: any) {
      close();
      setSending(false);
      pushErrorSnack(`Error while sending email. ${error.message}`);
      log({
        level: "error",
        message: `Error while sending email. ${error.message}`,
        data: error,
      });
    }
  };

  return (
    <>
      <Tooltip title="Send email" placement="bottom">
        <div>
          <IconButton
            className={classes.button}
            classes={{ disabled: classes.disabled }}
            disabled={disabled}
            onClick={open}
          >
            <SendIcon />
          </IconButton>
        </div>
      </Tooltip>
      <ConfirmDialog
        open={show}
        onClose={close}
        title="Send email?"
        confirmLabel="SEND"
        onConfirm={sendHandler}
        loading={sending}
        disableConfirm={Boolean(error)}
      >
        {sending ? (
          <LoadingText type="linear">Preparing email...</LoadingText>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <>
            This will begin the process of preparing and delivering the email via
            SendGrid in batches. This could several minutes depending on how many
            recipients your email has.
          </>
        )}
      </ConfirmDialog>
    </>
  );
};
