import { useMemo, useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import ClearIcon from "@material-ui/icons/Clear";
import DownloadIcon from "@material-ui/icons/GetApp";
import FileIcon from "@material-ui/icons/InsertDriveFile";
import FolderIcon from "@material-ui/icons/Folder";
import ImageIcon from "@material-ui/icons/Image";
import PdfIcon from "@material-ui/icons/Description";

import { CLogger, TextUtils } from "../../../utils";

import { useSnack } from "../../../hooks/snack";

import Dropzone from "../../../components/core/Dropzone";

import useStyles from "./styles";

import { EmailAttachment } from "../../../../../types";

const log = CLogger.category("components.dialogs.AttachmentsDialog");

export interface AttachmentsDialogProps {
  /**
   * Whether the dialog is open.
   *
   * @default false
   */
  open?: boolean;

  /**
   * Callback function to close the dialog.
   */
  onClose?: () => void;

  /**
   * Show attachments as read-only.
   */
  readOnly?: boolean;

  /**
   * The attachments to show.
   */
  attachments?: EmailAttachment[];

  /**
   * Callback function for when user adds attachments.
   */
  onAddAttachments?: (attachments: File[]) => Promise<void>;

  /**
   * Callback function for when user removes an attachment.
   */
  onRemoveAttachment?: (attachmentId: string) => Promise<void>;

  /**
   * Callback function for when a user requests a download of an attachment.
   */
  onDownloadAttachment?: (attachmentId: string) => Promise<void>;
}

/**
 * Get the icon to show based on file type.
 *
 * @param type The file type.
 */
const getAttachmentIcon = (type: string) => {
  if (type.startsWith("image/")) return <ImageIcon />;
  switch (type) {
    case "application/pdf":
      return <PdfIcon />;
    case "application/zip":
      return <FolderIcon />;
    default:
      return <FileIcon />;
  }
};

/**
 * This dialog shows UI for the user to add/remove attachments.
 */
export function AttachmentsDialog(props: AttachmentsDialogProps) {
  const {
    open = false,
    onClose,
    readOnly,
    attachments,
    onAddAttachments,
    onRemoveAttachment,
    onDownloadAttachment,
  } = props;
  const classes = useStyles();

  const { pushErrorSnack } = useSnack();
  const [loading, setLoading] = useState(false);

  // Calculate total size of all attachments
  const totalSize = useMemo(
    () => attachments?.reduce((size, a) => size + a.size, 0),
    [attachments]
  );

  /**
   * Callback for when file drop is accepted.
   *
   * @param files The files that were dropped.
   */
  const onDropAccepted = async (files: File[]) => {
    if (!onAddAttachments) return;
    setLoading(true);
    try {
      await onAddAttachments?.(files);
    } catch (error: any) {
      pushErrorSnack(error.message);
      log({
        level: "error",
        message: `Error while saving attachment: ${error.message}`,
      });
    }
    setLoading(false);
  };

  /**
   * Handler for clearing all attachments.
   */
  const clearAttachments = async () => {
    if (!attachments) return;
    if (!onRemoveAttachment) return;
    setLoading(true);
    await Promise.all(
      attachments.map((attachment) => onRemoveAttachment(attachment.id))
    );
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Attachments</DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText>
          {readOnly
            ? "Attachments are sent to all recipients."
            : "Attachments will be sent to all recipients, and cannot exceed 10 MB."}
        </DialogContentText>
      </DialogContent>
      {!readOnly && (
        <Dropzone
          className={classes.dropzone}
          fileTypeName="attachment"
          disabled={loading}
          multiple={true}
          onDropAccepted={onDropAccepted}
          disableNonEmptyText
        >
          {loading && <LinearProgress />}
        </Dropzone>
      )}
      <List className={classes.list}>
        {attachments?.map((attachment) => (
          <ListItem
            key={attachment.id}
            disableGutters
            classes={{
              secondaryAction: classes.action,
            }}
          >
            <Tooltip title={attachment.type}>
              <ListItemIcon className={classes.icon}>
                {getAttachmentIcon(attachment.type)}
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              primary={attachment.name}
              secondary={TextUtils.dataSize(attachment.size)}
            />
            <ListItemSecondaryAction>
              {onDownloadAttachment && (
                <Tooltip title="Download attachment">
                  <IconButton
                    edge={readOnly ? "end" : undefined}
                    onClick={() => onDownloadAttachment(attachment.id)}
                  >
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              )}
              {!readOnly && onRemoveAttachment && (
                <Tooltip title="Remove attachment">
                  <IconButton
                    edge="end"
                    onClick={() => onRemoveAttachment(attachment.id)}
                  >
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        <ListItem disableGutters>
          <ListItemText
            secondary={
              totalSize
                ? `${TextUtils.pluralWithCount(
                    attachments?.length ?? 0,
                    "attachment"
                  )}, ${TextUtils.dataSize(totalSize)}`
                : "No attachments"
            }
          />
        </ListItem>
      </List>
      <DialogActions>
        {!readOnly && (
          <Button className={classes.clear} onClick={clearAttachments}>
            CLEAR ALL
          </Button>
        )}
        <Button onClick={onClose}>CLOSE</Button>
      </DialogActions>
    </Dialog>
  );
}
