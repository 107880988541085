import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  responsiveFontSizes,
  ThemeOptions,
} from "@material-ui/core";

import { Color } from ".";

const themeConfig: ThemeOptions = {
  palette: {
    primary: {
      main: Color.MONASH_BLUE,
    },
    secondary: {
      main: Color.MONASH_GREY,
    },
    error: {
      main: Color.ERROR,
    },
    background: {
      default: Color.BACKGROUND,
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "0.8rem",
      },
    },
  },
};

export const theme = responsiveFontSizes(createMuiTheme(themeConfig));
