import clsx from "clsx";

import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

import { APP_NAME } from "../../../constants";

import useStyles from "./styles";

export interface LoadingProps {
  /**
   * The loading title.
   */
  title?: string;

  /**
   * The loading message.
   */
  message?: string;

  /**
   * Set this to `true` to make the loader fill up a full page.
   */
  full?: boolean;
}

/**
 * Shows a loading indicator.
 */
export const Loading = ({
  title = APP_NAME,
  message = "Loading...",
  full,
}: LoadingProps) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.loadingWrapper, {
        [classes.full]: full,
      })}
    >
      <div className={classes.logoContainer}>
        <img
          className={classes.logo}
          src="/images/monashlogo.png"
          alt="monash university"
        />
        <LinearProgress className={classes.progress} />
      </div>
      <div className={classes.text}>
        <Typography variant="body1" color="textSecondary" className={classes.title}>
          <b>{title}</b>
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {message}
        </Typography>
      </div>
    </div>
  );
};
