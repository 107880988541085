export class TokenUtils {
  /**
   * Build a regex that can be used to match for the HTML representation of a token.
   *
   * Default regex:
   * ```
   * /<span(?:[^{>]+)data-mention="{{([^}]+)}}">{{[^}]+}}<\/span>/g
   * ```
   *
   * @param options.attribute The data attribute name. Defaults to "data-mention".
   * @param options.element The element type of a token. Defaults to "span".
   */
  static buildRegex(
    options: {
      attribute?: string;
      element?: string;
    } = {}
  ) {
    const { attribute = "data-mention", element = "span" } = options;
    return new RegExp(
      `<${element}[^{>]+${attribute}="{{([^}]+)}}">{{[^}]+}}<\\/${element}>`,
      "g"
    );
  }

  /**
   * Use the provided regex to extract token elements from the given HTML.
   *
   * The regex should match the token element and have 1 capture group which
   * extracts the name of the token.
   *
   * Returns a mapping of token names to the token HTML element (as a string).
   *
   * @param regex The regex to use.
   * @param html The HTML content to extract tokens from.
   */
  static extractTokens(regex: RegExp, html: string) {
    const tokenMap: { [tokenName: string]: string } = {};

    let result: RegExpExecArray | null = null;
    while ((result = regex.exec(html))) {
      const [match, token] = result;
      tokenMap[token] = match;
    }

    return tokenMap;
  }

  /**
   * Create a function that can be used to create a token that has no HTML markup.
   *
   * @param options.start The start delimeter for the token.
   * @param options.end The end delimeter for the token.
   */
  static createPlainTokenFactory(
    options: {
      start?: string;
      end?: string;
    } = {}
  ) {
    const { start = "{{", end = "}}" } = options;
    return (token: string) => `${start}${token}${end}`;
  }

  /**
   * Create a function that can be used to create a token with the appropriate HTML
   * markup for it to be styled and recognised as a token.
   *
   * @param options.element The element to use to wrap the token. Default is "span".
   * @param options.className The class to apply to the token. Default is "mention".
   * @param options.attributeName The attribute name to use on the token. Default is "data-mention".
   */
  static createTokenFactory(
    options: {
      element?: string;
      className?: string;
      attributeName?: string;
      plainTokenFactory?: (token: string) => string;
    } = {}
  ) {
    const {
      element = "span",
      className = "mention",
      attributeName = "data-mention",
      plainTokenFactory = TokenUtils.createPlainTokenFactory(),
    } = options;
    return (token: string) => {
      const plainToken = plainTokenFactory(token);
      return `<${element} class="${className}" ${attributeName}="${plainToken}">${plainToken}</${element}>`;
    };
  }

  /**
   * Remove all token markup from the given HTML.
   *
   * E.g. With the following mapping:
   * ```
   * { position: '<span class="token" data-token="{{position}}">{{position}}</span>' }
   * ```
   *
   * We can remove all "position" tokens that have the matching HTML markup
   * and replace it with just `{{position}}`.
   *
   * @param tokensToRemove A mapping of token name to token markup that should be removed.
   * @param html The html to remove markup from.
   * @param plainTokenFactory A function that is used to create a plain token from a token name. By default, `TokenUtils.createPlainTokenFactory()` is used.
   */
  static removeTokenMarkup(
    tokensToRemove: { [tokenName: string]: string },
    html: string,
    plainTokenFactory: (
      token: string
    ) => string = TokenUtils.createPlainTokenFactory()
  ) {
    let updatedHtml = html;
    for (const tokenName of Object.keys(tokensToRemove)) {
      updatedHtml = updatedHtml.replace(
        new RegExp(tokensToRemove[tokenName], "g"),
        plainTokenFactory(tokenName)
      );
    }
    return updatedHtml;
  }

  /**
   * Add HTML markup for the given tokens to the HTML.
   *
   * @param tokensToInject A list of tokens to inject into the HTML.
   * @param html The html to add markup to.
   * @param options.element The element to use to wrap the token. Default is "span".
   * @param options.className The class to apply to the token. Default is "token".
   * @param options.attributeName The attribute name to use on the token. Default is "data-token".
   * @param options.plainTokenFactory A function that is used to create a plain token from a token name. By default, `TokenUtils.createPlainTokenFactory()` is used.
   * @param options.tokenFactory A function that is used to create the HTML markup for a given token.  By default, `TokenUtils.createTokenFactory()` is used.
   */
  static injectTokenMarkup(
    tokensToInject: string[],
    html: string,
    options: {
      element?: string;
      className?: string;
      attributeName?: string;
      plainTokenFactory?: (token: string) => string;
      tokenFactory?: (token: string) => string;
    } = {}
  ) {
    const {
      plainTokenFactory = TokenUtils.createPlainTokenFactory(),
      tokenFactory = TokenUtils.createTokenFactory(options),
    } = options;

    let updatedHtml = html;
    for (const token of tokensToInject) {
      updatedHtml = updatedHtml.replace(
        new RegExp(plainTokenFactory(token), "g"),
        tokenFactory(token)
      );
    }
    return updatedHtml;
  }
}
