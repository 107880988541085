import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { theme } from "../../../constants";

export interface ThemeProps {
  children?: React.ReactNode;
}

/**
 * Provides the default MUI theme.
 */
export const Theme = ({ children }: ThemeProps) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
