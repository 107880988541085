import { selectorFamily } from "recoil";
import { EditorState, EntityInstance } from "draft-js";

import { DraftJsEditorState } from "./atoms";

import { DraftUtils } from "../../utils";

/**
 * Select the current rich text editor state.
 */
export const DraftJsState = selectorFamily<EditorState, string>({
  key: "DraftJsState",
  get:
    (key) =>
    ({ get }) =>
      get(DraftJsEditorState(key)),
  set:
    (key) =>
    ({ set }, state) => {
      set(DraftJsEditorState(key), state);
    },
});

/**
 * Select the current entity key from the editor state.
 */
export const DraftJsCurrentEntityKey = selectorFamily<string | undefined, string>({
  key: "DraftJsCurrentEntityKey",
  get:
    (key) =>
    ({ get }) => {
      const editorState = get(DraftJsEditorState(key));
      const entityKey = DraftUtils.getSelectionEntityKey(editorState);
      return entityKey;
    },
});

/**
 * Select the current entity from the editor state.
 */
export const DraftJsCurrentEntity = selectorFamily<
  EntityInstance | undefined,
  string
>({
  key: "DraftJsCurrenEntity",
  get:
    (key) =>
    ({ get }) => {
      const editorState = get(DraftJsEditorState(key));
      const entityKey = get(DraftJsCurrentEntityKey(key));
      if (entityKey) return editorState.getCurrentContent().getEntity(entityKey);
    },
});
