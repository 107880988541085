import { makeStyles, createStyles, Theme, lighten } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    dropzone: {
      "transition": theme.transitions.create(["border-color", "background-color"], {
        duration: "200ms",
      }),
      "borderStyle": "dashed",
      "borderWidth": "2px",
      "borderRadius": theme.shape.borderRadius,
      "&:hover": {
        cursor: "pointer",
      },
    },
    empty: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "10rem",
      padding: theme.spacing(1),
    },
    enabled: {
      "borderColor": lighten(theme.palette.primary.light, 0.5),
      "&:hover": {
        borderColor: theme.palette.primary.light,
        backgroundColor: lighten(theme.palette.primary.light, 0.95),
      },
      "&:active": {
        backgroundColor: lighten(theme.palette.primary.light, 0.87),
      },
    },
    dragActive: {
      borderColor: theme.palette.primary.light,
      backgroundColor: lighten(theme.palette.primary.light, 0.9),
    },
    dragReject: {
      borderColor: "#aaa",
      backgroundColor: lighten("#aaa", 0.9),
    },
  })
);
