import { useEffect, useMemo, useState } from "react";

import {
  getFetchUserTemplates,
  useFirestore,
} from "../../contexts/firebase/FirebaseContext";
import { useCurrentUser } from "../../state/FirebaseAuthState";
import { useCollectionData, useDocumentData } from "../../hooks/firebase";
import { useLogError } from "../../hooks/log";
import { ColRef } from "../../utils";

import { EmailTemplate, TransferredEmailTemplate } from "../../../../types";

function groupTemplates<T extends EmailTemplate | TransferredEmailTemplate>(
  templates?: T[]
) {
  const groupedTemplates: { [group: string]: T[] } = {};
  if (!templates) return {};
  for (const template of templates) {
    if (!groupedTemplates[template.group]) groupedTemplates[template.group] = [];
    groupedTemplates[template.group].push(template);
  }
  return groupedTemplates;
}

/**
 * Retrieve the templates available to the current user.
 */
export const useTemplates = () => {
  const user = useCurrentUser();
  const firestore = useFirestore();

  const [templates, setTemplates] = useState<TransferredEmailTemplate[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (!user?.groups) return;

    // If the user has less than 10 groups, query directly from Firestore
    // (since this is much faster)
    if (user.groups.length <= 10 && firestore) {
      setLoading(true);
      const ref = firestore.collection("templates") as ColRef<EmailTemplate>;
      const query = ref.where("group", "in", user.groups ?? []);
      query
        .get()
        .then((response) =>
          setTemplates(
            response.docs.map((doc) => {
              const data = doc.data();
              return {
                ...data,
                updatedAt: data.updatedAt.toDate().getTime(),
                syncedAt: data.syncedAt.toDate().getTime(),
              };
            })
          )
        )
        .catch(setError)
        .finally(() => setLoading(false));
    }
    // If the user is in more than 10 groups, we use a cloud function
    // to fetch templates due to Firestore compound query limitation
    // See: https://firebase.google.com/docs/firestore/query-data/queries#limitations_2
    else {
      const fetchTemplates = getFetchUserTemplates();
      if (!fetchTemplates) return;
      setLoading(true);
      fetchTemplates()
        .then((response) => setTemplates(response.data.templates))
        .catch(setError)
        .finally(() => setLoading(false));
    }
  }, [user, firestore]);

  useLogError(`Error while fetching user templates.`, "hook-useTemplates", error);

  return [templates, loading, error] as const;
};

/**
 * Retrieve the templates available to the current user by group.
 */
export const useTemplatesGrouped = () => {
  const [templates, loading, error] = useTemplates();
  const groupedTemplates = useMemo(() => groupTemplates(templates), [templates]);
  return [groupedTemplates, templates, loading, error] as const;
};

/**
 * Retrieve the email template with the given id.
 *
 * @param templateId The id of the template to retrieve.
 */
export const useTemplate = (templateId?: string) => {
  const [template, loading, error] = useDocumentData<EmailTemplate>(
    templateId && `templates/${templateId}`
  );
  useLogError(
    `Error while retrieving <template|${templateId}>.`,
    "hook-useTemplate",
    error
  );
  return [template, loading, error] as const;
};

/**
 * Retrieve all templates.
 */
export const useAllTemplates = () => {
  const [templates, loading, error] = useCollectionData<EmailTemplate>("templates");
  useLogError(`Error while retrieving templates.`, "hook-useAllTemplates", error);
  return [templates, loading, error] as const;
};

/**
 * Retrieve all templates and return them by group.
 */
export const useAllTemplatesGrouped = () => {
  const [templates, loading, error] = useAllTemplates();
  const groupedTemplates = useMemo(() => groupTemplates(templates), [templates]);
  return [groupedTemplates, templates, loading, error] as const;
};
