import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    select: {
      "paddingLeft": theme.spacing(1),
      "marginLeft": theme.spacing(-1),
      "&:focus": {
        borderRadius: theme.shape.borderRadius,
      },
    },
    hidden: {
      display: "none",
    },
  })
);
