import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    login: {
      backgroundColor: "#eee",
      display: "flex",
      flexDirection: "row",
      height: "100vh",
      justifyContent: "center",
    },
    paper: {
      padding: theme.spacing(4),
      maxWidth: 500,
      alignSelf: "center",
      width: "100%",
      marginBottom: "10vh",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    logo: {
      width: 200,
      marginLeft: -16,
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    subtitle: {
      marginTop: theme.spacing(2),
    },
    graphic: {
      backgroundImage: "linear-gradient(to top right, #006DAE, #009dfb)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    image: {
      width: "60%",
      minWidth: 280,
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    alert: {
      marginBottom: theme.spacing(1),
    },
    mock: {
      marginTop: theme.spacing(1),
    },
  })
);
