import { memo } from "react";
import { useRecoilValue } from "recoil";
import { FixedSizeList, ListChildComponentProps, areEqual } from "react-window";
import Autosizer from "react-virtualized-auto-sizer";

import ListItemText from "@material-ui/core/ListItemText";

import {
  PersonalisationsState,
  PersonalisationsStateType,
} from "../../../state/Personalisations";

import { useBooleanWithState } from "../../../hooks/state";

import RecipientStatusDialog from "../../../components/dialogs/RecipientStatusDialog";
import RecipientStatusListItem from "../../../components/status/RecipientStatusListItem";

// import useStyles from "./styles";

export interface RecipientStatusListProps {
  /**
   * The id of the email the recipients are in.
   */
  emailId?: string;

  /**
   * The key in which the recipient list is stored in Recoil state.
   */
  stateKey: PersonalisationsStateType;
}

/**
 * Show the status of the list of recipients.
 */
export function RecipientStatusList(props: RecipientStatusListProps) {
  const { stateKey, emailId } = props;
  // const classes = useStyles();

  const recipients = useRecoilValue(PersonalisationsState(stateKey));
  const [selectedRecipient, showDialog, openDialog, closeDialog] =
    useBooleanWithState<string>();

  return (
    <>
      {recipients.length === 0 && <ListItemText secondary="No recipients" />}
      <Autosizer>
        {({ width, height }) => (
          <FixedSizeList
            width={width}
            height={height}
            itemSize={72 + 8 + 8}
            itemCount={recipients.length ?? 0}
          >
            {memo(({ index, style }: ListChildComponentProps) => {
              const recipient = recipients[index];
              return (
                <RecipientStatusListItem
                  key={recipient?.id}
                  recipient={recipient}
                  onClick={openDialog}
                  style={style}
                />
              );
            }, areEqual)}
          </FixedSizeList>
        )}
      </Autosizer>
      <RecipientStatusDialog
        open={showDialog}
        onClose={closeDialog}
        emailId={emailId}
        recipientId={selectedRecipient}
      />
    </>
  );
}
