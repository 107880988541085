import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import PreviewIcon from "@material-ui/icons/Pageview";

import {
  PersonalisationsState,
  PersonalisationsStateType,
} from "../../../state/Personalisations";

import { useBoolean } from "../../../hooks/state";
import { useEmailContents, useEmailTemplateString } from "../../../hooks/data/email";

import { ColRef } from "../../../utils";

import { useFirestore } from "../../../contexts/firebase/FirebaseContext";

import TemplatePreviewDialog from "../../../components/dialogs/TemplatePreviewDialog";

import { EmailRecipient } from "../../../../../types";

export interface EditorPreviewActionProps {
  /**
   * The id of the email to preview.
   */
  emailId?: string;

  /**
   * Disables the action button.
   */
  disabled?: boolean;
}

/**
 * This action shows a dialog with a preview of the current email.
 */
export const EditorPreviewAction = ({
  emailId,
  disabled,
}: EditorPreviewActionProps) => {
  const store = useFirestore();
  const [contents] = useEmailContents(!disabled ? emailId : "");
  const [template] = useEmailTemplateString(!disabled ? emailId : "");

  const setPersonalisations = useSetRecoilState(
    PersonalisationsState(PersonalisationsStateType.RECIPIENTS)
  );

  const [open, onOpen, onClose] = useBoolean();
  const [shouldFetch, setShouldFetch] = useState(true);
  const [loading, setLoading] = useState(false);

  // Fetch recipients
  useEffect(() => {
    if (!open) return;
    if (!store) return;
    if (!shouldFetch) return;
    if (!emailId) return;

    setShouldFetch(false);
    setLoading(true);

    const ref = store.collection(
      `emails/${emailId}/recipients`
    ) as ColRef<EmailRecipient>;

    ref.get().then((snap) => {
      const tokens: Set<string> = new Set();
      const personalisations = snap.docs.map((doc) => {
        const data = doc.data();
        Object.keys(data.personalisations).forEach((token) => tokens.add(token));
        return data;
      });
      setPersonalisations(personalisations);
      setLoading(false);
    });
  }, [store, open, shouldFetch, emailId, setPersonalisations]);

  // If the email id changes, reset
  useEffect(() => {
    if (emailId && !open) setShouldFetch(true);
  }, [emailId, open]);

  return (
    <>
      <Tooltip title="Preview" placement="left">
        <div>
          <IconButton onClick={onOpen} disabled={disabled}>
            <PreviewIcon />
          </IconButton>
        </div>
      </Tooltip>
      <TemplatePreviewDialog
        open={open}
        onClose={onClose}
        template={template}
        contents={contents}
        loading={loading}
        showRecipientPreview
      />
    </>
  );
};
