import { useMemo } from "react";

import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";

import ProgressDisplay from "../../../components/status/ProgressDisplay";

import {
  Email,
  EmailRecipient,
  EmailTask,
  SendEmailsTaskData,
} from "../../../../../types";

import useStyles from "./styles";

export interface EmailDeliveryStatusPanelProps {
  loading?: boolean;
  email?: Email;
  tasks?: EmailTask[];
  recipients?: EmailRecipient[];
}

function calculateEventCountAndPercentage(
  recipients: EmailRecipient[],
  event: string,
  notEvents?: string[]
): [number, number] {
  if (recipients.length === 0) return [0, 0];
  const count = recipients.reduce((total, { events }) => {
    if (events?.includes(event)) {
      if (notEvents && events?.some((event) => notEvents.includes(event)))
        return total;
      else return total + 1;
    } else return total;
  }, 0);
  return [count, (count / recipients.length) * 100];
}

/**
 * Shows the delivery status for an email's recipients.
 */
export function EmailDeliveryStatusPanel(props: EmailDeliveryStatusPanelProps) {
  const { loading, email, tasks, recipients = [] } = props;
  const classes = useStyles();

  // Get the number of send-emails tasks
  const sendTasks = useMemo(
    () =>
      (tasks?.filter(
        (t) => t.type === "send-emails"
      ) as EmailTask<SendEmailsTaskData>[]) ?? [],
    [tasks]
  );

  // Calculate the progress of emails that have been delivered TO SendGrid
  const sendProgress = useMemo(() => {
    if (sendTasks.length === 0) return 0;
    const count = sendTasks.reduce((total, task) => total + (task.progress ?? 0), 0);
    return (count / sendTasks.length) * 100;
  }, [sendTasks]);

  // Calculate the progress of emails that have been delivered BY SendGrid
  const [deliveredCount, deliveredProgress] = useMemo(
    () => calculateEventCountAndPercentage(recipients, "delivered"),
    [recipients]
  );

  const [bouncedCount, bouncedProgress] = useMemo(
    () => calculateEventCountAndPercentage(recipients, "bounce"),
    [recipients]
  );

  const [deferredCount, deferredProgress] = useMemo(
    () =>
      calculateEventCountAndPercentage(recipients, "deferred", [
        "delivered",
        "bounce",
        "dropped",
      ]),
    [recipients]
  );

  const [droppedCount, droppedProgress] = useMemo(
    () => calculateEventCountAndPercentage(recipients, "dropped"),
    [recipients]
  );

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Fade in={loading}>
          <LinearProgress className={classes.loading} />
        </Fade>
        <Typography variant="h6" component="div">
          Delivery status
        </Typography>
        {email?.status !== "sent" ? (
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.empty}
          >
            Email not delievered yet
          </Typography>
        ) : (
          <Grid container spacing={1} className={classes.statusContainer}>
            <Grid item xs={6} sm={3}>
              <ProgressDisplay
                progress={sendProgress}
                description="Sent to SendGrid"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <ProgressDisplay
                progress={deliveredProgress}
                tooltip={`${deliveredCount} / ${recipients.length}`}
                description="Received"
              />
            </Grid>
            {deferredProgress > 0 && (
              <>
                <Grid item xs={6} sm={3}>
                  <ProgressDisplay
                    progress={deferredProgress}
                    tooltip={`${deferredCount} / ${recipients.length}`}
                    description="Deferred"
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ProgressDisplay
                    error
                    progress={bouncedProgress + droppedProgress}
                    tooltip={`${bouncedCount + droppedCount} / ${recipients.length}`}
                    description="Bounced / Dropped"
                  />
                </Grid>
              </>
            )}
            {deferredProgress === 0 && (
              <>
                <Grid item xs={6} sm={3}>
                  <ProgressDisplay
                    error
                    progress={bouncedProgress}
                    tooltip={`${bouncedCount} / ${recipients.length}`}
                    description="Bounced"
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ProgressDisplay
                    error
                    progress={droppedProgress}
                    tooltip={`${droppedCount} / ${recipients.length}`}
                    description="Dropped"
                  />
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Paper>
    </Grid>
  );
}
