import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    footer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    text: {
      color: "#bababa",
    },
    spacer: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    link: {
      "textDecoration": "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    links: {
      display: "flex",
      marginBottom: theme.spacing(1),
    },
  })
);
