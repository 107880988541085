import { useCollectionData } from "../../../hooks/firebase";
import { useLogError } from "../../../hooks/log";

/**
 * Retrieve a given email's sub-collection data.
 *
 * @param emailId The id of the email to get data for.
 * @param collection The name of the collection the data is under.
 */
export const useEmailData = <T>(
  emailId: string | undefined,
  collection: string
): [T[] | undefined, boolean, Error | undefined] => {
  const [data, loading, error] = useCollectionData<T>(
    emailId && `emails/${emailId}/${collection}`
  );
  useLogError(
    `Error while retrieving ${collection} for <email|${emailId}>.`,
    "hook-useEmailData",
    error
  );
  return [data, loading, error];
};
