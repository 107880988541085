import { useNavigate } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import NotFoundIcon from "@material-ui/icons/Help";

import { useDocumentTitle } from "../../../state/DocumentTitle";

import PageMessage from "../../../components/core/PageMessage";

import useStyles from "./styles";

export interface NotFoundPageProps {
  message?: string;
}

/**
 * Shows a not found message.
 */
export const NotFoundPage = ({
  message = "The page you were looking for could not be found.",
}: NotFoundPageProps) => {
  useDocumentTitle("Not Found");

  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <PageMessage
        title="Not Found"
        icon={NotFoundIcon}
        action={
          <Button variant="outlined" onClick={() => navigate(-1)}>
            Back
          </Button>
        }
      >
        <Typography variant="body1">{message}</Typography>
      </PageMessage>
    </div>
  );
};
