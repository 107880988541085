import { useDocumentData, useQuery, useQueryData } from "../../firebase";
import { useLogError } from "../../log";

import { EmailRecipient, WebhookEvent } from "../../../../../types";

/**
 * Get a recipient's details from an email.
 *
 * @param emailId The id of the email the recipient belongs to.
 * @param recipientId The id of the recipient to get.
 */
export const useEmailRecipient = (
  emailId?: string,
  recipientId?: string
): [EmailRecipient | undefined, boolean, Error | undefined] => {
  const [recipient, loading, error] = useDocumentData<EmailRecipient>(
    emailId && recipientId ? `emails/${emailId}/recipients/${recipientId}` : null
  );

  useLogError(
    `Error while retrieving recipient for <email|${emailId}>.`,
    "hook-useEmailRecipient",
    error
  );

  return [recipient, loading, error];
};

/**
 * Get all SendGrid webhook events for the specified recipient.
 *
 * @param emailId The id of the email the recipient belongs to.
 * @param recipientId The id of the recipient to get data for.
 */
export const useEmailRecipientEvents = (
  emailId?: string,
  recipientId?: string
): [WebhookEvent[] | undefined, boolean, Error | undefined] => {
  const [events, loading, error] = useQueryData<WebhookEvent>(
    emailId && recipientId && `emails/${emailId}/events`,
    [
      { op: "where", args: ["recipientId", "==", recipientId] },
      { op: "orderBy", args: ["timestamp"] },
    ]
  );

  useLogError(
    `Error while retrieving events for <emailRecipient|${recipientId}> from <email|${emailId}>.`,
    "hook-useEmailRecipientEvents",
    error
  );

  return [events, loading, error];
};

/**
 * Check if the given email has at least one recipient.
 *
 * @param emailId The id of the email to check for recipients.
 */
export const useEmailHasRecipients = (emailId?: string): [boolean, boolean] => {
  const [snapshot, loading, error] = useQuery(
    emailId && `emails/${emailId}/recipients`,
    [{ op: "limit", args: [1] }] // Only need to fetch at most one
  );
  useLogError(
    `Error while checking if email has recipients.`,
    `hook-useEmailHasRecipients`,
    error
  );
  return [!snapshot?.empty, loading];
};
