import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(2),
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    selectPaper: {
      maxHeight: 48 * 8.5 + 8,
      width: 400,
    },
    cancel: {
      marginRight: "auto",
    },
  })
);
