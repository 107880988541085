import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import EditorIcon from "@material-ui/icons/Edit";

import { useBoolean } from "../../../hooks/state";
import { useEmailEditorsAsUsers } from "../../../hooks/data/email";

import EditorSelectDialog from "../../../components/dialogs/EditorSelectDialog";

export interface EditorViewEditorsActionProps {
  /**
   * The id of the email to preview.
   */
  emailId?: string;

  /**
   * Disables the action button.
   */
  disabled?: boolean;
}

/**
 * This action shows a list of the current editors.
 */
export const EditorViewEditorsAction = ({
  emailId,
  disabled,
}: EditorViewEditorsActionProps) => {
  const [editors] = useEmailEditorsAsUsers(emailId);

  const [open, onOpen, onClose] = useBoolean();

  return (
    <>
      <Tooltip title="Editors" placement="left">
        <div>
          <IconButton onClick={onOpen} disabled={disabled}>
            <EditorIcon />
          </IconButton>
        </div>
      </Tooltip>
      <EditorSelectDialog
        open={open}
        onClose={onClose}
        readOnly
        title="Editors"
        emptyMessage="No editors"
        listTitle=""
        selected={editors}
      >
        Users who have access to edit this email.
      </EditorSelectDialog>
    </>
  );
};
