import { atom, atomFamily } from "recoil";

import { Email, EmailStatus } from "../../../../types";

/**
 * This atom is used to keep track the emails the current
 * user has access to edit.
 */
export const EditableEmailsState = atomFamily<
  [Email[] | undefined, boolean, Error | undefined],
  EmailStatus
>({
  key: "EditableEmailsState",
  default: [undefined, false, undefined],
});

/**
 * This atom is used to keep track the emails the current
 * user has access to review.
 */
export const ReviewableEmailsState = atom<
  [Email[] | undefined, boolean, Error | undefined]
>({
  key: "ReviewableEmailsState",
  default: [undefined, false, undefined],
});
