import { Link } from "react-router-dom";
import clsx from "clsx";

import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";

import { useLinkStyles } from "./styles";

export interface NavigationDrawerLinkProps {
  /**
   * The icon to use for the link.
   */
  icon?: React.ReactNode;

  /**
   * The URL to link to.
   */
  to?: string;

  /**
   * Indicate that the link is active.
   */
  selected?: boolean;

  /**
   * The label under the link.
   */
  label?: string;

  /**
   * Pass a positive number to render a badge to indicate that there are items here.
   */
  amount?: number;

  /**
   * The variant of the badge color to use.
   *
   * @default "primary"
   */
  badgeVariant?: "primary" | "error";
}

/**
 * Renders a icon and text as a link on the sidebar.
 */
export const NavigationDrawerLink = ({
  icon,
  to = "#",
  selected,
  label = "Link",
  amount = 0,
  badgeVariant = "primary",
}: NavigationDrawerLinkProps) => {
  const classes = useLinkStyles();
  return (
    <Tooltip title={label} placement="right">
      <Button
        className={clsx(classes.button, {
          [classes.selected]: selected,
        })}
        classes={{
          label: classes.label,
        }}
        component={Link}
        to={to}
      >
        <Badge
          badgeContent={amount && amount}
          color={badgeVariant}
          overlap="rectangular"
        >
          {icon}
        </Badge>
      </Button>
    </Tooltip>
  );
};
