import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { APP_BAR_SPACING } from "../../../components/core/AppLayout";

export default makeStyles<Theme>((theme) =>
  createStyles({
    editor: {
      display: "flex",
      flexDirection: "row",
      height: `calc(100vh - ${theme.spacing(APP_BAR_SPACING)}px)`,
    },
  })
);
