import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>(
  createStyles({
    page: {
      display: "flex",
      minHeight: "100vh",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  })
);
