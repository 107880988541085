import { useState, useEffect, useCallback } from "react";
import { RawDraftContentState } from "draft-js";

import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import { ContentType } from "../../../constants";
import { DocRef } from "../../../utils";

import { useFirestore } from "../../../contexts/firebase/FirebaseContext";

import { TriggerType, useTrigger } from "../../../state/Triggers";
import {
  useCurrentEmailId,
  useFetchEmailById,
  useFetchEmailContentById,
} from "../../../hooks/data/email";
import { useSetEmailContent } from "../../../hooks/actions/emailData";

import DraftJsEditor from "../../../components/editor/DraftJsEditor";
import CKEditor from "../../../components/editor/CKEditor5";

import useStyles from "./styles";

import { Email, EmailContent } from "../../../../../types";

export interface ContentEditorProps {
  /**
   * The key to use for the content.
   */
  contentKey?: string | null;

  /**
   * The type of content.
   */
  contentType?: string | null;

  /**
   * Show the editor in read-only mode.
   */
  readOnly?: boolean;
}

/**
 * Renders the appropriate content editor when given a `contentType`.
 */
export const ContentEditor = ({
  contentKey,
  contentType,
  readOnly,
}: ContentEditorProps) => {
  const classes = useStyles();
  const store = useFirestore();

  const emailId = useCurrentEmailId();
  const fetchInitialEmail = useFetchEmailById(emailId);
  const fetchInitialContent = useFetchEmailContentById(emailId, contentKey);
  const setContent = useSetEmailContent(emailId, contentKey);

  const [initialContent, setInitialContent] = useState<EmailContent>();
  const [initialTokens, setInitialTokens] = useState<string[]>();

  // This trigger is used to refresh the content in the editor
  // when new personalisations are uploaded. This is important
  // as there is currently no other way to update the HTML markup
  // for tokens in the editor if they are changed by a CSV upload.
  const refreshTrigger = useTrigger(TriggerType.EDITOR_CONTENT_REFRESH);

  // Fetch initial content
  useEffect(() => {
    let cancelled = false;

    Promise.all([fetchInitialContent(), fetchInitialEmail()]).then(
      ([content, email]) => {
        if (cancelled) return;
        setInitialContent(content);
        setInitialTokens(email?.tokens ?? []);
      }
    );

    return () => {
      cancelled = true;
    };
  }, [fetchInitialContent, fetchInitialEmail, refreshTrigger]);

  /**
   * This handler saves rich text editor content to Firestore.
   *
   * @param state The RawDraftContentState object to save.
   * @param text The plain text version of the content to save.
   */
  const setRichTextContentHandler = (state: RawDraftContentState, text: string) => {
    setContent({
      text,
      richText: state,
    });
  };

  /**
   * This handler saves HTML content to Firestore.
   *
   * @param html The HTML string to save.
   * @param text The plain text version of the content to save.
   */
  const setHtmlContentHandler = (html: string, text: string) => {
    setContent({
      text,
      html,
    });
  };

  /**
   * This function fetches the latest email document
   * and returns the list of tokens that can be used.
   */
  const getTokens = useCallback(
    async (query: string) => {
      if (!store) return [];
      if (!emailId) return [];

      async function fetchTokens(emailId: string) {
        const ref = store!.doc(`emails/${emailId}`) as DocRef<Email>;
        const snapshot = await ref.get();
        return (snapshot.data()?.tokens ?? []).map((t) => `{{${t}}}`);
      }

      // Filter tokens based on query
      const tokens = await fetchTokens(emailId);
      return tokens.filter((x) =>
        x.toLowerCase().includes(query.toLowerCase().trim())
      );
    },
    [store, emailId]
  );

  if (!contentKey)
    return (
      <Alert severity="error" className={classes.alert}>
        <AlertTitle>
          <b>Missing content key</b>
        </AlertTitle>
        The email template you are using has a missing content key for a{" "}
        {contentType} block. Please contact an administrator to fix the template.
      </Alert>
    );

  if (contentType === ContentType.SINGLE_LINE_TEXT)
    return (
      <DraftJsEditor
        initialContent={initialContent?.richText}
        onSaveContent={setRichTextContentHandler}
        readOnly={readOnly}
        plainText
        singleLine
      />
    );

  if (contentType === ContentType.PLAIN_TEXT)
    return (
      <DraftJsEditor
        initialContent={initialContent?.richText}
        onSaveContent={setRichTextContentHandler}
        readOnly={readOnly}
        plainText
      />
    );

  if (contentType === ContentType.RICH_TEXT) {
    return (
      <CKEditor
        initialTokens={initialTokens}
        initialContent={initialContent?.html ?? initialContent?.richText}
        onSaveContent={setHtmlContentHandler}
        readOnly={readOnly}
        getTokens={getTokens}
      />
    );
  }

  return (
    <Alert severity="error" className={classes.alert}>
      <AlertTitle>
        <b>Unknown content type: {contentType}</b>
      </AlertTitle>
      The email template you are using has an invalid content type. Please contact an
      administrator to fix the template.
    </Alert>
  );
};
