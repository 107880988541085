import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const HEIGHT = 300;

export default makeStyles<Theme>((theme) =>
  createStyles({
    content: {
      flexShrink: 0,
    },
    dropzone: {
      minHeight: HEIGHT,
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      height: "100%",
    },
    noticeAlert: {
      marginTop: theme.spacing(1),
    },
    message: {
      minHeight: HEIGHT,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    list: {
      minHeight: "50vh",
      overflowY: "hidden",
      flexGrow: 1,
    },
    listActionItemGutters: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    filterContainer: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    filterIcon: {
      marginLeft: theme.spacing(1),
    },
    save: {
      color: theme.palette.success.main,
    },
  })
);
