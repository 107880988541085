import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";

import { CLogger } from "../../../utils";

import { SessionState } from "../../../state/FirebaseAuthState";

import { useInputField } from "../../../hooks/state";

import { useAuth } from "../../../contexts/firebase/FirebaseContext";

import useStyles from "./styles";

export interface MockLoginDialogProps {
  /**
   * Whether the dialog is open.
   *
   * @default false
   */
  open?: boolean;

  /**
   * Callback function to close the dialog.
   */
  onClose?: () => void;
}

const log = CLogger.category("components.dialogs.MockLoginDialog");

const createSubGroups = (department: string) => [
  `msend-${department}`,
  `msend-${department}-approvers`,
  `msend-${department}-administrators`,
];

const MOCK_GROUPS = [
  "msend-application-support",
  // "msend-administration", // unused
  ...createSubGroups("hr"),
  ...createSubGroups("sd"),
];

/**
 * Allows the user to select parameters for a mock login.
 */
export function MockLoginDialog(props: MockLoginDialogProps) {
  const { open = false, onClose } = props;
  const classes = useStyles();

  const auth = useAuth();
  const setSession = useSetRecoilState(SessionState);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [monashId, setMonashId, monashIdError, setMonashIdError] = useInputField(
    localStorage.getItem("msend.mocklogin.id") ||
      "9e873364-9c85-4e66-b04f-41da65d3deee",
    open
  );

  const [name, setName, nameError, setNameError] = useInputField(
    localStorage.getItem("msend.mocklogin.name") || "Ben Yap",
    open
  );

  const [email, setEmail, emailError, setEmailError] = useInputField(
    localStorage.getItem("msend.mocklogin.email") || "ben.yap@idmqat.monash.edu",
    open
  );

  const [roles, setRoles] = useState(() => {
    const rolesString = localStorage.getItem("msend.mocklogin.roles");
    if (rolesString && /\[(['"][^'"]*['"],?)*\]/.test(rolesString))
      return new Set(JSON.parse(rolesString));
    return new Set(["msend-application-support"]);
  });

  // Reset values when dialog opens
  useEffect(() => {
    if (!open) return;
    setError("");
  }, [open]);

  /**
   * Call the mock login token endpoint
   */
  const mockSignIn = async () => {
    if (!auth) return;
    setError("");

    // Validate fields

    let valid = true;

    if (!monashId) {
      valid = false;
      setMonashIdError("Monash OBject ID is required.");
    }

    if (!name) {
      valid = false;
      setNameError("Name is required.");
    }

    if (!email) {
      valid = false;
      setEmailError("Email is required.");
    }

    if (roles.size === 0) {
      valid = false;
      setError("At least one role must be selected.");
    }

    if (!valid) return;

    setLoading(true);
    log({ level: "debug", message: "Mock token exchange initiated." });

    localStorage.setItem("msend.mocklogin.id", monashId);
    localStorage.setItem("msend.mocklogin.name", name);
    localStorage.setItem("msend.mocklogin.email", email);
    localStorage.setItem("msend.mocklogin.roles", JSON.stringify(Array.from(roles)));

    const res = await fetch(`/api/mock-token`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        preferred_username: monashId,
        name,
        email,
        Roles: Array.from(roles),
      }),
    });

    if (!res.ok) {
      log({
        level: "error",
        message: `Token exchanged failed.`,
        data: res.statusText,
      });
      setError(`Token exchanged failed`);
      setLoading(false);
      return;
    }

    // Extract token
    const data = await res.json();
    if (!data?.token) {
      log({
        level: "error",
        message: `Token exchanged failed.`,
        data: `Missing token`,
      });
      setError("Token exchanged failed - missing token");
      setLoading(false);
      return;
    }

    // Sign in with custom Firebase token
    log({ level: "debug", message: `Signing in...` });
    await auth.signInWithCustomToken(data.token);

    log({
      level: "debug",
      message: "Signed in sucessfully.",
    });

    onClose?.();
    setSession({});
  };

  /**
   * Mock the Okta session. This will allow the user to sign in
   * if they have an active Firebase session.
   */
  const mockSession = () => {
    log({
      level: "debug",
      message: "Mocking session.",
    });

    onClose?.();
    setSession({});
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Mock Sign In</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" className={classes.field}>
            {error}
          </Alert>
        )}
        <TextField
          className={classes.field}
          fullWidth
          disabled={loading}
          label="Monash Object ID"
          value={monashId}
          onChange={(e) => setMonashId(e.target.value)}
          error={Boolean(monashIdError)}
          helperText={monashIdError}
        />
        <TextField
          className={classes.field}
          fullWidth
          disabled={loading}
          label="Display name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={Boolean(nameError)}
          helperText={nameError}
        />
        <TextField
          className={classes.field}
          fullWidth
          disabled={loading}
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={Boolean(emailError)}
          helperText={emailError}
        />
        <FormControl fullWidth>
          <InputLabel id="role-select-label">Roles</InputLabel>
          <Select
            labelId="role-select-label"
            id="role-select"
            multiple
            disabled={loading}
            value={Array.from(roles)}
            onChange={(e) => setRoles(new Set(e.target.value as string[]))}
            input={<Input />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {(selected as string[]).map((value) => (
                  <Chip key={value} label={value} className={classes.chip} />
                ))}
              </div>
            )}
            MenuProps={{ PaperProps: { className: classes.selectPaper } }}
          >
            {MOCK_GROUPS.map((role) => (
              <MenuItem key={role} value={role}>
                <Checkbox checked={roles.has(role)} />
                <ListItemText primary={role} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading} className={classes.cancel}>
          CANCEL
        </Button>
        <Tooltip title="Requires existing Firebase session" placement="top">
          <div>
            <Button
              onClick={mockSession}
              disabled={loading}
              color="primary"
              variant="outlined"
            >
              MOCK SESSION
            </Button>
          </div>
        </Tooltip>
        <Button
          onClick={mockSignIn}
          disabled={loading}
          color="primary"
          variant="contained"
        >
          SIGN IN
        </Button>
      </DialogActions>
    </Dialog>
  );
}
