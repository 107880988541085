import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";

export interface EmptyStateProps {}

/**
 * Shows an empty state graphic.
 */
export const EmptyState = (props: EmptyStateProps) => {
  const classes = useStyles();
  return (
    <div className={classes.emptyState}>
      <img className={classes.image} src="/images/empty_mail.svg" alt="No emails" />
      <Typography color="textSecondary" variant="body1">
        <b>No email selected</b>
      </Typography>
      <Typography color="textSecondary" variant="body2">
        Choose an email to get started.
      </Typography>
    </div>
  );
};
