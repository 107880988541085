import { makeStyles, createStyles, Theme, lighten } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    content: {
      flexShrink: 0,
    },
    tasksList: {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderBottom: `1px solid ${theme.palette.divider}`,
      overflowY: "auto",
    },
    task: {
      "display": "flex",
      "flexDirection": "column",
      "alignItems": "flex-start",
      "paddingLeft": theme.spacing(3),
      "paddingRight": theme.spacing(3),
      "&:not(:last-child)": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
    taskInfo: {
      display: "flex",
      alignItems: "center",
    },
    taskIcon: {
      minWidth: theme.spacing(5),
    },
    taskLoading: {
      width: "100%",
      marginBottom: theme.spacing(1),
    },
    taskSuccess: {
      color: theme.palette.success.main,
    },
    taskPartial: {
      color: theme.palette.warning.main,
    },
    loadingError: {
      backgroundColor: lighten(theme.palette.error.light, 0.3),
    },
    loadingBarError: {
      backgroundColor: theme.palette.error.main,
    },
    loadingPartialError: {
      backgroundColor: lighten(theme.palette.warning.light, 0.3),
    },
    loadingBarPartialError: {
      backgroundColor: theme.palette.warning.main,
    },
    taskId: {
      top: "auto",
    },
  })
);
