import { useState, useEffect } from "react";
import clsx from "clsx";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import { useCurrentEmailId, useEmailContentById } from "../../../hooks/data/email";
import { useSetEmailContent } from "../../../hooks/actions/emailData";

import useStyles from "./styles";

export interface SelectionEditorProps {
  /**
   * The key to use for the content.
   */
  contentKey: string;

  /**
   * The options to show for the selection field.
   */
  options?: {
    id: string;
    value: string;
  }[];

  /**
   * If this value is provided, the content at the specified key
   * will be set to this value if it does not exist yet.
   */
  defaultValue?: string;

  /**
   * Show the editor in read-only mode.
   */
  readOnly?: boolean;

  /**
   * Show a placeholder when the selector is empty.
   */
  placeholder?: string;
}

/**
 * This editor allows the user to choose an option from a predefined
 * list of items provided through the Recoil selector family
 * `SelectionData` with the given key `selectionDataKey`.
 */
export const SelectionEditor = ({
  contentKey,
  options,
  defaultValue,
  readOnly,
  placeholder,
}: SelectionEditorProps) => {
  const classes = useStyles();
  const emailId = useCurrentEmailId();
  const [content, loading] = useEmailContentById(emailId, contentKey);
  const setContent = useSetEmailContent(emailId, contentKey);

  // Use local state to show the UI because `setContent` asynchronously updates its value
  const [selected, setSelected] = useState("");

  // Initialise content shown in the select if there is no local state yet
  useEffect(() => {
    if (selected) return;
    if (!content) return;
    setSelected(content.text);
  }, [selected, content, setSelected]);

  // Set the default option if there is no value
  useEffect(() => {
    if (loading) return;
    if (content) return;
    if (readOnly) return;
    if (!defaultValue) return;
    setContent({ text: defaultValue });
  }, [loading, content, readOnly, setContent, defaultValue, contentKey, emailId]);

  /**
   * Handle the selection change.
   */
  const handleOnSelect = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const value = (e.target.value as string) ?? "";
    setSelected(value);
    setContent({ text: value });
  };

  if (readOnly)
    return (
      <Typography variant="body2" color={selected ? "textPrimary" : "textSecondary"}>
        {selected || "Unavailable"}
      </Typography>
    );

  const noOptions = (options?.length ?? 0) === 0;
  const onlyOneOption = (options?.length ?? 0) === 1;

  return (
    <Select
      fullWidth
      classes={{
        root: classes.select,
        icon: clsx({ [classes.hidden]: onlyOneOption }),
      }}
      value={selected}
      onChange={handleOnSelect}
      disableUnderline
      readOnly={onlyOneOption}
      disabled={noOptions}
      displayEmpty
      renderValue={(value) => {
        if (typeof value === "string" && value.length > 0)
          return <Typography variant="body2">{value}</Typography>;
        return (
          <Typography variant="body2" color="textSecondary">
            <em>{placeholder}</em>
          </Typography>
        );
      }}
    >
      {options?.map((item) => (
        <MenuItem key={item.id} value={item.value}>
          {item.value}
        </MenuItem>
      ))}
    </Select>
  );
};
