import { useParams, useNavigate } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import ReviewIcon from "@material-ui/icons/RateReview";
import ApproveIcon from "@material-ui/icons/Check";
import RejectIcon from "@material-ui/icons/Clear";

import { CLogger } from "../../../utils";

import { useCurrentUser } from "../../../state/FirebaseAuthState";

import { useSnack } from "../../../hooks/snack";
import { useBoolean, useInputField } from "../../../hooks/state";
import { useSubmitReview } from "../../../hooks/actions/email";
import { useEmail } from "../../../hooks/data/email";
import { useOrderedEmailReviews } from "../../../hooks/data/email/review";

import ReviewDisplay from "../../../components/core/ReviewDisplay";

import { useActionStyles } from "../styles";
import useStyles from "./styles";

export interface EditorSubmitReviewActionProps {
  /**
   * Disable this action.
   */
  disabled?: boolean;
}

const log = CLogger.category("components/editorToolbar/EditorSubmitReviewAction");

/**
 * This action allows the user to submit a review for the current email.
 */
export const EditorSubmitReviewAction = ({
  disabled,
}: EditorSubmitReviewActionProps) => {
  const sharedClasses = useActionStyles();
  const classes = useStyles();

  const { emailId } = useParams();
  const { pushClosableSnack, pushErrorSnack } = useSnack();
  const user = useCurrentUser();
  const navigate = useNavigate();
  const submitReview = useSubmitReview(emailId);
  const [email] = useEmail(emailId);

  const [reviews] = useOrderedEmailReviews(emailId);
  const [show, open, close] = useBoolean();
  const [comment, setComment] = useInputField("", show);

  /**
   * Submit the review and redirect the user if they are not in the email
   */
  const submitReviewHandler = async (approve: boolean, comments?: string) => {
    try {
      await submitReview(approve, comments);
      pushClosableSnack(`Email ${approve ? "approved" : "rejected"}.`);

      // Redirect the user away from the email if they are not an editor.
      // This is because once the review is submitted, the user will
      // no longer be able to view the email, so we don't want them to
      // see an "access denied" screen.
      if (user && email && !email.editors.includes(user.uid))
        navigate(`/emails/review`);
    } catch (error: any) {
      pushErrorSnack(`Failed to submit review. ${error.message}`);
      log({
        level: "error",
        message: `Error while submitting review: ${error.message}`,
        data: error,
      });
    }
  };

  return (
    <>
      <Tooltip title="Submit your review" placement="bottom">
        <div>
          <IconButton
            className={sharedClasses.button}
            classes={{ disabled: sharedClasses.disabled }}
            disabled={disabled}
            onClick={open}
          >
            <ReviewIcon />
          </IconButton>
        </div>
      </Tooltip>
      <Dialog open={show} onClose={close} maxWidth="xs" fullWidth>
        <DialogTitle>Submit review</DialogTitle>
        <DialogContent>
          <ReviewDisplay
            className={classes.reviews}
            reviews={reviews}
            mode="review"
          />
          <DialogContentText>
            Approve the email to allow the author to send this email to the specified
            recipients.
          </DialogContentText>
          <TextField
            multiline
            fullWidth
            className={classes.comment}
            label="Leave a comment for the author"
            placeholder="Optional"
            variant="outlined"
            minRows={2}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} className={classes.cancel}>
            CANCEL
          </Button>
          <Button
            variant="contained"
            className={classes.reject}
            startIcon={<RejectIcon />}
            onClick={() => {
              submitReviewHandler?.(false, comment.trim());
              close();
            }}
          >
            REJECT
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ApproveIcon />}
            onClick={() => {
              submitReviewHandler?.(true, comment.trim());
              close();
            }}
          >
            APPROVE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
