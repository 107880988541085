import clsx from "clsx";

import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

import useStyles from "./styles";

export interface ProgressDisplayProps {
  /**
   * If `true`, a red progess is shown instead.
   */
  error?: boolean;

  /**
   * The progress to show on the progress circle.
   * Values range from 0 to 100.
   */
  progress?: number;

  /**
   * The value to show on the tooltip. No tooltip is
   * shown if this is empty.
   */
  tooltip?: React.ReactNode;

  /**
   * A description to place below the progress display.
   */
  description?: string;
}

/**
 * Display a circular progress with a percentage and description.
 */
export function ProgressDisplay(props: ProgressDisplayProps) {
  const { error, progress = 0, tooltip, description } = props;
  const classes = useStyles();
  return (
    <div>
      <Tooltip title={tooltip || ""} placement="top">
        <div className={classes.status}>
          <CircularProgress
            className={clsx({
              [classes.error]: error,
              [classes.success]: !error && progress === 100,
            })}
            variant="determinate"
            value={progress}
            thickness={6}
            size={84}
          />
          <Typography variant="body1" className={classes.statusValue}>
            {progress.toFixed(progress > 0 && progress < 10 ? 1 : 0)}%
          </Typography>
        </div>
      </Tooltip>
      <Typography
        variant="body1"
        color="textSecondary"
        className={classes.statusCaption}
      >
        {description}
      </Typography>
    </div>
  );
}
