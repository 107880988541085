import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { Color } from "../../../constants";

export default makeStyles<Theme>(
  createStyles({
    editor: {
      width: "100%",
    },
  })
);

export const useToolbarStyles = makeStyles<Theme>((theme) =>
  createStyles({
    toolbar: {
      padding: theme.spacing(0.5),
      display: "flex",
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
      marginBottom: theme.spacing(1),
      backgroundColor: `${Color.BACKGROUND}f0`,
      zIndex: theme.zIndex.appBar,
      position: "sticky",
      top: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
    },
    divider: {
      margin: theme.spacing(1),
      borderRight: "solid 1px #efefef",
    },
  })
);

export const useToolbarButtonStyles = makeStyles<Theme>((theme) =>
  createStyles({
    button: {
      minWidth: 0,
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.text.secondary,
      transition: theme.transitions.create(["color", "background-color"]),
    },
    active: {
      backgroundColor: `rgba(0, 0, 0, 0.05)`,
      color: theme.palette.text.primary,
    },
    buttonText: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
  })
);

export const useLinkPopoverStyles = makeStyles<Theme>((theme) =>
  createStyles({
    paper: {
      minWidth: 320,
    },
    content: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(3),
    },
    actions: {
      padding: theme.spacing(1),
      display: "flex",
      justifyContent: "space-between",
    },
  })
);
