import { atomFamily } from "recoil";

export enum TriggerType {
  EDITOR_CONTENT_REFRESH,
}

export const TriggerState = atomFamily<number, TriggerType>({
  key: "TriggerState",
  default: 0,
});
