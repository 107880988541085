export class BatchUtils {
  /**
   * Split a list into batches of the specified size.
   *
   * @param items The list of items to batch.
   * @param batchSize The size of each batch.
   */
  static split<T>(items: T[], batchSize: number): T[][] {
    const batches: T[][] = [];
    for (let i = 0; i < items.length; i += batchSize) {
      batches.push(items.slice(i, i + batchSize));
    }
    return batches;
  }
}
