import { atomFamily } from "recoil";
import { EditorState } from "draft-js";

/**
 * This atom is used to keep track of the DraftJS editor state.
 */
export const DraftJsEditorState = atomFamily<EditorState, string>({
  key: "DraftJsEditorState",
  default: EditorState.createEmpty(),
});
