import { useEffect } from "react";
import { useSetRecoilState } from "recoil";

import { LIST_MAX } from "../../constants";

import { useEmailList } from "../../hooks/data/email";
import { useLogError } from "../../hooks/log";

import { EditableEmailsState, ReviewableEmailsState } from "./atoms";

import { EmailStatus } from "../../../../types";

/**
 * Retrieve the current user's editable emails and store
 * it in the `EditableEmailsState` atom.
 */
export const useEditableEmails = (status: EmailStatus) => {
  const setState = useSetRecoilState(EditableEmailsState(status));

  const [data, loading, error] = useEmailList({
    type: "editors",
    status,
    limit: LIST_MAX,
  });

  useLogError(
    `Error while retrieving editable ${status} emails for user.`,
    "hook-useEditableEmails",
    error
  );

  useEffect(() => {
    setState([data, loading, error]);
  }, [setState, data, loading, error]);
};

/**
 * Retrieve the current user's reviewable emails and store
 * it in the `ReviewableEmailsState` atom.
 */
export const useReviewableEmails = () => {
  const setState = useSetRecoilState(ReviewableEmailsState);

  const [data, loading, error] = useEmailList({
    type: "reviewers",
    status: "review",
    limit: LIST_MAX,
  });

  useLogError(
    `Error while retrieving reviewable emails for user.`,
    "hook-useEditableEmails",
    error
  );

  useEffect(() => {
    setState([data, loading, error]);
  }, [setState, data, loading, error]);
};
