import { useCallback } from "react";
import { useSnackbar, OptionsObject } from "notistack";

import { useTheme } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

import ClearIcon from "@material-ui/icons/Clear";

import { id } from "../utils";

/**
 * An extension to the `useSnackbar` hook from `notistack`.
 * In addition to `enqueueSnackbar` and `closeSnackbar`, this
 * hook also returns additional functions that can create
 * commonly used snackbars that have been pre-configured.
 */
export const useSnack = () => {
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  /**
   * Creates a snackbar that can be closed.
   */
  const pushClosableSnack = useCallback(
    (message: React.ReactNode, options: OptionsObject = {}) => {
      const { key = id(), autoHideDuration = 5000 } = options;
      enqueueSnackbar(message, {
        key,
        autoHideDuration,
        action: (
          <IconButton
            size="small"
            style={{ color: theme.palette.error.contrastText }}
            onClick={() => closeSnackbar(key)}
          >
            <ClearIcon />
          </IconButton>
        ),
        ...options,
      });
    },
    [enqueueSnackbar, closeSnackbar, theme]
  );

  /**
   * Creates an error snackbar that can be closed.
   */
  const pushErrorSnack = useCallback(
    (message: React.ReactNode, options: OptionsObject = {}) => {
      pushClosableSnack(message, { variant: "error", ...options });
    },
    [pushClosableSnack]
  );

  /**
   * Creates a persistent snackbar that can be closed.
   */
  const pushPersistentSnack = useCallback(
    (message: React.ReactNode, options: OptionsObject = {}) => {
      const { key = id() } = options;
      enqueueSnackbar(message, {
        key,
        persist: true,
        action: (
          <IconButton
            size="small"
            style={{ color: theme.palette.error.contrastText }}
            onClick={() => closeSnackbar(key)}
          >
            <ClearIcon />
          </IconButton>
        ),
        ...options,
      });
    },
    [enqueueSnackbar, closeSnackbar, theme]
  );

  /**
   * Creates a permanent snackbar that can only be closed programatically.
   */
  const pushPermanentSnack = useCallback(
    (message: React.ReactNode, options: OptionsObject = {}) => {
      const key = options.key ?? id();
      enqueueSnackbar(message, {
        persist: true,
        ...options,
        key,
      });
      return key;
    },
    [enqueueSnackbar]
  );

  return {
    closeSnack: closeSnackbar,
    pushSnack: enqueueSnackbar,
    pushClosableSnack,
    pushErrorSnack,
    pushPersistentSnack,
    pushPermanentSnack,
  };
};
