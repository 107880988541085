import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      flexShrink: 0,
      flexGrow: 0,
    },
    label: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    loading: {
      position: "absolute",
      width: "100%",
    },
    avatar: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },

    // List
    listHeading: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(1),
    },
    list: {
      minHeight: "10vh",
      overflowY: "hidden",
      flexGrow: 1,
    },
    listbox: {
      padding: 0,
    },
  })
);
