import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const HEIGHT = 200;

export default makeStyles<Theme>((theme) =>
  createStyles({
    content: {
      flexShrink: 0,
    },
    dropzone: {
      minHeight: HEIGHT,
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      height: "100%",
    },
    list: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    action: {
      paddingRight: theme.spacing(14),
    },
    clear: {
      marginRight: "auto",
    },
    icon: {
      minWidth: theme.spacing(5),
    },
  })
);
