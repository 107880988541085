import {
  parse,
  ParseConfig,
  ParseWorkerConfig,
  ParseLocalConfig,
  ParseResult,
  ParseError as PapaParseError,
} from "papaparse";
import { convertFromRaw, RawDraftContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

import { id, Utf8Utils } from "../utils";

import { EmailRecipient } from "../../../types";

export type ParseError = PapaParseError;

/**
 * A `ParseWarning` is used to capture warning messages for
 * a result that has been parsed from a CSV.
 */
export interface ParseWarning {
  /**
   * The id of the result that this warning is for.
   */
  id: string;

  /**
   * The warning message.
   */
  message: string;

  /**
   * If this is `true`, it will block the personalisation
   * from being saved.
   */
  blocking?: boolean;
}

export interface ParseNotice {
  /**
   * The id of the result that this notice is for.
   */
  id: string;

  /**
   * The informing message.
   */
  message: string;

  /**
   * The type of notice
   */
  type: "mailing-list";
}

/**
 * ==============================
 * HEY DEVELOPER! IMPORTANT INFO!
 * ==============================
 *
 * This class contains utils that are shared between the client
 * and the server. This file should not use any Node.JS or browser
 * specific methods.
 *
 * This file should be replicated across the following places:
 *   - `frontend/src/utils/SharedUtils.ts`
 *   - `functions/src/utils/SharedUtils.ts`
 *
 * Tests for this file are located at:
 *   - `frontend/src/utils/__tests__/SharedUtils.spec.ts`
 *
 * _Reason for code duplication_
 *
 * Current repository structure and tooling does not have an easy
 * mechanism for sharing code between different project "roots".
 * Definitely something to look for improving later on.
 */
export class SharedUtils {
  static readonly EMAIL_REGEX = /^[\w.'+-]+@[\w-]+(\.[\w-]+)+$/;
  static readonly MONASH_REGEX = /^[\w.'+-]+@([\w-]+\.)*monash\.edu$/;
  static readonly MONASH_QAT_REGEX = /^[\w.'+-]+@([\w-]+\.)*idmqat\.monash\.edu$/;
  static readonly RESERVED_TOKEN_NAMES = new Set(["name", "email", "date"]);

  /**
   * Parse a CSV file with email personalisations.
   *
   * IMPORTANT: This method should be implemented identically between the client and server.
   *
   * @param file The CSV file object.
   */
  public static async parsePersonalisations(
    file: string | NodeJS.ReadableStream | File,
    options: {
      worker?: boolean;
      allowExternal?: boolean;
    } = {}
  ): Promise<{
    recipients: EmailRecipient[];
    errorMessage: string;
    parseErrors: ParseError[];
    parseWarnings: ParseWarning[];
    parseNotices: ParseNotice[];
    tokens: string[];
  }> {
    let recipients: EmailRecipient[] = [];
    let errorMessage = "";
    let parseErrors: ParseError[] = [];
    const parseWarnings: ParseWarning[] = [];
    const parseNotices: ParseNotice[] = [];
    const tokens: Set<string> = new Set<string>();

    // Check for duplicate emails by collecting each email used
    const usedRecipientEmails: Record<string, EmailRecipient[]> = {};

    // Wait for parsing to complete
    await new Promise<void>((resolve) => {
      function processResult(result: ParseResult<any>) {
        const { data, errors, meta } = result;

        // Check for parse errors
        if (errors.length > 0) {
          parseErrors = errors;
          return resolve();
        }

        // Check that the required fields are present
        const missing = ["name", "email"].filter(
          (header) => !meta.fields?.includes(header)
        );
        if (missing.length > 0) {
          errorMessage = `Required columns are missing: ${missing.join(", ")}`;
          return resolve();
        }

        // Record tokens used
        meta.fields?.forEach((field) => {
          if (!field.startsWith("cc") && !field.startsWith("bcc")) tokens.add(field);
        });

        // Transform parsed data into email recipients and perform checks
        recipients = recipients.concat(
          data.map<EmailRecipient>((row) => {
            const { name: rawName, email: rawEmail, ...other } = row;
            const rowId = id();

            // Extract non-empty CC's, identified by column names starting with 'cc'
            const rawCC = Object.keys(other).reduce<string[]>((list, key) => {
              if (key.startsWith("cc") && other[key]) list.push(other[key]);
              return list;
            }, []);

            // Extract non-empty BCC's, identified by column names starting with 'bcc'
            const rawBCC = Object.keys(other).reduce<string[]>((list, key) => {
              if (key.startsWith("bcc") && other[key]) list.push(other[key]);
              return list;
            }, []);

            // Extract all non-cc and non-bcc personalisations
            const personalisations = Object.keys(other).reduce<
              Record<string, string>
            >((values, key) => {
              if (!key.startsWith("cc") && !key.startsWith("bcc"))
                values[key] = other[key];
              return values;
            }, {});

            // Sanitise values
            const name = rawName?.trim() ?? "";
            const email = rawEmail?.toLowerCase().trim() ?? "";
            const cc = rawCC.map((cc) => cc.toLowerCase().trim());
            const bcc = rawBCC.map((cc) => cc.toLowerCase().trim());

            // Add a blocking warning if any recipients are missing name or email
            const missing: string[] = [];
            if (!name) missing.push("name");
            if (!email) missing.push("email");
            if (missing.length)
              parseWarnings.push({
                id: rowId,
                blocking: true,
                message: `${name || email || id} is missing ${missing.join(
                  " and "
                )}`,
              });

            // Test if name contains broken UTF-8 (detect sequence of gibrresh latin characters that maps to legit UTF-8 characters)
            if (Utf8Utils.isBroken(name)) {
              // Present a possible fix for the user by showing the expected/correct UTF-8 name
              const fixedName = Utf8Utils.fixUtf8(name);
              parseWarnings.push({
                id: rowId,
                blocking: true,
                message: `Invalid name: "${name}" contains a possible encoding issue. Did you mean "${fixedName}"?`,
              });
            }

            // Check for empty personalisations
            Object.keys(personalisations).forEach((key) => {
              if (!(personalisations[key] as string).trim()) {
                parseWarnings.push({
                  id: rowId,
                  message: `"${key}" is empty`,
                });
              }
            });

            // Ensure email addresses are valid
            [email, ...cc, ...bcc].forEach((email) => {
              if (!SharedUtils.EMAIL_REGEX.test(email))
                parseWarnings.push({
                  id: rowId,
                  message: `Invalid email: ${email}`,
                  blocking: true,
                });
              else {
                if (options.allowExternal) {
                  // If allow external is true:
                  // Show non-blocking warnings for emails outside of Monash
                  if (!SharedUtils.MONASH_REGEX.test(email)) {
                    parseWarnings.push({
                      id: rowId,
                      message: `${email} is outside of monash.edu domain`,
                    });
                  }
                } else {
                  // If allow external is false:
                  // Show blocking warnings for emails outside of IDMQAT Monash
                  if (!SharedUtils.MONASH_QAT_REGEX.test(email)) {
                    parseWarnings.push({
                      id: rowId,
                      message: `${email} is outside of idmqat.monash.edu domain`,
                      blocking: true,
                    });
                  }
                }
              }
            });

            // Ensure email addresses are unique within this personalisation
            const usedWithin: Record<string, "used" | "used-and-warned"> = {};

            [email, ...cc, ...bcc].forEach((email) => {
              const state = usedWithin[email];
              if (state === "used") {
                parseWarnings.push({
                  id: rowId,
                  message: `${email} cannot be used more than once in this personalisation.`,
                  blocking: true,
                });
                usedWithin[email] = "used-and-warned";
              } else if (state !== "used-and-warned") usedWithin[email] = "used";
            });

            const result: EmailRecipient = {
              id: rowId,
              name,
              email,
              cc,
              bcc,
              personalisations,
            };

            // Add the recipient email to the email collector
            if (!usedRecipientEmails[email]) usedRecipientEmails[email] = [];
            usedRecipientEmails[email].push(result);

            // Check if it is a mailing list
            if (email.search(/-l@/) !== -1) {
              parseNotices.push({
                id: rowId,
                message: `${email} is a mailing list`,
                type: "mailing-list",
              });
            }

            return result;
          })
        );

        // Check for duplicate recipient emails
        Object.values(usedRecipientEmails).forEach((usedIn) => {
          // If the email has more than one result, add a warning
          if (usedIn.length > 1) {
            usedIn.forEach(({ id }) =>
              parseWarnings.push({
                id,
                message:
                  "Duplicate: this email is also the recipient in another personalisation",
              })
            );
          }
        });
      }

      // Remove BOM characters before parsing to support UTF-8 with BOM format
      // See https://github.com/mholt/PapaParse/issues/372
      // Solution from https://github.com/sindresorhus/strip-bom/blob/main/index.js
      function removeBOM(chunk: string) {
        if (chunk.charCodeAt(0) === 0xfeff) return chunk.slice(1);
        return chunk;
      }

      if (typeof file === "string") {
        const config: ParseConfig = {
          header: true,
          skipEmptyLines: true,
          complete(result) {
            processResult(result);
            return resolve();
          },
        };

        if (options.worker) {
          (config as ParseWorkerConfig).worker = true;
        } else {
          config.beforeFirstChunk = removeBOM;
        }

        // Parse file
        parse(file, config);
      } else {
        const config: ParseLocalConfig = {
          header: true,
          skipEmptyLines: true,
          error(error) {
            errorMessage = error.message;
            return resolve();
          },
          chunk: processResult,
          complete() {
            return resolve();
          },
        };

        // HACK: Need to cast `file` to `any` as PapaParse doesn't
        // seem to recognise the NodeJS.ReadableStream type
        parse(file as any, config);
      }
    });

    return {
      recipients,
      errorMessage,
      parseErrors,
      parseWarnings,
      parseNotices,
      tokens: Array.from(tokens),
    };
  }

  /**
   * Generate the personalisations for a recipient.
   *
   * @param recipient The recipient to generate personalisations for.
   */
  public static generatePersonalisationTokens(
    recipient?: EmailRecipient
  ): Record<string, string> {
    return {
      ...recipient?.personalisations,
      name: recipient?.name ?? "",
      email: recipient?.email ?? "",
      date: new Date().toISOString(),
    };
  }

  /**
   * Renders a rich text content state to a HTML string.
   *
   * IMPORTANT: This method should be implemented identically between the client and server.
   *
   * @see https://www.npmjs.com/package/draft-js-export-html
   *
   * @param state The raw Draft.js content state object.
   */
  public static renderRichText(state: RawDraftContentState) {
    return stateToHTML(convertFromRaw(state), {
      blockStyleFn: () => ({
        style: {
          lineHeight: "20px",
        },
      }),
      entityStyleFn: (entity) => {
        const type = entity.getType();
        const data = entity.getData();
        // Customise LINK entity styling
        if (type === "LINK") {
          return {
            element: "a",
            attributes: {
              href: data.link,
            },
            style: {
              color: "#006dae",
            },
          };
        }
        return {};
      },
    });
  }
}
