import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      position: "relative",
      height: "100%",
    },
    loading: {
      position: "absolute",
      width: "100%",
      left: 0,
      top: 0,
      borderRadius: theme.shape.borderRadius,
    },
    heading: {
      display: "flex",
      marginBottom: theme.spacing(2),
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        alignItems: "flex-start",
        flexDirection: "column",
      },
    },
    title: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        marginBottom: theme.spacing(1),
      },
    },
    actions: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      width: "50%",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    recipientFilter: {
      width: "100%",
      marginRight: theme.spacing(1),
    },
    recipientList: {
      height: `calc(100vh - 540px)`,
      minHeight: (56 + 8 + 8) * 6,
    },
  })
);
