import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import AttachmentIcon from "@material-ui/icons/AttachFile";

import { useBoolean } from "../../../hooks/state";
import { useEmailAttachments } from "../../../hooks/data/email";
import {
  useAddAttachment,
  useDownloadAttachment,
  useRemoveAttachment,
} from "../../../hooks/actions/emailAttachments";

import AttachmentsDialog from "../../../components/dialogs/AttachmentsDialog";

export interface EditorAttachmentsActionProps {
  /**
   * The styling class
   */
  className?: string;

  /**
   * The id of the email to preview.
   */
  emailId?: string;

  /**
   * Disables the action button.
   */
  disabled?: boolean;

  /**
   * Show the attachments as read only.
   */
  readOnly?: boolean;
}

/**
 * This action allows a user to manage the email's attachments.
 */
export function EditorAttachmentsAction({
  className,
  emailId,
  disabled,
  readOnly,
}: EditorAttachmentsActionProps) {
  const [open, onOpen, onClose] = useBoolean();
  const [attachments] = useEmailAttachments(emailId);
  const addAttachment = useAddAttachment(emailId);
  const removeAttachment = useRemoveAttachment(emailId);
  const downloadAttachment = useDownloadAttachment(emailId);

  const onAddAttachments = async (attachments: File[]) => {
    for (const attachment of attachments) {
      await addAttachment(attachment);
    }
  };

  return (
    <>
      <Tooltip title="Attachments" placement="left">
        <div>
          <IconButton onClick={onOpen} disabled={disabled} className={className}>
            <AttachmentIcon />
          </IconButton>
        </div>
      </Tooltip>
      <AttachmentsDialog
        open={open}
        onClose={onClose}
        readOnly={readOnly}
        attachments={attachments}
        onAddAttachments={onAddAttachments}
        onRemoveAttachment={removeAttachment}
        onDownloadAttachment={downloadAttachment}
      />
    </>
  );
}
