import Container, { ContainerProps } from "@material-ui/core/Container";
import Typography, { TypographyProps } from "@material-ui/core/Typography";

import useStyles from "./styles";

export interface PageTitleProps {
  children?: React.ReactNode;

  /**
   * The height of the header background.
   */
  height?: number | string;

  /**
   * Disable wrapping the title in a `Typography` component.
   */
  disableTypography?: boolean;

  /**
   * The title size variant.
   *
   * @default "h4"
   */
  variant?: TypographyProps["variant"];

  /**
   * The width of the title.
   *
   * @default "md"
   */
  width?: ContainerProps["maxWidth"];
}

/**
 * Show a page title with a coloured background.
 */
export const PageTitle = (props: PageTitleProps) => {
  const classes = useStyles(props);
  const { variant = "h4", width = "md", disableTypography, children } = props;
  return (
    <div className={classes.root}>
      <div className={classes.background}>
        <Container maxWidth={width}>
          {disableTypography ? (
            <div className={classes.title}>{children}</div>
          ) : (
            <Typography
              variant={variant}
              variantMapping={{
                [variant]: "h1",
              }}
              className={classes.title}
            >
              {children}
            </Typography>
          )}
        </Container>
      </div>
    </div>
  );
};
