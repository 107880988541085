import { useState, useEffect } from "react";

import { id } from "../../utils";

import { useCurrentUser } from "../../state/FirebaseAuthState";
import { useSettings, useEmailSettings } from "../../hooks/data/settings";

import { EmailsByGroupSettings } from "../../../../types";

interface SelectionItem {
  id: string;
  value: string;
}

/**
 * Retrieve a list of emails from settings for the current user
 * based on their groups.
 *
 * @param type The type of email group to get.
 * @param group Filter results from the specified group.
 */
const useEmailsByGroup = (
  type: "replyTo" | "from",
  group?: string
): [SelectionItem[], boolean] => {
  const user = useCurrentUser();
  const [settings, settingsLoading] = useEmailSettings();
  const [emailsByGroup, emailsLoading] = useSettings<EmailsByGroupSettings>(type);
  const [items, setItems] = useState<SelectionItem[]>([]);

  // Map the settings to selection items
  useEffect(() => {
    if (!user) return;
    if (!emailsByGroup) return;
    if (!settings) return;

    // Use a set to ensure repeated emails are only shown once
    const items: Set<string> = new Set();

    // If a group is provided, only show addresses from that group
    if (group && user.groups?.includes(group)) {
      emailsByGroup[group]?.forEach((email) => items.add(email));
    } else {
      // Add emails for each group to the list
      user.groups?.forEach((group) =>
        emailsByGroup[group]?.forEach((email) => items.add(email))
      );
    }

    // If there are no from addresses available, set the default value
    if (items.size === 0)
      setItems([{ id: "default", value: settings?.defaultReplyTo ?? "" }]);
    else setItems(Array.from(items).map((value) => ({ id: id(), value })));
  }, [user, group, setItems, settings, emailsByGroup]);

  return [items, settingsLoading || emailsLoading];
};

/**
 * Retrieve the reply-to email addresses available to the current user.
 *
 * If none are available to the user through their groups, the default
 * reply-to address will be provided.
 *
 * @param group Filter the reply to options by the given group.
 */
export const useReplyToAddresses = (group?: string) =>
  useEmailsByGroup("replyTo", group);

/**
 * Retrieve the from email addresses available to current user.
 *
 * If none are available to the user through their groups, the default
 * from address will be provided.
 *
 * @param group Filter the reply to options by the given group.
 */
export const useFromAddresses = (group?: string) => useEmailsByGroup("from", group);
