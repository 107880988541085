import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    acknowledge: {
      color: theme.palette.info.light,
      marginLeft: theme.spacing(1),
    },
    dismiss: {
      color: theme.palette.info.contrastText,
      marginLeft: theme.spacing(1),
    },
  })
);
