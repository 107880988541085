import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { APP_BAR_SPACING } from "../../../components/core/AppLayout";

export default makeStyles<Theme>((theme) =>
  createStyles({
    loading: {
      display: "flex",
      justifyContent: "center",
      marginTop: "20vh",
      width: "100%",
    },
    wrapper: {
      overflow: "auto",
      width: "100%",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    editor: {
      display: "flex",
      flexDirection: "row-reverse",
      height: `calc(100vh - ${theme.spacing(APP_BAR_SPACING)}px)`,
    },
    group: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
    unavailable: {
      cursor: "pointer",
    },

    // Meta fields
    field: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      height: 50,
    },
    fieldLabel: {
      marginRight: theme.spacing(1),
      minWidth: 70,
    },
    fieldAttachments: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      backgroundColor: theme.palette.background.paper,
      height: "auto",
    },
    paperClip: {
      paddingLeft: theme.spacing(0),
      marginLeft: theme.spacing(-0.75),
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
      gap: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0.15),
    },
    subjectField: {
      maxHeight: 50,
      width: "100%",
      overflowY: "auto",
    },
  })
);
