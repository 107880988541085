export interface Changelog {
  id: string;
  type: "patch" | "minor" | "major";
  date?: Date;
  features?: string[];
  changes?: string[];
  fixes?: string[];
}

export const CHANGELOG: Changelog[] = [
  {
    id: "1.7.5",
    type: "patch",
    date: new Date("2023-08-15"),
    features: [`Addition of attachments field in email page`],
  },
  {
    id: "1.7.4",
    type: "patch",
    date: new Date("2023-07-10"),
    features: [`Integrate a dedicated Report page (accessible by admins)`],
    fixes: [
      `Detect encoding issues with recipient names and propose a potential fix`,
    ],
    changes: [
      `Improve parsing errors feedback by displaying errors origin (row number and reason)`,
    ],
  },
  {
    id: "1.7.2",
    type: "patch",
    date: new Date("2023-03-30"),
    fixes: [
      `Internal changes that removes unwanted styling on Spark Desktop (email client)`,
    ],
  },
  {
    id: "1.7.1",
    type: "patch",
    date: new Date("2022-06-14"),
    fixes: [
      `Prevent selected template from being corrupted when template IDs are updated`,
    ],
  },
  {
    id: "1.7.0",
    type: "minor",
    date: new Date("2022-03-29"),
    features: [`Add ability to leave comments on a review`],
    changes: [
      `Show latest review in the submit review window`,
      `Show review history in new window`,
    ],
  },
  {
    id: "1.6.0",
    type: "minor",
    date: new Date("2022-02-08"),
    features: [
      `Filter recipients by status on the email status page`,
      `Export recipient statuses as CSV on the email status page`,
      `Preview a template before selecting it for use`,
    ],
    changes: [`Show percentage of emails bounced and dropped on email status page`],
    fixes: [
      `Hide unnecessary scroll bars`,
      `Allow data URL images to load properly`,
      `Show correct error when email does not exist (instead of no template)`,
    ],
  },
  {
    id: "1.5.1",
    type: "minor",
    date: new Date("2021-11-09"),
    features: [`Internal system upgrades`],
    fixes: [`Crash when a user is in more than 10 groups`],
  },
  {
    id: "1.4.0",
    type: "minor",
    date: new Date("2021-08-18"),
    features: [
      `Logs can now filtered by tags with an improved UI`,
      `Reviews are now displayed with the reviewer name, date and approval status`,
      `Email notifications for approvals now include reviewer name`,
    ],
    changes: [`Revamped system and administration pages layout`],
    fixes: [`Disable review action on emails without a template selected`],
  },
  {
    id: "1.3.0",
    type: "minor",
    date: new Date("2021-07-07"),
    features: [
      `Add ability to create static tables in emails`,
      `Show recipients and subject in preview mode`,
    ],
    changes: [
      `Personalisation tokens (merge fields) are only highlighted if they are valid`,
      `Prevent emails from being submitted for review when there are no recipients`,
      `Improved feedback on warnings and errors when uploading personalisation CSVs`,
    ],
    fixes: [
      `Reviewers not being submitted correctly when using the submit action in the editor toolbar`,
      `Email domains with dash "-" are now considered valid`,
      `Emails with plus "+" are now now considered valid`,
    ],
  },
  {
    id: "1.2.1",
    type: "minor",
    date: new Date("2021-06-10"),
    features: [
      `Add support for BBC fields in personalisations`,
      `Add support for system-wide notification alerts`,
      `Add new changelog page`,
      `Mark users as inactive after 90 days (inactive users are hidden from reviewers list)`,
    ],
    changes: [`Show reviewer options in the reviewers dialog as a checkbox list`],
    fixes: [
      `Implement dynamic batching for repeated recipients and mailing lists to ensure they are delivered separately`,
      `Fix crash when previewing an email that contains a token with a space in the content body`,
    ],
  },
  {
    id: "1.1.8",
    type: "patch",
    date: new Date("2021-05-11"),
    features: [
      `Add ability to fully preview an email for a selected recipient`,
      `Add ability to add up to 10MB of attachments on an email`,
    ],
  },
  {
    id: "1.1.5",
    type: "patch",
    date: new Date("2021-05-05"),
    features: [`Add email delivery status page`, `Add admin logs page`],
  },
  {
    id: "1.1.0",
    type: "minor",
    date: new Date("2021-04-20"),
    features: [`Allow FROM field to be configured per email`],
    changes: [
      `Submit email for review using action button on toolbar`,
      `Restrict email approvals to users in approver groups`,
    ],
    fixes: [`Support CSV files that use "UTF-8 with BOM" encoding`],
  },
  {
    id: "1.0.1",
    type: "patch",
    date: new Date("2021-03-16"),
    features: [`Add custom REPLY TO addresses for each group`],
    changes: [`Restrict reviewers list to reviewers from the current user's group`],
    fixes: [
      `Allow CSV filenames to contain any character except for "/"`,
      `Fix profile not being created for new users`,
    ],
  },
  {
    id: "1.0.0",
    type: "major",
    date: new Date("2021-03-03"),
    features: [`Initial release`],
  },
];
