import { atom } from "recoil";

import { NavigationLocationType } from ".";

/**
 * This atom is used to keep track of the user's current position in
 * the application so that the appropriate link in the navigation
 * drawer can be highlighted.
 */
export const NavigationLocationState = atom<NavigationLocationType>({
  key: "NavigationLocationState",
  default: null,
});
