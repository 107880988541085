import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    error: {
      marginTop: theme.spacing(1),
    },
    table: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      background: theme.palette.background.default,
    },
    field: {
      width: theme.spacing(8),
    },
  })
);
