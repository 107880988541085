import { useRecoilValue } from "recoil";

import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import Tooltip from "@material-ui/core/Tooltip";

import { TextUtils } from "../../../utils";

import {
  PersonalisationsState,
  PersonalisationsStateType,
  PersonalisationsEventFilterState,
} from "../../../state/Personalisations";

import RecipientStatusList from "../../../components/status/RecipientStatusList";
import PersonalisationFilterField from "../../../components/editor/PersonalisationFilterField";
import PersonalisationEventFilterField from "../../../components/status/PersonalisationEventFilterField";
import EmailRecipientStatusDownloadButton from "../../../components/status/EmailRecipientStatusDownloadButton";

import useStyles from "./styles";

import { Email, EmailRecipient } from "../../../../../types";

export interface EmailRecipientStatusPanelProps {
  email?: Email;
  loading?: boolean;
  recipients?: EmailRecipient[];
}

const RecipientState = PersonalisationsState(PersonalisationsStateType.UPLOAD);
const EventFilterState = PersonalisationsEventFilterState(
  PersonalisationsStateType.UPLOAD
);

/**
 * Shows a list of recipients and their email status.
 */
export function EmailRecipientStatusPanel(props: EmailRecipientStatusPanelProps) {
  const classes = useStyles();
  const { email, loading, recipients = [] } = props;

  const shownRecipients = useRecoilValue(RecipientState);
  const eventFilter = useRecoilValue(EventFilterState);

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Fade in={loading}>
          <LinearProgress className={classes.loading} />
        </Fade>
        <div className={classes.heading}>
          <div className={classes.title}>
            <div>
              <Typography variant="h6" component="div">
                Recipient status
              </Typography>
              <Typography variant="body2" component="div" color="textSecondary">
                {eventFilter
                  ? `Showing ${
                      shownRecipients.length
                    } out of ${TextUtils.pluralWithCount(
                      recipients.length,
                      "recipient"
                    )}`
                  : `Showing all ${TextUtils.pluralWithCount(
                      recipients.length,
                      "recipient"
                    )}`}
              </Typography>
            </div>
          </div>
          <div className={classes.actions}>
            <PersonalisationFilterField
              className={classes.recipientFilter}
              stateKey={PersonalisationsStateType.UPLOAD}
              recipients={recipients}
              disabled={loading || recipients.length === 0}
              size="small"
              placeholder="Search for recipient..."
            />
            <Tooltip title="Filter recipients by status">
              <PersonalisationEventFilterField
                stateKey={PersonalisationsStateType.UPLOAD}
                disabled={loading || recipients.length === 0}
                reset={loading}
              />
            </Tooltip>
            <EmailRecipientStatusDownloadButton email={email} loading={loading} />
          </div>
        </div>
        <div className={classes.recipientList}>
          <RecipientStatusList
            stateKey={PersonalisationsStateType.UPLOAD}
            emailId={email?.id}
          />
        </div>
      </Paper>
    </Grid>
  );
}
