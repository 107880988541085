import { useEffect } from "react";
import clsx from "clsx";

import Button from "@material-ui/core/Button";

import { useSnack } from "../../../hooks/snack";

import useStyles from "./styles";

import { NotificationAlert } from "../../../../../types";

export interface LowPriorityAlertProps {
  /**
   * Whether to show the alert.
   */
  open?: boolean;

  /**
   * Callback for when the acknoledge button is clicked.
   */
  onClose?: (alert: NotificationAlert) => void;

  /**
   * The alert to show.
   */
  alert?: NotificationAlert;

  /**
   * Set this to `true` to prevent alert previews from intefering
   * with real alerts.
   */
  preview?: boolean;
}

/**
 * Show a low priority alert as a snackbar.
 */
export function LowPriorityAlert(props: LowPriorityAlertProps) {
  const { open = false, onClose, alert, preview } = props;

  const classes = useStyles();
  const { pushSnack, closeSnack } = useSnack();
  const previewId = preview ? "-preview" : "";

  // Create the snackbar object to show alert
  useEffect(() => {
    if (!alert) return;
    if (!open) {
      closeSnack(alert.id + previewId);
      return;
    }
    pushSnack(alert.message, {
      key: alert.id + previewId,
      anchorOrigin: {
        horizontal: "center",
        vertical: "top",
      },
      persist: true,
      preventDuplicate: true,
      variant: alert.variant,
      action: (
        <Button
          size="small"
          className={clsx(classes.acknowledge, {
            [classes.defaultAlert]: !alert.variant || alert.variant === "default",
            [classes.nonDefaultAlert]: alert.variant && alert.variant !== "default",
          })}
          onClick={() => {
            closeSnack(alert.id + previewId);
            onClose?.(alert);
          }}
        >
          <b>{alert.acknowledgeText || "CLOSE"}</b>
        </Button>
      ),
    });
  }, [alert, onClose, pushSnack, closeSnack, open, classes, previewId]);

  return null;
}
