import { useMemo } from "react";
import { useParams } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import LinearProgress from "@material-ui/core/LinearProgress";

import SendingIcon from "@material-ui/icons/Assignment";
import SentIcon from "@material-ui/icons/AssignmentTurnedIn";
import ErrorIcon from "@material-ui/icons/Warning";

import { useBoolean } from "../../../hooks/state";
import { useLatestEmailTasks } from "../../../hooks/data/email";

import EmailSendTasksDialog from "../../dialogs/EmailSendTasksDialog";

import { useActionStyles } from "../styles";
import useStyles from "./styles";

export interface EditorStatusSummaryActionProps {
  /**
   * Disable this action.
   */
  disabled?: boolean;
}

/**
 * This action shows the current progress of the email if it is being delivered.
 */
export const EditorStatusSummaryAction = ({
  disabled,
}: EditorStatusSummaryActionProps) => {
  const sharedClases = useActionStyles();
  const classes = useStyles();

  const { emailId } = useParams();
  const [tasks, loadingTasks] = useLatestEmailTasks(emailId, "send-emails");

  const [show, open, close] = useBoolean();

  const error = useMemo(() => tasks?.some((t) => t.status === "failed"), [tasks]);
  const sending = useMemo(
    () => !error && tasks?.some((t) => t.status === "in-progress"),
    [error, tasks]
  );
  const sent = !sending && !error;

  if (loadingTasks) return null;

  return (
    <>
      {sending && <LinearProgress className={classes.loading} />}
      {sending && (
        <Typography variant="body1" className={sharedClases.status}>
          SENDING
        </Typography>
      )}
      {sent && (
        <Typography variant="body1" className={sharedClases.status}>
          SENT
        </Typography>
      )}
      {error && (
        <Typography variant="body1" className={sharedClases.status}>
          <b>FAILED</b>
        </Typography>
      )}
      <Tooltip title="Email delivery summary" placement="bottom">
        <div className={classes.loadingButton}>
          <IconButton
            className={sharedClases.button}
            classes={{ disabled: sharedClases.disabled }}
            disabled={disabled}
            onClick={open}
          >
            {error ? <ErrorIcon /> : sending ? <SendingIcon /> : <SentIcon />}
          </IconButton>
        </div>
      </Tooltip>
      <EmailSendTasksDialog
        open={show}
        onClose={close}
        emailId={emailId}
        tasks={tasks}
      />
    </>
  );
};
