import { useMemo } from "react";

import { useCollectionData } from "../../hooks/firebase";
import { useLogError } from "../../hooks/log";

import { UserGroup } from "../../../../types";

/**
 * Fetch available user groups.
 */
export const useUserGroups = () => {
  const [data, loading, error] = useCollectionData<UserGroup>("userGroups");
  useLogError(`Error while retrieving user groups.`, "hook-useUserGroups", error);
  const groupedById = useMemo(() => {
    const grouped: Record<string, UserGroup> = {};
    if (!data) return grouped;
    return data.reduce((grouped, group) => {
      grouped[group.id] = group;
      return grouped;
    }, {} as Record<string, UserGroup>);
  }, [data]);
  return [groupedById, data, loading, error] as const;
};
