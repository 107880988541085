import { useCallback, useEffect, useMemo, useState } from "react";

import {
  useAcknowledgeAlert,
  useCreateAlertView,
} from "../../../hooks/actions/alert";
import { useNotificationAlertsForUser } from "../../../hooks/data/alerts";
import { useBooleanWithState } from "../../../hooks/state";

import HighPriorityAlert from "../../../components/alerts/HighPriorityAlert";
import LowPriorityAlert from "../../../components/alerts/LowPriorityAlert";

// import useStyles from "./styles";

import { NotificationAlert } from "../../../../../types";

export interface NotificationAlertManagerProps {}

/**
 * This component retrieves alert notifications that need to be
 * shown for the current user.
 */
export function NotificationAlertManager(props: NotificationAlertManagerProps) {
  // const classes = useStyles();

  const createAlert = useCreateAlertView();
  const acknowledgeAlert = useAcknowledgeAlert();
  const [alerts] = useNotificationAlertsForUser();

  const highAlerts = useMemo(
    () => alerts?.filter((a) => a.priority === "high") ?? [],
    [alerts]
  );
  const lowAlerts = useMemo(
    () => alerts?.filter((a) => a.priority === "low") ?? [],
    [alerts]
  );

  const [highAlert, showHighAlert, openAlert, closeAlert] = useBooleanWithState<
    NotificationAlert | undefined
  >(undefined, false);
  const [lowAlert, setLowAlert] = useState<NotificationAlert>();

  // If there is an unopened high priority alert, show it
  useEffect(() => {
    if (highAlerts.length === 0) return;
    if (highAlert?.id !== highAlerts[0].id) {
      openAlert(highAlerts[0]);
      createAlert(highAlerts[0].id);
    }
  }, [highAlerts, openAlert, createAlert, highAlert, closeAlert]);

  // If there is a low priority alert and there are no pending high priority alerts, show it
  useEffect(() => {
    if (highAlerts.length > 0) return;
    if (lowAlerts.length === 0) return;
    if (lowAlert?.id !== lowAlerts[0].id) {
      setLowAlert(lowAlerts[0]);
      createAlert(lowAlerts[0].id);
    }
  }, [highAlerts, lowAlert, lowAlerts, createAlert]);

  /**
   * Close and acknowledge the currently displayed high priority alert.
   */
  const closeAlertHandler = useCallback(
    (alert?: NotificationAlert) => {
      if (!alert) return;
      closeAlert();
      acknowledgeAlert(alert.id, "acknowledged");
    },
    [closeAlert, acknowledgeAlert]
  );

  return (
    <>
      <HighPriorityAlert
        open={showHighAlert}
        onClose={closeAlertHandler}
        alert={highAlert}
      />
      <LowPriorityAlert open onClose={closeAlertHandler} alert={lowAlert} />
    </>
  );
}
