import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    emptyState: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    image: {
      marginBottom: theme.spacing(4),
      height: "18rem",
      width: "auto",
      [theme.breakpoints.down("xs")]: {
        height: "10rem",
      },
    },
  })
);
