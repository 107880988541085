import { useState } from "react";
import { useRecoilValue } from "recoil";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";

import DownloadIcon from "@material-ui/icons/CloudDownload";

import { BatchUtils, CSVUtils, SendGridUtils } from "../../../utils";

import {
  PersonalisationsState,
  PersonalisationsStateType,
  PersonalisationsEventFilterState,
} from "../../../state/Personalisations";
import { useFirestore } from "../../../contexts/firebase/FirebaseContext";
import { useMenuAnchor } from "../../../hooks/state";

import useStyles from "./styles";

import { Email, WebhookEvent } from "../../../../../types";

export interface EmailRecipientStatusDownloadButtonProps {
  email?: Email;
  loading?: boolean;
}

/**
 * Shows a button that allows the user to download recipient status information as a CSV.
 */
export function EmailRecipientStatusDownloadButton(
  props: EmailRecipientStatusDownloadButtonProps
) {
  const classes = useStyles();
  const { email, loading } = props;

  const shownRecipients = useRecoilValue(
    PersonalisationsState(PersonalisationsStateType.UPLOAD)
  );
  const eventFilter = useRecoilValue(
    PersonalisationsEventFilterState(PersonalisationsStateType.UPLOAD)
  );

  const firestore = useFirestore();
  const [downloading, setDownloading] = useState(false);
  const [anchor, openMenu, closeMenu] = useMenuAnchor();
  const [progress, setProgress] = useState(0);

  async function createCSVDownload(options: { getEventData?: boolean } = {}) {
    if (!email) return;
    if (!firestore) return;

    // First map all recipients to their most relevant event for reporting on
    const basicRecipientEventData = shownRecipients.map(
      ({ id: recipientId, name, email, events = [] }) => {
        let status = "all";
        if (eventFilter === "deferred") status = "deferred";
        else if (events.includes("delivered")) status = "delivered";
        else if (events.includes("dropped")) status = "dropped";
        else if (events.includes("bounce")) status = "bounce";
        else if (events.includes("deferred")) status = "deferred";
        else if (events.includes("processed")) status = "processed";
        return { recipientId, name, email, status };
      }
    );

    // If we don't need to get additional event data, download the CSV and we're done
    if (!options.getEventData) {
      const csv = CSVUtils.objectsToCSV(
        eventFilter ? ["name", "email", "status"] : ["name", "email"],
        basicRecipientEventData.map((recipient) => {
          recipient.status = SendGridUtils.mapEventToLabel(recipient.status);
          return recipient;
        })
      );
      CSVUtils.downloadCSV(
        `${SendGridUtils.mapEventToLabel(eventFilter) || "recipients"}.csv`,
        csv
      );
      return;
    }

    try {
      let recipientEvents: {
        recipient: {
          recipientId: string;
          name: string;
          email: string;
          status: string;
        };
        events: WebhookEvent[];
      }[] = [];

      const ref = firestore.collection(`emails/${email.id}/events`);
      const batches = BatchUtils.split(basicRecipientEventData, 50);

      setDownloading(true);
      setProgress(1 / batches.length);

      // Fetch additional data in batches
      for (const batch of batches) {
        const data = await Promise.all(
          batch.map(async (recipient) => {
            const query = ref
              .where("recipientId", "==", recipient.recipientId)
              .where("event", "==", recipient.status)
              .orderBy("timestamp", "desc");
            const events = await query.get();
            return {
              recipient,
              events: events.docs.map((doc) => doc.data() as WebhookEvent),
            };
          })
        );
        recipientEvents = recipientEvents.concat(data);
        setProgress((progress) => progress + 1 / batches.length);
      }

      // Get more detail such as the date and errors from events
      const detailedRecipientEventData = recipientEvents.map(
        ({ recipient: { recipientId, name, email, status }, events }) => {
          const latest = events[0];

          const date: Date | null = latest?.timestamp
            ? new Date(latest.timestamp * 1000)
            : null;

          const timestampParts: string[] = [];
          if (date) {
            timestampParts.push(date.toLocaleDateString());
            timestampParts.push(date.toLocaleTimeString());
          }

          return {
            recipientId,
            name,
            email,
            status,
            timestamp: timestampParts.join(" "),
            count: events.length,
            details: latest?.reason ?? latest?.response ?? latest?.ip,
          };
        }
      );

      // Download CSV
      const csv = CSVUtils.objectsToCSV(
        ["recipientId", "timestamp", "name", "email", "status", "count", "details"],
        detailedRecipientEventData.map((recipient) => {
          recipient.status = SendGridUtils.mapEventToLabel(recipient.status);
          return recipient;
        })
      );
      CSVUtils.downloadCSV(
        `${SendGridUtils.mapEventToLabel(eventFilter) || "recipients"}.csv`,
        csv
      );
    } catch (error) {
      console.error(error);
    }

    setDownloading(false);
  }

  return (
    <>
      <Tooltip
        title={
          eventFilter
            ? shownRecipients.length === 0
              ? "No recipients to download"
              : "Download CSV of selected recipients"
            : "Download CSV of recipient statuses"
        }
      >
        <span className={classes.downloadButton}>
          <Fade in={downloading}>
            <CircularProgress
              className={classes.loading}
              variant="determinate"
              value={progress * 100}
            />
          </Fade>
          <IconButton
            disabled={loading || shownRecipients.length === 0 || downloading}
            onClick={openMenu}
          >
            <DownloadIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu}>
        <MenuItem
          onClick={() => {
            closeMenu();
            createCSVDownload();
          }}
        >
          <ListItemText
            primary={`Download${eventFilter ? " filtered " : " "}recipient emails`}
            secondary="Instant download"
          />
        </MenuItem>
        <MenuItem
          disabled={!eventFilter}
          onClick={() => {
            closeMenu();
            createCSVDownload({ getEventData: true });
          }}
        >
          <ListItemText
            primary={`Download${
              eventFilter ? " filtered " : " "
            }recipient emails and additional status information`}
            secondary={
              eventFilter
                ? "May take a few seconds, depending on number of recipients"
                : "Filter by status to select this option"
            }
          />
        </MenuItem>
      </Menu>
    </>
  );
}
