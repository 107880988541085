import { useCallback } from "react";

import { FirestoreUtils } from "../../utils";
import { useUploadBase64File, useDownloadBase64File } from "./base64File";

import { EmailAttachment } from "../../../../types";

/**
 * This hook creates a function that adds an attachment to the specified email.
 *
 * @param emailId The id of the email to add attachments to.
 */
export const useAddAttachment = (emailId?: string) => {
  const MAX_ATTACHMENT_SIZE = 10_000_000; // 10 MB
  const upload = useUploadBase64File({
    maxFileSize: MAX_ATTACHMENT_SIZE,
    maxTotalFileSize: MAX_ATTACHMENT_SIZE,
    namePlural: "attachments",
    decorateDocument: (doc) => ({
      ...doc,
      modified: FirestoreUtils.timestamp(),
    }),
  });

  return useCallback(
    async (file: File) => {
      if (!emailId) return;
      return upload(
        FirestoreUtils.createColRef<EmailAttachment>(
          `emails/${emailId}/attachments`
        ),
        file
      );
    },
    [upload, emailId]
  );
};

/**
 * This hook creates a function that can be used to remove an attachment from an email.
 *
 * @param emailId The id of the email to remove an attachment from.
 */
export const useRemoveAttachment = (emailId?: string) => {
  return useCallback(
    async (attachmentId: string) => {
      if (!emailId) return;
      const ref = FirestoreUtils.createDocRef<EmailAttachment>(
        `emails/${emailId}/attachments/${attachmentId}`
      );
      await ref.delete();
    },
    [emailId]
  );
};

/**
 * This hook creates a function that can be used to download an attachment.
 *
 * @param emailId The id of the email to download attachments from.
 */
export const useDownloadAttachment = (emailId?: string) => {
  const download = useDownloadBase64File("attachment");
  return useCallback(
    async (attachmentId: string) => {
      if (!emailId) return;
      if (!attachmentId) return;
      return download(
        FirestoreUtils.createDocRef<EmailAttachment>(
          `emails/${emailId}/attachments/${attachmentId}`
        )
      );
    },
    [download, emailId]
  );
};
