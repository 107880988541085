import useStyles from "./styles";

export interface HorizontalScrollerProps {
  children?: React.ReactNode;

  /**
   * The width of the scroll fade on the right in pixels.
   *
   * @default 65
   */
  fadeWidth?: number;
}

/**
 * Creates a scrollable container to hold the children in,
 * with a gradient fade on the right to indicate that the
 * contents are scrollable.
 *
 * Control the width of the fade using the `fadeWidth` prop.
 */
export const HorizontalScroller = (props: HorizontalScrollerProps) => {
  const classes = useStyles(props);
  const { children } = props;
  return (
    <div className={classes.container}>
      <div className={classes.scroller}>
        <div className={classes.content}>
          {children}
          <div className={classes.spacer}></div>
        </div>
      </div>
    </div>
  );
};
