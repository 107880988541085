import { useDropzone, DropzoneOptions } from "react-dropzone";
import clsx from "clsx";

import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";

export interface DropzoneProps extends DropzoneOptions {
  className?: string;
  children?: React.ReactNode;

  /**
   * Pass in an array of the files to use the dropzone as
   * a controlled component.
   */
  files?: File[];

  /**
   * Use this to customise the file name used in the dropzone message.
   */
  fileTypeName?: string;

  /**
   * Customise the message shown when the dropzone is empty.
   */
  emptyText?: string;

  /**
   * Customise the message shown when the dropzone is active.
   */
  emptyActiveText?: string;

  /**
   * Customise the message shown when the dropzone is rejecting the dragged file.
   */
  emptyRejectText?: string;

  /**
   * Customise the message shown when the dropzone has a file.
   * By default, the file name is shown.
   */
  nonEmptyText?: string;

  /**
   * If `true`, non-empty text will never be shown.
   */
  disableNonEmptyText?: boolean;
}

/**
 * A dropzone component, built off `react-dropzone`. By default, the
 * dropzone will always allow items to be dropped into it. To stop
 * items from being dropped on it, use the `disabled` prop.
 *
 * Children to this component will be rendered when there is at least
 * one file that has been successfully dropped in the dropzone.
 */
export const Dropzone = ({
  className,
  fileTypeName = "file",
  emptyText = `Drag ${fileTypeName} here or click to select a file.`,
  emptyActiveText = `Drop ${fileTypeName} here`,
  emptyRejectText = "File type not accepted",
  nonEmptyText,
  disableNonEmptyText,
  files,
  children,
  ...dropzoneProps
}: DropzoneProps) => {
  const classes = useStyles();
  const { disabled } = dropzoneProps;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone(dropzoneProps);

  // Check the given files prop if this is a controlled component.
  // Otherwise, use the internal `useDropzone` hook's files.
  const filesToCheck = files || acceptedFiles;

  // By default, show an empty state.
  let content = (
    <Typography variant="body2" color="textSecondary">
      {emptyText}
    </Typography>
  );

  // Show caption if the user is dragging an item.
  if (isDragActive) {
    content = (
      <Typography variant="body2" color="textSecondary">
        <b>{isDragAccept ? emptyActiveText : emptyRejectText}</b>
      </Typography>
    );
  }

  // If this component has children and the dropzone has files
  // stored in it, render the children.
  const showChildren = filesToCheck.length > 0;

  if (showChildren) {
    if (children) {
      content = <>{children}</>;
    } else if (nonEmptyText && !disableNonEmptyText) {
      content = (
        <Typography variant="body2" color="textSecondary">
          {nonEmptyText}
        </Typography>
      );
    } else if (!disableNonEmptyText) {
      content = (
        <>
          {filesToCheck.map((file) => (
            <Typography
              key={file.name}
              variant="body2"
              color="textSecondary"
              component="div"
            >
              {file.name}
            </Typography>
          ))}
        </>
      );
    }
  }

  return (
    <div
      {...getRootProps()}
      className={clsx(className, {
        [classes.dropzone]: !disabled,
        [classes.empty]: (!disabled && !showChildren) || !Boolean(children),
        [classes.enabled]: !disabled && !isDragActive,
        [classes.dragActive]: !disabled && isDragActive && !isDragReject,
        [classes.dragReject]: !disabled && isDragReject,
      })}
    >
      <input data-testid="upload" {...getInputProps()} />
      {content}
    </div>
  );
};
