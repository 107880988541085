import { EditorState } from "draft-js";
import { useRecoilValue } from "recoil";
import linkifyIt from "linkify-it";
import tlds from "tlds";

import Popover from "@material-ui/core/Popover";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import { DraftEntityUtils } from "../../../../utils";

import {
  DraftJsState,
  DraftJsCurrentEntity,
  DraftJsCurrentEntityKey,
} from "../../../../state/DraftJsEditor";

import { useInputField } from "../../../../hooks/state";

import { useLinkPopoverStyles } from "../styles";

const linkify = linkifyIt();
linkify.tlds(tlds);

export interface EditorToolbarButtonLinkPopoverProps {
  /**
   * The key the rich text content is stored in in the Recoil state.
   */
  contentKey: string;

  /**
   * The anchor at which to show the popover.
   */
  anchor?: HTMLElement | null;

  /**
   * Callback to set the editor state.
   */
  setEditorState?: (state: EditorState) => void;

  /**
   * Callback to close the popover.
   */
  onClose?: () => void;
}

/**
 * This popover shows an input field for the user to enter in a URL to create a link.
 */
export const EditorToolbarButtonLinkPopover = ({
  contentKey,
  anchor,
  setEditorState,
  onClose,
}: EditorToolbarButtonLinkPopoverProps) => {
  const classes = useLinkPopoverStyles();

  const editorState = useRecoilValue(DraftJsState(contentKey));
  const currentEntity = useRecoilValue(DraftJsCurrentEntity(contentKey));
  const currentEntityKey = useRecoilValue(DraftJsCurrentEntityKey(contentKey));

  const [link, setLink, linkError, setLinkError] = useInputField<string>(
    currentEntity?.getType() === "LINK" ? currentEntity?.getData()?.link ?? "" : "",
    Boolean(anchor)
  );

  /**
   * Callback for validating and saving a link entity.
   */
  const onConfirmHandler = () => {
    let sanitisedLink = link.trim();

    if (!linkify.test(sanitisedLink)) return setLinkError("Invalid URL.");

    // Auto-append a protocol
    if (!/^(https?:|mailto:)/.test(sanitisedLink)) {
      if (sanitisedLink.includes("@")) sanitisedLink = `mailto:${sanitisedLink}`;
      else sanitisedLink = `https://${sanitisedLink}`;
    }

    const newEditorState = DraftEntityUtils.createLinkEntity(
      editorState,
      sanitisedLink
    );
    setEditorState?.(newEditorState);
    onClose?.();
  };

  /**
   * Callback for clearing a link entity.
   */
  const onClearHandler = () => {
    if (!currentEntityKey) return;
    const newEditorState = DraftEntityUtils.removeEntity(
      editorState,
      currentEntityKey
    );
    if (newEditorState) setEditorState?.(newEditorState);
    onClose?.();
  };

  return (
    <Popover
      classes={{
        paper: classes.paper,
      }}
      open={Boolean(anchor)}
      onClose={onClose}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <div className={classes.content}>
        <TextField
          label="Link URL"
          InputLabelProps={{ shrink: true }}
          placeholder="Enter link here..."
          fullWidth
          value={link}
          onChange={(e) => setLink(e.target.value)}
          error={Boolean(linkError)}
          helperText={linkError}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              onConfirmHandler();
            }
          }}
        />
      </div>
      <div className={classes.actions}>
        {currentEntity ? <Button onClick={onClearHandler}>CLEAR</Button> : <div />}
        <Button onClick={onConfirmHandler}>CONFIRM</Button>
      </div>
    </Popover>
  );
};
