import { useEffect, useState } from "react";
import { useLocation, Outlet, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

import { CLogger, SignInUtils } from "../../../utils";

import {
  AuthLoadingState,
  AuthenticationState,
  SessionState,
} from "../../../state/FirebaseAuthState";

import { useAuth } from "../../../contexts/firebase/FirebaseContext";

import Loading from "../../../components/core/Loading";
import AppShell from "../../../components/containers/AppShell";

import SignInPage from "../../../pages/core/SignInPage";

export interface SecureProps {}

const log = CLogger.category("components.core.Secure");

/**
 * This component only renders child routes if the user is
 * authenticated. Otherwise, it will render the sign in page
 * with a "will redirect" indicator.
 */
export const Secure = (props: SecureProps) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [signingIn, setSigningIn] = useState(false);
  const [session, setSession] = useRecoilState(SessionState);
  const [loading, setLoading] = useRecoilState(AuthLoadingState);
  const [user, setUser] = useRecoilState(AuthenticationState);

  // Check whether the user is signed with an Okta session
  useEffect(() => {
    if (!auth) return;

    // Check for a user session
    fetch("/api/user", { method: "GET" }).then(async (res) => {
      if (res.ok) {
        const session = await res.json();
        setSession(session);

        // Check for redirect
        const path = SignInUtils.getRedirectPath(true);
        if (path?.startsWith("/")) navigate(path);
      } else {
        // If it was a forbidden error, show the message.
        if (res.status === 403) {
          const error = await res.json();
          log({ level: "error", message: error.error });
          navigate(`?errors=Forbidden,${error.error}`);
        } else {
          // Ensure user is signed out if there is no session
          log({ level: "debug", message: "No user session." });
          if (process.env.NODE_ENV !== "development") auth.signOut();
        }
      }
    });
  }, [auth, navigate, setSession]);

  // If the user has a session, sign in through Firebase
  useEffect(() => {
    if (!auth) return;
    if (!session) return;

    // Don't trigger sign in again if the user is already signed in
    if (user) return;

    setSigningIn(true);
    log({ level: "debug", message: "Token exchange initiated." });

    // Fetch Firebase token
    fetch(`/api/token`, { method: "POST" }).then(async (res) => {
      if (!res.ok) {
        log({
          level: "error",
          message: `Token exchanged failed.`,
          data: res.statusText,
        });
        setSigningIn(false);
        return;
      }

      // Extract token
      const data = await res.json();
      if (!data?.token) {
        log({
          level: "error",
          message: `Token exchanged failed.`,
          data: `Missing token`,
        });
        setSigningIn(false);
        return;
      }

      // Sign in with custom Firebase token
      log({ level: "debug", message: `Signing in...` });
      await auth.signInWithCustomToken(data.token);

      log({
        level: "debug",
        message: "Signed in sucessfully.",
      });
    });
  }, [auth, session, user]);

  // Listen to Firebase auth state
  useEffect(() => {
    auth?.onAuthStateChanged(async (user) => {
      if (user) {
        // Whenever this effect runs, make sure we refresh the user's
        // id token to retrieve the most up to date claims
        const { claims } = await user.getIdTokenResult(true);
        setUser({
          uid: user.uid,
          email: claims.email,
          displayName: claims.displayName,
          monashObjectId: claims.monashObjectId,
          groups: claims.groups ?? [],
        });
        log({ level: "debug", message: `Signed in ${claims.email}` });
        setSigningIn(false);
      } else {
        setUser(null);
      }
      setLoading(false);
    });
  }, [auth, session, setLoading, setUser]);

  if (loading) return <Loading full message="Loading application..." />;
  if (signingIn) return <Loading full message="Signing in..." />;

  if (!session || !user) return <SignInPage showLoginMessasge={pathname !== "/"} />;

  return (
    <AppShell>
      <Outlet />
    </AppShell>
  );
};
