import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { matchSorter } from "match-sorter";

import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";

import ClearIcon from "@material-ui/icons/Clear";

import {
  PersonalisationsEventFilterState,
  PersonalisationsFilterState,
  PersonalisationsState,
  PersonalisationsStateType,
} from "../../../state/Personalisations";

import { useDebounced } from "../../../hooks/state";

import useStyles from "./styles";

import { EmailRecipient } from "../../../../../types";

export interface PersonalisationFilterFieldProps
  extends Pick<TextFieldProps, "className" | "placeholder" | "size" | "fullWidth"> {
  /**
   * The key of the Recoil state the personalisation data to filter is stored in.
   */
  stateKey: PersonalisationsStateType;

  /**
   * Set this value to `true` to reset the value inside the filter field.
   */
  reset?: boolean;

  /**
   * if you want to disable the field, set the value to true
   */
  disabled?: boolean;

  /**
   * All recipients that can be shown.
   */
  recipients?: EmailRecipient[];

  /**
   * The function used to decide whether a recipient should be shown
   * based on the current filter value.
   */
  filterFn?: (recipient: EmailRecipient, filter: string) => boolean;
}

/**
 * This field can be used to filter the values stored in the
 * `Personalisations` Recoil state atom family.
 *
 * When the filter field changes, the values stored in the `Personalisations`
 * atom specified by `stateKey` will be updated accordingly using the given
 * filter function.
 *
 * When the filter value is empty, all recipients are shown.
 *
 * If no filter function is given, a basic string match on the recipient's
 * name and email is used.
 */
export const PersonalisationFilterField = ({
  stateKey,
  reset,
  recipients,
  filterFn,
  disabled,
  ...textFieldProps
}: PersonalisationFilterFieldProps) => {
  const classes = useStyles();

  const [filter, setFilter] = useRecoilState(PersonalisationsFilterState(stateKey));
  const setPersonalisations = useSetRecoilState(PersonalisationsState(stateKey));
  const eventFilter = useRecoilValue(PersonalisationsEventFilterState(stateKey));

  // Debounce the filter value to prevent UI from lagging on every keystroke
  const debouncedFilter = useDebounced(filter);

  // Filter recipients when debounced value is updated
  useEffect(() => {
    // If a custom filter has been provided, use it to filter the recipients
    const filteredRecipients =
      (filterFn
        ? recipients?.filter((r) => filterFn(r, debouncedFilter))
        : recipients) ?? [];

    // Sort the recipients based on the filter
    const results = matchSorter(filteredRecipients, debouncedFilter, {
      keys: ["name", "email"],
    });

    // Set filtered recipients
    if (eventFilter) {
      setPersonalisations(results.filter((r) => r?.events?.includes(eventFilter)));
    } else {
      setPersonalisations(results);
    }
  }, [debouncedFilter, filterFn, recipients, eventFilter, setPersonalisations]);

  // Reset filter when the reset flag is changed to true
  useEffect(() => {
    if (reset) setFilter("");
  }, [reset, setFilter]);

  return (
    <TextField
      variant="outlined"
      disabled={disabled}
      InputProps={{
        classes: {
          adornedEnd: classes.adornedEnd,
        },
        endAdornment: filter && (
          <IconButton size="small" onClick={() => setFilter("")}>
            <ClearIcon />
          </IconButton>
        ),
      }}
      value={filter}
      onChange={(e) => setFilter(e.target.value)}
      {...textFieldProps}
    />
  );
};
