import { atom } from "recoil";

/**
 * This atom keeps track whether the auth state is loading.
 */
export const AuthLoadingState = atom<boolean>({
  key: "AuthLoadingState",
  default: true,
});

/**
 * This atom keeps track of the current user's session.
 */
export const SessionState = atom<object | null>({
  key: "SessionState",
  default: null,
});

export interface UserInfo {
  uid: string;
  email?: string;
  displayName?: string;
  monashObjectId?: string;
  groups?: string[];
}

/**
 * This atom keeps track of the user's authentication state.
 */
export const AuthenticationState = atom<UserInfo | null>({
  key: "AuthenticationState",
  default: null,
});
