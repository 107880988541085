import clsx from "clsx";

import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";

export interface LoadingTextProps {
  className?: string;
  children?: React.ReactNode;

  /**
   * The type of progress to show.
   */
  type?: "circular" | "linear";

  /**
   * The progress to show.
   */
  progress?: number;

  /**
   * Do not render children within a `<Typography/>` component.
   */
  disableTypography?: boolean;
}

/**
 * Show a progress element with some text below.
 */
export const LoadingText = ({
  className,
  type = "circular",
  progress,
  disableTypography,
  children,
}: LoadingTextProps) => {
  const classes = useStyles();
  const variant = typeof progress === "number" ? "determinate" : "indeterminate";
  return (
    <div className={clsx(classes.loading, className)}>
      {type === "circular" ? (
        <CircularProgress
          className={classes.circular}
          size={30}
          variant={variant}
          value={progress}
        />
      ) : (
        <LinearProgress
          className={classes.linear}
          variant={variant}
          value={progress}
        />
      )}
      {disableTypography ? (
        children
      ) : (
        <Typography variant="body2" color="textSecondary" component="div">
          {children}
        </Typography>
      )}
    </div>
  );
};
