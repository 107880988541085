import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      position: "relative",
      height: "100%",
    },
    loading: {
      position: "absolute",
      width: "100%",
      left: 0,
      top: 0,
      borderRadius: theme.shape.borderRadius,
    },
    empty: {
      paddingTop: theme.spacing(1),
    },
    statusContainer: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  })
);
