import clsx from "clsx";

import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import { SvgIconProps } from "@material-ui/core/SvgIcon";

import useStyles from "./styles";

export interface PageMessageProps {
  className?: string;
  children?: React.ReactNode;

  /**
   * The title of the page message.
   */
  title: string;

  /**
   * If `true`, the title size will be bigger.
   */
  largeTitle?: boolean;

  /**
   * An optional SVG icon to render in the title.
   */
  icon?: React.ComponentType<SvgIconProps>;

  /**
   * Set the color of the icon.
   *
   * @default "action"
   */
  iconColor?: "action" | "disabled" | "warning" | "error";

  /**
   * An action that will be rendered at the bottom of the message
   * if given. This will typically be a button.
   */
  action?: React.ReactNode;
}

/**
 * Render a message with a title and divider. Pass the message body
 * as children. Use the `action` prop to append an action for the user
 * to take, which will be rendered after the message body.
 */
export const PageMessage = ({
  className,
  title,
  largeTitle,
  icon: Icon,
  iconColor = "action",
  children,
  action,
}: PageMessageProps) => {
  const classes = useStyles();
  return (
    <div className={className}>
      <div className={classes.title}>
        {Icon && (
          <Icon
            className={clsx({
              [classes.icon]: !Boolean(largeTitle),
              [classes.iconLarge]: Boolean(largeTitle),
              [classes.warning]: iconColor === "warning",
            })}
            color={iconColor === "warning" ? "inherit" : iconColor}
            fontSize="large"
          />
        )}
        <Typography variant={largeTitle ? "h4" : "h5"}>{title}</Typography>
      </div>
      <Divider className={classes.divider} />
      {children}
      {action && <div className={classes.gap} />}
      {action}
    </div>
  );
};
