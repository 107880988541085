import { Helmet } from "react-helmet-async";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import WarningIcon from "@material-ui/icons/Warning";

import HorizontalScroller from "../../../components/core/HorizontalScroller";
import PageMessage from "../../../components/core/PageMessage";

import useStyles from "./styles";

export interface CrashPageProps {
  /**
   * The error that caused the crash.
   */
  error?: Error;

  /**
   * The crash page title.
   *
   * @default "Application crashed"
   */
  title?: string;

  /**
   * The crash page message.
   *
   * @default "Apologies for the inconvenience. Please try refreshing the page."
   */
  message?: string;
}

/**
 * Displays an error message and a stack trace if an `error` is provided.
 */
export const CrashPage = ({
  error,
  title = "Application crashed",
  message = "Apologies for the inconvenience. Please try refreshing the page.",
}: CrashPageProps) => {
  const classes = useStyles();
  return (
    <Container maxWidth="sm" className={classes.container}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageMessage
        title={title}
        icon={WarningIcon}
        action={
          <Button variant="outlined" onClick={() => window.location.reload()}>
            Refresh
          </Button>
        }
      >
        <Typography variant="body1">{message}</Typography>
      </PageMessage>
      {error && (
        <div className={classes.error}>
          {error.stack ? (
            <HorizontalScroller>
              <Typography variant="body2" color="textSecondary" component="pre">
                {error.stack}
              </Typography>
            </HorizontalScroller>
          ) : (
            <Typography variant="body2" color="textSecondary">
              <b>{error.name}: </b>
              {error.message}
            </Typography>
          )}
        </div>
      )}
    </Container>
  );
};
