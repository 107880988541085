import { Component, ErrorInfo } from "react";

import { CLogger } from "../../../utils";

export interface ErrorBoundaryProps {
  /**
   * Callback that should return a component to render if there is an error.
   */
  onError?: (error: Error) => React.ReactNode;
}

export interface ErrorBoundaryState {
  /**
   * The error that was caught by the error boundary.
   */
  error?: Error;
}

const log = CLogger.category("components.core.ErrorBoundary");

/**
 * This component can capture errors that occur within its children
 * and calls the `onError` callback with the error.
 *
 * An error page can be displayed if `onError` returns a component.
 */
export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {
    error: undefined,
  };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    log({
      level: "error",
      message: `Caught unexpected error: ${error.message}`,
      data: { error, info },
    });
  }

  render() {
    const { error } = this.state;
    const { children, onError } = this.props;

    if (!error) return children;
    if (!onError) return null;

    return onError(error);
  }
}
