import { nanoid } from "nanoid";

import { LoggerUtil } from "./LoggerUtil";

export const CLogger = new LoggerUtil({
  source: "frontend",
  group: true,
  logLevels: ["debug", "info", "warn", "error"],
});

export const id = () => nanoid();

export * from "./BatchUtils";
export * from "./CSVUtils";
export * from "./DateUtils";
export * from "./DraftEntityUtils";
export * from "./DraftUtils";
export * from "./EmailUtils";
export * from "./EnvUtils";
export * from "./FileUtils";
export * from "./FirestoreUtils";
export * from "./TextUtils";
export * from "./SendGridUtils";
export * from "./SharedUtils";
export * from "./SignInUtils";
export * from "./TokenUtils";
export * from "./TagUtils";
export * from "./Utf8Utils";
