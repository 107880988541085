import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    title: {
      display: "flex",
      alignItems: "center",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    iconLarge: {
      marginRight: theme.spacing(2),
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    gap: {
      paddingTop: theme.spacing(3),
    },
    warning: {
      color: theme.palette.warning.main,
    },
  })
);
