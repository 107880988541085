import loadable from "@loadable/component";
import LinearProgress from "@material-ui/core/LinearProgress";

/**
 * Dynamically loaded version of `SystemAlertsPage`.
 */
const LoadablePage = loadable(
  () => import(/* webpackChunkName: "SystemAlerts" */ "."),
  {
    fallback: <LinearProgress />,
  }
);

export default LoadablePage;
