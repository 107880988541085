import { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { TriggerState, TriggerType } from "./atoms";

/**
 * Watch a trigger value for updates.
 *
 * @param trigger The trigger type.
 */
export const useTrigger = (trigger: TriggerType) => {
  return useRecoilValue(TriggerState(trigger));
};

/**
 * Update a trigger value.
 *
 * @param trigger The trigger type.
 */
export const useIncrementTrigger = (trigger: TriggerType) => {
  const setTrigger = useSetRecoilState(TriggerState(trigger));
  return useCallback(() => {
    setTrigger((x) => x + 1);
  }, [setTrigger]);
};
