import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
    },
    tag: {
      borderRadius: 20,
      paddingTop: theme.spacing(0.75),
      paddingBottom: theme.spacing(0.75),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginRight: theme.spacing(2),
      color: "white",
      fontWeight: "bold",
      textTransform: "uppercase",
      lineHeight: "unset",
    },
    listItem: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
    listItemText: {
      display: "flex",
      alignItems: "baseline",
    },
    feature: {
      background: "#33a234",
    },
    change: {
      background: "#f6b100",
    },
    fix: {
      background: "#4285f4",
    },
  })
);
