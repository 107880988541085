import { useEffect, useState } from "react";

import { SystemAlertIdentifier } from "../../../constants";

import {
  useAcknowledgeAlert,
  useCreateAlertView,
} from "../../../hooks/actions/alert";
import { useSystemAlertsForUser } from "../../../hooks/data/alerts";

import { TOS2021MAY } from "./alerts/TOS2021MAY";

// import useStyles from "./styles";

import { SystemAlert } from "../../../../../types";

export interface SystemAlertManagerProps {
  /**
   * The list of alerts to render.
   */
  alerts?: SystemAlert[];
}

/**
 * System alerts are hard-coded alerts that must be shown
 * to the user when they use the system.
 */
export function SystemAlertManager(props: SystemAlertManagerProps) {
  // const classes = useStyles();
  const { alerts } = props;

  const createAlert = useCreateAlertView();
  const acknowledgeAlert = useAcknowledgeAlert();
  const [pendingAlerts] = useSystemAlertsForUser(alerts);

  const [open, setOpen] = useState<Set<string>>(new Set());
  const [interacted, setInteracted] = useState<Set<string>>(new Set());

  // When the system alerts load, keep track of which alerts
  // should be open and which ones require interaction
  useEffect(() => {
    if (!pendingAlerts) return;
    Object.keys(pendingAlerts).forEach(async (key) => {
      const alert = pendingAlerts[key];
      createAlert(alert.id);
    });
    setOpen(new Set(Object.keys(pendingAlerts)));
  }, [pendingAlerts, createAlert]);

  /**
   * Create a callback function that records an interaction
   * response with an alert.
   *
   * @param id The id of the alert.
   */
  const onCloseHandler = (id: string) => (response: string) => {
    setInteracted((set) => {
      const newSet = new Set(set);
      newSet.add(id);
      return newSet;
    });
    acknowledgeAlert(id, response);
  };

  /**
   * Show an alert if it should be open and it has not been interacted with.
   */
  const shouldOpen = (key: string) => open.has(key) && !interacted.has(key);

  return (
    <>
      <TOS2021MAY
        open={shouldOpen(SystemAlertIdentifier.TOS_2021_MAY)}
        onClose={onCloseHandler(SystemAlertIdentifier.TOS_2021_MAY)}
      />
    </>
  );
}
