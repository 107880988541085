import { useMemo } from "react";
import clsx from "clsx";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import CustomEditor from "ckeditor5-custom-build";

import { CLogger, TokenUtils } from "../../../utils";

import { ConfigBuilder, EditorType } from "./ConfigBuilder";

import "./css/reset.css";
import "./css/theme.css";

// These styles should be directly added to the template in SendGrid
// import "./css/injected.css";

export interface CKEditor5Props {
  /**
   * The initial content to load into the editor state.
   */
  initialContent?: any;

  /**
   * The initial set of tokens the content is allowed to contain.
   */
  initialTokens?: string[];

  /**
   * Callback function to save the editor state to persistent storage.
   * WARNING: The provided function should be debounced.
   */
  onSaveContent?: (rawState: string, plainText: string) => void;

  /**
   * If `true`, the editor will remove all styling.
   */
  // plainText?: boolean;

  /**
   * If `true`, the editor will only allow text on a single line.
   */
  // singleLine?: boolean;

  /**
   * Show the editor in read-only mode.
   */
  readOnly?: boolean;

  /**
   * A function used to fetch personalisation tokens from
   * the currently active email.
   */
  getTokens?: (query: string) => Promise<string[]>;
}

const log = CLogger.category("components.editor.CKEditor5");

/**
 * Renders a customised rich text editing area using `CKEditor5`.
 */
export function CKEditor5(props: CKEditor5Props) {
  const { initialContent, initialTokens, onSaveContent, readOnly, getTokens } =
    props;

  // const classes = useStyles();

  // Create the config for the editor
  const config = useMemo(() => {
    return new ConfigBuilder()
      .configure(EditorType.RICH_TEXT)
      .addMentionFeed({ feed: getTokens, marker: "{" })
      .build();
  }, [getTokens]);

  // Process the initial data for the editor
  const initialData = useMemo(() => {
    if (!initialContent || !initialTokens) return "";

    let processedContent = initialContent;

    if (typeof initialContent === "object" && "blocks" in initialContent) {
      // This is likely a legacy DraftJS content state - convert to HTML string
      log({ level: "debug", message: "Converting legacy state to HTML string" });
      processedContent = stateToHTML(convertFromRaw(initialContent));
    }

    // Process HTML
    const regex = TokenUtils.buildRegex();
    const tokenMap = TokenUtils.extractTokens(regex, initialContent);

    // Remove all unrecognised tokens
    processedContent = TokenUtils.removeTokenMarkup(
      Object.keys(tokenMap).reduce((mapping, key) => {
        if (!initialTokens.includes(key)) mapping[key] = tokenMap[key];
        return mapping;
      }, {} as typeof tokenMap),
      processedContent
    );

    // Add new tokens
    processedContent = TokenUtils.injectTokenMarkup(
      initialTokens.filter((token) => !(token in tokenMap)),
      processedContent
    );

    return processedContent;
  }, [initialContent, initialTokens]);

  if (!getTokens) return null;

  return (
    <div className={clsx("msend-ckeditor", { hidden: readOnly })}>
      <CKEditor
        editor={CustomEditor}
        disabled={readOnly}
        data={initialData}
        config={config}
        onReady={(editor) => {
          editor?.focus();
        }}
        onChange={(event, editor) => {
          if (readOnly) return;

          // Get HTML from editor state
          const contentHtml = editor.getData();

          // Only save if html is different
          if (contentHtml === initialContent) return;

          // Convert data into plaintext for preview
          const container = document.createElement("div");
          container.innerHTML = contentHtml;
          const plaintext = container.textContent || "";

          // Save content
          onSaveContent?.(contentHtml, plaintext);
        }}
      />
    </div>
  );
}
