import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    item: {
      textTransform: "none",
      textAlign: "left",
      paddingLeft: theme.spacing(2),
      width: "100%",
      minWidth: 500,
    },
    action: {
      minWidth: "40%",
    },
    stepper: {
      padding: 0,
      minWidth: "50%",
      backgroundColor: "transparent",
      pointerEvents: "none",
    },
    label: {
      marginTop: `4px !important`,
    },
  })
);

export const stepIconStyles = makeStyles<Theme>((theme) => ({
  root: {
    color: theme.palette.grey[300],
    display: "flex",
    height: 24,
    alignItems: "center",
  },
  base: {
    zIndex: 1,
    fontSize: 18,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  success: {
    color: theme.palette.success.main,
  },
  wait: {
    color: theme.palette.grey[500],
  },
  error: {
    color: theme.palette.error.main,
  },
}));
