import { Link } from "react-router-dom";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import StatusIcon from "@material-ui/icons/Assessment";

export interface EditorViewStatusActionProps {
  /**
   * The id of the email to preview.
   */
  emailId?: string;

  /**
   * Disables the action button.
   */
  disabled?: boolean;
}

/**
 * This action redirects the user to the email status page.
 */
export const EditorViewStatusAction = ({
  emailId,
  disabled,
}: EditorViewStatusActionProps) => {
  return (
    <>
      <Tooltip title="View status" placement="left">
        <div>
          <IconButton
            component={Link}
            to={`/emails/view/${emailId}/status`}
            disabled={disabled}
          >
            <StatusIcon />
          </IconButton>
        </div>
      </Tooltip>
    </>
  );
};
