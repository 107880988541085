import { useEmailData } from "./utils";

import { EmailAttachment } from "../../../../../types";

/**
 * Retrieve the attachments for the specified email.
 *
 * @param emailId The id of the email to retrieve attachments for.
 */
export const useEmailAttachments = (emailId?: string) =>
  useEmailData<EmailAttachment>(emailId, "attachments");
