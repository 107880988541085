import React from "react";
import ReactDOM from "react-dom";
import moment from "moment/moment";

import App from "./App";

import "./styles.css";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// Configure moment.js
moment.updateLocale("en", {
  relativeTime: {
    m: "a min",
    mm: "%d mins",
    s: "just now",
    past: (relTime: string) => {
      if (relTime === "just now") return relTime;
      return `${relTime} ago`;
    },
  },
});
