import { useCallback } from "react";

import {
  ColRef,
  DocRef,
  Base64FileUtils,
  FirestoreUtils,
  TextUtils,
  ReadBase64FileOptions,
} from "../../utils";

import { Base64EncodedFile, Base64EncodedFilePart } from "../../../../types";

// See https://firebase.google.com/docs/firestore/quotas#collections_documents_and_fields
const FIELD_SIZE_LIMIT = 1048000; // Leave some buffer

interface Base64UploadFileOptions<T> extends ReadBase64FileOptions {
  /**
   * The maximum permitted file size. Not checked if this value is not provided.
   */
  maxFileSize?: number;

  /**
   * The maximum permitted total file size (caculated by summing the size of all files in the collection). Not checked if this value is not provided.
   */
  maxTotalFileSize?: number;

  /**
   * A function used to add additional data to the document before being saved.
   */
  decorateDocument?: (doc: Base64EncodedFile) => T;

  /**
   * The plural form of the name to use for the file type (for logging purposes).
   */
  namePlural?: string;
}

/**
 * Returns a function which can be used to upload a file encoded as
 * Base64 string parts to the given collection.
 *
 * @param ref The Firestore collection reference to add the file to.
 * @param options Upload options.
 */
export const useUploadBase64File = <T extends Base64EncodedFile>(
  options?: Base64UploadFileOptions<T>
) => {
  const {
    maxFileSize,
    maxTotalFileSize,
    decorateDocument = (x: Base64EncodedFile) => x,
    namePlural = "files",
    preservePrefix,
    removeBOM,
  } = options ?? {};

  return useCallback(
    async (ref: ColRef<T>, file: File) => {
      if (!ref) return;

      // Check attachment size
      if (maxFileSize && file.size > maxFileSize)
        throw new Error(
          `${TextUtils.capitalise(
            namePlural
          )} cannot be larger than ${TextUtils.dataSize(maxFileSize ?? 0)} - ${
            file.name
          }`
        );

      // Get existing files
      const existing = await ref.get();

      // Check for files with same name
      const { docs } = await ref.where("name", "==", file.name).get();
      const existingDoc = docs[0];

      // Ensure files do not exceed max total size
      if (maxTotalFileSize) {
        const size = existing.docs.reduce(
          (size, doc) => (doc.data()?.size ?? 0) + size,
          0
        );
        const newSize = size + file.size - (existingDoc?.data()?.size ?? 0);
        if (newSize > maxTotalFileSize)
          throw new Error(
            `Cannot add ${
              file.name
            } as it would cause ${namePlural} to be larger than ${TextUtils.dataSize(
              maxTotalFileSize
            )}.`
          );
      }

      // Read data from file and split into chunks
      const data = await Base64FileUtils.readFileAsBase64(file, {
        preservePrefix,
        removeBOM,
      });
      const chunks = Base64FileUtils.chunkFile(data, FIELD_SIZE_LIMIT);

      // If there is an attachment with the same name, overwrite the content
      const fileRef = ref.doc(existingDoc?.id ?? undefined);

      // Get parts
      const parts = await FirestoreUtils.createColRef<Base64EncodedFilePart>(
        `${fileRef.path}/parts`
      ).get();

      await FirestoreUtils.runTransaction(async (tx) => {
        // Create the document to save
        const doc = decorateDocument({
          id: fileRef.id,
          name: file.name,
          type: file.type,
          size: file.size,
          parts: chunks.length,
        });

        // Save file
        tx.set(fileRef, doc);

        // Save attachment data in chunks
        chunks.forEach((chunk, index) => {
          const id = `${fileRef.id}-${TextUtils.lpad(index, 3)}`;
          const path = `${fileRef.path}/parts/${id}`;
          const partRef = FirestoreUtils.createDocRef<Base64EncodedFilePart>(path);
          tx.set(partRef, {
            data: chunk,
            index,
          });
        });

        // Remove dangling parts
        parts.docs.forEach((doc) => {
          const data = doc.data();
          if (data?.index >= chunks.length) tx.delete(doc.ref);
        });
      });

      // Wait until attachment exists in Firestore before continuing.
      // This is required to prevent race conditions in calculating total
      // file size when uploading multiple files.
      if (maxTotalFileSize)
        await new Promise<void>(async (resolve, reject) => {
          let attempt = 0;
          while (attempt < 5) {
            const exists = await fileRef.get();
            if (exists.exists) return resolve();
            // Wait 500 ms
            await new Promise((resolve) => setTimeout(resolve, 500));
            attempt++;
          }
          reject(
            new Error(
              `Failed to verify that attachment ${file.name} was added successfully.`
            )
          );
        });

      return fileRef;
    },
    [
      maxFileSize,
      maxTotalFileSize,
      decorateDocument,
      namePlural,
      preservePrefix,
      removeBOM,
    ]
  );
};

/**
 * Returns a function which downloads a base64 encoded file from
 * the given document reference in Firestore.
 *
 * @param name The name to use for the file type (for logging purposes).
 */
export const useDownloadBase64File = <T extends Base64EncodedFile>(
  name: string = "file"
) => {
  return useCallback(
    async (ref?: DocRef<T> | null | false) => {
      if (!ref) return;

      // Get file
      const snapshot = await ref.get();
      const file = snapshot.data();
      if (!file)
        throw new Error(`${TextUtils.capitalise(name)} ${ref.id} not found.`);

      // Get parts
      const partsRef = FirestoreUtils.createColRef<Base64EncodedFilePart>(
        `${ref.path}/parts`
      );
      const { docs: parts } = await partsRef.orderBy("index").get();

      // Create download
      const download = document.createElement("a");
      download.href = Base64FileUtils.composeAsBase64DataUrl(
        file.type,
        parts.map((p) => p.data().data)
      );
      download.download = file.name;
      download.click();
    },
    [name]
  );
};
