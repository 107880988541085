import { useCallback } from "react";

import { debounce } from "@material-ui/core";

import { CLogger, FirestoreUtils } from "../../utils";

import { useCurrentUser } from "../../state/FirebaseAuthState";

import { useEmailContentById, useEmailHasRecipients } from "../data/email";

import { useUploadBase64File } from "./base64File";
import { useSubmitForReview } from "./email";

import {
  EmailEditor,
  EmailContent,
  EmailEditorRole,
  EmailRecipientCSVFile,
  EmailTemplate,
} from "../../../../types";

/**
 * This hook creates a function that can set the
 * template used for the current email.
 *
 * @param emailId The id of the email to set the template for.
 */
export const useSelectTemplate = (emailId?: string) => {
  return useCallback(
    async (template: Omit<EmailTemplate, "syncedAt" | "updatedAt">) => {
      if (!emailId) return;
      const ref = FirestoreUtils.createDocRef<EmailContent>(
        `emails/${emailId}/contents/template`
      );
      await ref.set({
        id: "template",
        modified: FirestoreUtils.timestamp(),
        text: `${template.group}:${template.name}`,
      });
    },
    [emailId]
  );
};

/**
 * This hook creates a function to add an editor
 * to the specified email.
 *
 * @param emailId The email to modify editors for.
 */
export const useAddEditor = (emailId?: string) => {
  return useCallback(
    async (userId: string, options: Pick<EmailEditor, "role" | "type">) => {
      if (!emailId) return;
      const ref = FirestoreUtils.createColRef<EmailEditor>(
        `emails/${emailId}/editors`
      ).doc();
      await ref.set({
        id: ref.id,
        editorId: userId,
        addedOn: FirestoreUtils.timestamp(),
        ...options,
      });
    },
    [emailId]
  );
};

/**
 * This hook creates a function to add update the editors
 * of the specified email.
 *
 * @param emailId The email to modify editors for.
 * @param role The role to use for the editors that are being added.
 */
export const useUpdateEditors = (
  emailId?: string,
  role: EmailEditorRole = "reviewer"
) => {
  return useCallback(
    async (editorIds: string[], options: Pick<EmailEditor, "role" | "type">) => {
      if (!emailId) return;
      const ref = FirestoreUtils.createColRef<EmailEditor>(
        `emails/${emailId}/editors`
      );
      const editorsSnapshot = await ref.where("role", "==", role).get();

      // Create list of current editors
      const currentEditors = editorsSnapshot.docs;
      const currentEditorIds = currentEditors.map(
        (editor) => editor.data().editorId
      );

      // Find editors to remove
      const removeEditors = currentEditors.filter(
        (editor) => !editorIds.includes(editor.data().editorId)
      );

      // Find editors to add
      const addEditors = editorIds.filter(
        (editorId) => !currentEditorIds.includes(editorId)
      );

      await Promise.all(removeEditors.map((editor) => editor.ref.delete()));
      await Promise.all(
        addEditors.map((editor) => {
          const docRef = ref.doc();
          return docRef.set({
            id: docRef.id,
            editorId: editor,
            addedOn: FirestoreUtils.timestamp(),
            ...options,
          });
        })
      );
    },
    [emailId, role]
  );
};

/**
 * This hook creates functions to remove an editor from
 * the specified email.
 *
 * @param emailId The email to modify editors for.
 * @param role The email role to remove. Default is 'reviewer'.
 */
export const useRemoveEditor = (
  emailId?: string,
  role: EmailEditorRole = "reviewer"
) => {
  return useCallback(
    async (editorId: string) => {
      if (!emailId) return;
      const matches = await FirestoreUtils.createColRef(`emails/${emailId}/editors`)
        .where("editorId", "==", editorId)
        .where("role", "==", role)
        .get();
      await Promise.all(matches.docs.map((doc) => doc.ref.delete()));
    },
    [emailId, role]
  );
};

/**
 * This hook returns a function that can upload a CSV file
 * for setting the recipients in the email.
 *
 * @param emailId The email to set recipients for.
 */
export const useSetRecipients = (emailId?: string) => {
  const currentUser = useCurrentUser();

  const upload = useUploadBase64File<EmailRecipientCSVFile>({
    decorateDocument: (doc) => ({
      ...doc,
      uploaded: FirestoreUtils.timestamp(),
      status: "uploading",
    }),
    namePlural: "CSV files",
    removeBOM: true,
  });

  return useCallback(
    async (file: File) => {
      if (!emailId) return;
      if (!currentUser) return;

      const ref = await upload(
        FirestoreUtils.createColRef<EmailRecipientCSVFile>(
          `emails/${emailId}/recipientCSVFiles`
        ),
        file
      );

      await ref?.set({ status: "pending" }, { merge: true });
    },
    [emailId, currentUser, upload]
  );
};

/**
 * This hook creates a function that can be used to set the
 * content specified for an email.
 *
 * @param emailId The id of the email to set the content for.
 * @param contentId The id of the content to set.
 */
export const useSetEmailContent = (emailId?: string, contentId?: string | null) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    debounce(async (content: Pick<EmailContent, "text" | "richText" | "html">) => {
      if (!emailId) return;
      if (!contentId) return;
      const ref = FirestoreUtils.createDocRef<EmailContent>(
        `emails/${emailId}/contents/${contentId}`
      );
      await ref.set({
        id: contentId,
        modified: FirestoreUtils.timestamp(),
        ...content,
      });
      CLogger.category("hooks.actions.useSetEmailContent")({
        level: "debug",
        message: `Saved ${contentId} content for <email|${emailId}>.`,
      });
    }, 1000),
    [emailId, contentId]
  );
};

/**
 * Returns a callback function that checks if an email is allowed
 * to be submitted for review. If the email passes all checks, it is
 * submitted for review and the `onSuccess` handler is called. Otherwise,
 * a list of errors is passed to the `onError` handler.
 *
 * @param emailId The id of the email to submit a review for.
 * @param onSuccess The callback for when a review is successfully submitted.
 * @param onError The callback for when there is an error submitting the review.
 */
export const useRequestReview = (
  emailId?: string,
  onSuccess?: () => void,
  onError?: (errors: string[]) => void
) => {
  const updateEditors = useUpdateEditors(emailId);
  const submitForReview = useSubmitForReview(emailId);

  const [hasRecipients] = useEmailHasRecipients(emailId);
  const [subject] = useEmailContentById(emailId, "subject");
  const [replyTo] = useEmailContentById(emailId, "replyTo");
  const [from] = useEmailContentById(emailId, "from");

  return useCallback(
    async (reviewerIds: string[]) => {
      const errors: string[] = [];

      if (!hasRecipients) errors.push("There are no recipients for this email.");
      if (!subject?.text) errors.push("Subject cannot be empty.");
      if (!replyTo?.text) errors.push("A reply-to address is required.");
      if (!from?.text) errors.push("A from address is required.");

      if (errors.length > 0) {
        onError?.(errors);
        return;
      }

      await updateEditors(reviewerIds, { role: "reviewer", type: "user" });
      await submitForReview();
      onSuccess?.();
    },
    [
      onSuccess,
      onError,
      updateEditors,
      submitForReview,
      hasRecipients,
      subject,
      replyTo,
      from,
    ]
  );
};
