import { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

import { CLogger, EnvUtils } from "../../../utils";

import { FirebaseContext, FIREBASE_REGION } from "./context";

const log = CLogger.category("contexts.firebase.FirebaseContext");

export interface FirebaseProviderProps {
  children?: React.ReactNode;
}

/**
 * This context loads initialises Firebase.
 */
export function FirebaseProvider({ children }: FirebaseProviderProps) {
  const [initialised, setInitialised] = useState(false);

  // Initialise Firebase when we have a valid configuration
  useEffect(() => {
    if (!initialised) {
      // Check if Firebase is already initialised
      if (firebase.apps.length > 0) {
        setInitialised(true);
        return;
      }

      try {
        // Parse Firebase config
        const firebaseJSON = EnvUtils.get("FIREBASE_CONFIG");
        const config = JSON.parse(firebaseJSON);

        // Initialise the app
        firebase.initializeApp(config);

        // Set emulators if on localhost
        if (window.location.hostname === "localhost") {
          firebase.auth().useEmulator("http://localhost:9099");
          firebase.firestore().useEmulator("localhost", 8080);
          firebase.app().functions(FIREBASE_REGION).useEmulator("localhost", 5001);
          log({
            level: "info",
            message: `Connected to Firebase Emulators.`,
          });

          // HACK: required for connecting to emulators reliably when testing with Cypress
          // https://github.com/cypress-io/cypress/issues/6350#issuecomment-821916119
          if ((window as any).Cypress) {
            firebase
              .firestore()
              .settings({ experimentalForceLongPolling: true, merge: true });
            log({
              level: "debug",
              message: `Detected local Cypress testing environment`,
            });
          }
        }

        setInitialised(true);

        log({
          level: "debug",
          message: `Initialised Firebase.`,
        });
      } catch (error: any) {
        log({
          level: "error",
          message: `Failed to parse Firebase configuration. ${error.message}`,
        });
      }
    }
  }, [initialised, setInitialised]);

  return (
    <FirebaseContext.Provider value={{ initialised }}>
      {children}
    </FirebaseContext.Provider>
  );
}
