export class CSVUtils {
  /**
   * Convert a list of objects into CSV form with the specified fields.
   *
   * @param fields The fields from the object to use in the CSV.
   * @param objecst The list of objects to turn into CSV records.
   */
  static objectsToCSV(fields: string[], objects: any[]): string {
    const rows: (string | number)[][] = [fields];
    for (const object of objects) {
      rows.push(
        fields.map((field) => {
          const value = object[field];
          if (typeof value === "number") return value;
          if (value === null || typeof value === "undefined") return "";
          return `"${value}"`;
        })
      );
    }
    return rows.map((row) => row.join(",")).join("\n");
  }

  /**
   * Trigger a download of a CSV file with the provided filename and data.
   *
   * @param filename The name of the file when downloading.
   * @param csv The CSV data to download.
   */
  static downloadCSV(filename: string, csv: string) {
    const file = new Blob([csv], { type: "text/csv" });
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}
