import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Skeleton from "@material-ui/lab/Skeleton";
import Container from "@material-ui/core/Container";

import { version } from "../../../version.json";

import { useApplicationSettings } from "../../../hooks/data/settings";

import Property from "../../../components/core/Property";
import Footer from "../../../components/core/Footer";

import useStyles from "./styles";

const PLACEHOLDER = <Skeleton width="10rem" />;

/**
 * Display the system's current settings.
 */
export const SystemInfoPage = () => {
  const classes = useStyles();

  const [app, appLoading] = useApplicationSettings();

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Typography variant="h5" className={classes.title}>
        <b>System Information</b>
      </Typography>
      <Divider className={classes.divider} />

      <Property className={classes.property} title="VERSION">
        MSend v{version}
      </Property>
      <Property
        className={classes.property}
        title="ID"
        titleHelp="This ID is used to identify the application and environment."
      >
        {appLoading ? PLACEHOLDER : app?.id}
      </Property>
      <Property
        className={classes.property}
        title="NAME"
        titleHelp="The official name of the application."
      >
        {appLoading ? PLACEHOLDER : app?.name}
      </Property>
      <Property
        className={classes.property}
        title="DOMAIN"
        titleHelp="This domain is used for generating links back to the application."
      >
        {appLoading ? PLACEHOLDER : app?.domain}
      </Property>

      <Property
        className={classes.property}
        title="EMBEDDED REPORT URL"
        titleHelp="The embedded looker URL for the report page"
      >
        {appLoading ? PLACEHOLDER : app?.reportLookerUrl}
      </Property>

      <Divider className={classes.divider} />
      <Footer />
    </Container>
  );
};
