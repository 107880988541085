import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Drawer from "@material-ui/core/Drawer";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

import DashboardIcon from "@material-ui/icons/Dashboard";
import EmailsIcon from "@material-ui/icons/Drafts";
import ReviewIcon from "@material-ui/icons/RateReview";
import WaitingIcon from "@material-ui/icons/HourglassFull";
import SendIcon from "@material-ui/icons/Send";
import HistoryIcon from "@material-ui/icons/History";
import AdminIcon from "@material-ui/icons/Apps";
import SystemIcon from "@material-ui/icons/Settings";
import ReportIcon from "@material-ui/icons/BarChart";

import { TextUtils } from "../../../utils";
import { SystemUserGroup } from "../../../constants";

import { NavigationLocationState } from "../../../state/NavigationLocation";
import { useCurrentUser } from "../../../state/FirebaseAuthState";
import {
  EditableEmailsState,
  ReviewableEmailsState,
} from "../../../state/UserEmails";

import { useMenuAnchor } from "../../../hooks/state";

import { NavigationDrawerLink } from "./NavigationDrawerLink";

import useStyles from "./styles";

export interface NavigationDrawerProps {}

/**
 * Shows the application navigation when the user the signed in.
 */
export const NavigationDrawer = (props: NavigationDrawerProps) => {
  const classes = useStyles();

  const navigate = useNavigate();
  const user = useCurrentUser();
  const navigationLocation = useRecoilValue(NavigationLocationState);

  const [draft] = useRecoilValue(EditableEmailsState("draft"));
  const [pending] = useRecoilValue(EditableEmailsState("review"));
  const [approved] = useRecoilValue(EditableEmailsState("approved"));
  const [reviewable] = useRecoilValue(ReviewableEmailsState);

  const [anchorEl, openMenu, closeMenu] = useMenuAnchor();

  const isSupport = user?.groups?.includes(SystemUserGroup.SUPPORT);
  const isDepartmentAdmin = user?.groups?.some((e) => e.endsWith("-administrators"));

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.links}>
        <Button component={Link} to="/dashboard">
          <img src="/images/monash_shield.svg" alt="home" className={classes.logo} />
        </Button>
        <NavigationDrawerLink
          to="/dashboard"
          label="Dashboard"
          icon={<DashboardIcon />}
          selected={navigationLocation === "dashboard"}
        />
        <NavigationDrawerLink
          to="/emails/draft"
          label="Your drafts"
          icon={<EmailsIcon />}
          amount={draft?.length}
          selected={navigationLocation === "email-draft"}
        />
        <NavigationDrawerLink
          to="/emails/pending"
          label="Emails awaiting approval"
          icon={<WaitingIcon />}
          amount={pending?.length}
          selected={navigationLocation === "email-pending"}
        />
        <NavigationDrawerLink
          to="/emails/approved"
          label="Ready to send"
          icon={<SendIcon />}
          amount={approved?.length}
          selected={navigationLocation === "email-approved"}
        />
        <NavigationDrawerLink
          to="/emails/sent"
          label="Delivered emails"
          icon={<HistoryIcon />}
          selected={navigationLocation === "email-sent"}
        />
        <Divider className={classes.sectionDivider} />
        <NavigationDrawerLink
          to="/emails/review"
          label="Emails requiring your approval"
          icon={<ReviewIcon />}
          amount={reviewable?.length}
          badgeVariant="error"
          selected={navigationLocation === "email-review"}
        />
        {(isSupport || isDepartmentAdmin) && (
          <Divider className={classes.sectionDivider} />
        )}
        {isDepartmentAdmin && (
          <NavigationDrawerLink
            to="/admin"
            label="Admin"
            icon={<AdminIcon />}
            selected={navigationLocation === "admin"}
          />
        )}
        {(isSupport || isDepartmentAdmin) && (
            <NavigationDrawerLink
                to="/report"
                label="Report"
                icon={<ReportIcon />}
                selected={navigationLocation === "report"}
            />
        )}
        {isSupport && (
          <NavigationDrawerLink
            to="/system"
            label="System"
            icon={<SystemIcon />}
            selected={navigationLocation === "system"}
          />
        )}
      </div>
      <IconButton onClick={openMenu} className={classes.avatarButton}>
        <Avatar className={classes.avatar}>
          {TextUtils.initials(user?.displayName ?? "")}
        </Avatar>
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closeMenu}
        classes={{
          paper: classes.menu,
        }}
      >
        <div />
        <Typography variant="body1">
          <b>{user?.displayName}</b>
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {user?.email}
        </Typography>
        <Divider className={classes.divider} />
        <Button
          color="primary"
          variant="outlined"
          onClick={() => navigate("/secure/exit")}
        >
          Log out
        </Button>
      </Menu>
    </Drawer>
  );
};
