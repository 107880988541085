import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Breakpoint, Breakpoints } from "@material-ui/core/styles/createBreakpoints";

/**
 * Use the mobile/desktop breakpoint. Returns `true` when
 * the current size is desktop.
 */
export const useDesktopBreakpoint = () => useBreakpoint("sm");

/**
 * Check whether the current screen size matches the given breakpoint.
 *
 * @param bp The breakpoint to check for.
 * @param type The type of breakpoint query. Default value is "up".
 */
export const useBreakpoint = (
  bp: Breakpoint,
  type: keyof Pick<Breakpoints, "up" | "down" | "only"> = "up"
) => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints[type](bp));
  return match;
};

/**
 * Check whether the current screen size is between the specified breakpoints.
 *
 * @param start The starting breakpoint.
 * @param end The ending breakpoint.
 */
export const useBreakpointBetween = (start: Breakpoint, end: Breakpoint) => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.between(start, end));
  return match;
};
