const PATH_KEY = "msend-path";

export class SignInUtils {
  /**
   * Save a redirect path in local storage.
   *
   * @param path The redirect path to save.
   */
  public static saveRedirectPath(path: string) {
    localStorage?.setItem(PATH_KEY, path);
  }

  /**
   * Get the last saved redirect path from local storage.
   *
   * @param clear If `true`, the redirect path will be removed from local storage.
   */
  public static getRedirectPath(clear?: boolean) {
    const path = localStorage?.getItem(PATH_KEY);
    if (clear) localStorage?.removeItem(PATH_KEY);
    return path;
  }
}
