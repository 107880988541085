import { useState } from "react";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import DuplicateIcon from "@material-ui/icons/FileCopy";

import { CLogger } from "../../../utils";

import { useSnack } from "../../../hooks/snack";
import { useBoolean } from "../../../hooks/state";
import { useDuplicateEmail } from "../../../hooks/actions/email";

import LoadingText from "../../../components/core/LoadingText";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";

import useStyles from "./styles";

const log = CLogger.category("components.editorSidebar.EditorDuplicateAction");

export interface EditorDuplicateActionProps {
  /**
   * The id of the email to preview.
   */
  emailId?: string;

  /**
   * Disables the action button.
   */
  disabled?: boolean;
}

/**
 * This action duplicates the current email.
 */
export const EditorDuplicateAction = ({
  emailId,
  disabled,
}: EditorDuplicateActionProps) => {
  const classes = useStyles();
  const { pushClosableSnack, pushErrorSnack } = useSnack();
  const duplicateEmail = useDuplicateEmail(emailId);

  const [open, onOpen, onClose] = useBoolean();
  const [duplicating, setDuplicating] = useState(false);

  /**
   * Handler for duplicating the email.
   */
  const duplicateHandler = async () => {
    try {
      setDuplicating(true);
      await duplicateEmail();
      pushClosableSnack(`Email duplicated.`);
      onClose();
    } catch (error: any) {
      pushErrorSnack(`Failed to duplicate email. ${error.message}`);
      log({
        level: "error",
        message: `Error while duplicating email: ${error.message}`,
        data: error,
      });
    } finally {
      setDuplicating(false);
    }
  };

  return (
    <>
      <Tooltip title="Duplicate" placement="left">
        <div>
          <IconButton onClick={onOpen} disabled={disabled}>
            <DuplicateIcon />
          </IconButton>
        </div>
      </Tooltip>
      <ConfirmDialog
        open={open}
        onClose={onClose}
        title="Duplicate email"
        confirmLabel="DUPLICATE"
        onConfirm={duplicateHandler}
        loading={duplicating}
      >
        {duplicating ? (
          <LoadingText type="linear">Duplicating email...</LoadingText>
        ) : (
          <>
            Duplicating this email will create a copy of the email's contents and
            attachments in a new draft.
            <div className={classes.br} />
            Note that this will <b>not</b> copy personalisation data.
          </>
        )}
      </ConfirmDialog>
    </>
  );
};
