export class Utf8Utils {
  // Check https://www.i18nqa.com/debug/utf8-debug.html#dbg for more details
  private static readonly BROKEN_UTF8_MAP: [RegExp, string][] = [
    [/â‚¬/g, "(EUR)"],
    [/â€š/g, ","],
    [/â€ž/g, ",,"],
    [/â€¦/g, "..."],
    [/â€¡/g, "++"],
    [/â€°/g, "%"],
    [/â€¹/g, "<"],
    [/â€˜/g, "'"],
    [/â€™/g, "'"],
    [/â€œ/g, "\""],
    [/â€¢/g, "."],
    [/â€“/g, "-"],
    [/â€”/g, "-"],
    [/â„¢/g, "(TM)"],
    [/â€º/g, ">"],
    [/Æ’/g, "f"],
    [/â€/g, "+"],
    [/Ë†/g, "^"],
    [/Å’/g, "OE"],
    [/Å½/g, "Z"],
    [/â€/g, "\""],
    [/Ëœ/g, "~"],
    [/Å¡/g, "s"],
    [/Å“/g, "oe"],
    [/Å¾/g, "z"],
    [/Å¸/g, "Y"],
    [/Â¡/g, "i"],
    [/Â¢/g, "c"],
    [/Â£/g, "(GBP)"],
    [/Â¤/g, " "],
    [/Â¥/g, "(YEN)"],
    [/Â¦/g, " "],
    [/Â§/g, "S"],
    [/Â¨/g, "\""],
    [/Â©/g, "(C)"],
    [/Âª/g, "a"],
    [/Â«/g, "<<"],
    [/Â¬/g, " "],
    [/Â­/g, " "],
    [/Â®/g, "(R)"],
    [/Â¯/g, "-"],
    [/Â°/g, "o"],
    [/Â±/g, "+-"],
    [/Â²/g, "2"],
    [/Â³/g, "3"],
    [/Â´/g, "'"],
    [/Âµ/g, "u"],
    [/Â¶/g, " "],
    [/Â·/g, "."],
    [/Â¸/g, ","],
    [/Â¹/g, "1"],
    [/Âº/g, "o"],
    [/Â»/g, ">>"],
    [/Â¼/g, "1/4"],
    [/Â½/g, "1/2"],
    [/Â¾/g, "3/4"],
    [/Â¿/g, "?"],
    [/Ã€/g, "A"],
    [/Ã‚/g, "A"],
    [/Ãƒ/g, "A"],
    [/Ã„/g, "A"],
    [/Ã…/g, "A"],
    [/Ã†/g, "AE"],
    [/Ã‡/g, "C"],
    [/Ãˆ/g, "E"],
    [/Ã‰/g, "E"],
    [/ÃŠ/g, "E"],
    [/Ã‹/g, "E"],
    [/ÃŒ/g, "I"],
    [/ÃŽ/g, "I"],
    [/Ã‘/g, "N"],
    [/Ã’/g, "O"],
    [/Ã“/g, "O"],
    [/Ã”/g, "O"],
    [/Ã•/g, "O"],
    [/Ã–/g, "O"],
    [/Ã—/g, "x"],
    [/Ã˜/g, "O"],
    [/Ã™/g, "U"],
    [/Ãš/g, "U"],
    [/Ã›/g, "U"],
    [/Ãœ/g, "U"],
    [/Ãž/g, "TH"],
    [/ÃŸ/g, "ss"],
    [/Ã¡/g, "a"],
    [/Ã¢/g, "a"],
    [/Ã£/g, "a"],
    [/Ã¤/g, "a"],
    [/Ã¥/g, "a"],
    [/Ã¦/g, "ae"],
    [/Ã§/g, "c"],
    [/Ã¨/g, "e"],
    [/Ã©/g, "e"],
    [/Ãª/g, "e"],
    [/Ã«/g, "e"],
    [/Ã¬/g, "i"],
    [/Ã­/g, "i"],
    [/Ã®/g, "i"],
    [/Ã¯/g, "i"],
    [/Ã°/g, "d"],
    [/Ã±/g, "n"],
    [/Ã²/g, "o"],
    [/Ã³/g, "o"],
    [/Ã´/g, "o"],
    [/Ãµ/g, "o"],
    [/Ã¶/g, "o"],
    [/Ã·/g, "/"],
    [/Ã¸/g, "o"],
    [/Ã¹/g, "u"],
    [/Ãº/g, "u"],
    [/Ã»/g, "u"],
    [/Ã¼/g, "u"],
    [/Ã½/g, "y"],
    [/Ã¾/g, "th"],
    [/Ã¿/g, "y"]
  ];

  public static fixUtf8(name: string): string {
    return Utf8Utils.BROKEN_UTF8_MAP.reduce(
        (fixedName, [regex, replacement]) => fixedName.replace(regex, replacement),
        name
    ).normalize();
  }

  public static isBroken(name: string): boolean {
    return Utf8Utils.BROKEN_UTF8_MAP.some(([regex]) => regex.test(name));
  }
}
