import clsx from "clsx";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";

import useStyles from "./styles";

export interface ConfirmDialogProps {
  children?: React.ReactNode;

  /**
   * Whether the dialog is open.
   *
   * @default false
   */
  open?: boolean;

  /**
   * Callback function to close the dialog.
   */
  onClose?: () => void;

  /**
   * A title to show on the dialog.
   */
  title?: string;

  /**
   * Callback for when the cancel button is clicked.
   */
  onCancel?: () => void;

  /**
   * Callback for when the confirm button is clicked.
   */
  onConfirm?: () => void;

  /**
   * The label to use for the cancel button.
   *
   * @default "Cancel"
   */
  cancelLabel?: string;

  /**
   * The label to use for the confirm button.
   *
   * @default "Confirm"
   */
  confirmLabel?: string;

  /**
   * If this is `true`, it indicates a destrucive action and
   * the confirm button will be coloured red.
   */
  destructive?: boolean;

  /**
   * If `true`, the confirm dialog will not be closable and
   * buttons will be disabled.
   */
  loading?: boolean;

  /**
   * Disables the confirm button.
   */
  disableConfirm?: boolean;
}

/**
 * Show a confirmation dialog.
 */
export const ConfirmDialog = ({
  open = false,
  onClose,
  title,
  onConfirm,
  onCancel = onClose,
  confirmLabel = "CONFIRM",
  cancelLabel = "CANCEL",
  destructive,
  children,
  loading,
  disableConfirm,
}: ConfirmDialogProps) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!loading && onClose) onClose();
      }}
      maxWidth="xs"
      fullWidth
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      {children && (
        <DialogContent>
          <DialogContentText component="div">{children}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onCancel} disabled={loading}>
          {cancelLabel}
        </Button>
        {onConfirm && (
          <Button
            color="primary"
            variant="contained"
            disabled={loading || disableConfirm}
            onClick={onConfirm}
            className={clsx({
              [classes.destructive]: destructive,
            })}
          >
            {confirmLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
