import { useCurrentUser } from "../../state/FirebaseAuthState";

import { useCollectionData, useDocumentData } from "../../hooks/firebase";
import { useLogError } from "../../hooks/log";

import { useEmail } from "./email";

import { User, AlertView } from "../../../../types";

/**
 * Retreive the specified user's profile.
 *
 * @param userId The id of the user to get.
 */
export const useGetUserProfile = (
  userId?: string | null
): [User | undefined, boolean, Error | undefined] => {
  const [profile, loading, error] = useDocumentData<User>(
    userId && `users/${userId}`
  );
  useLogError(
    `Error while retrieving profile for <user|${userId}>`,
    "hook-useGetUserProfile",
    error
  );
  return [profile, loading, error];
};

/**
 * Get the current user's profile.
 */
export const useUserProfile = (): [User | undefined, boolean, Error | undefined] => {
  const user = useCurrentUser();
  return useGetUserProfile(user?.uid);
};

/**
 * Get the alert views for the given user.
 */
export const useAlertViews = (
  userId?: string
): [AlertView[] | undefined, boolean, Error | undefined] => {
  const [alerts, loading, error] = useCollectionData<AlertView>(
    userId && `users/${userId}/alerts`
  );
  useLogError(
    `Error while retrieving alert views for <user|${userId}>`,
    "hook-useUserAlertViews",
    error
  );
  return [alerts, loading, error];
};

/**
 * Returns if the current user is an editor or reviewer of the email.
 *
 * @param emailId The id of the email.
 * @retursn `[isEditor, isReviewer]`
 */
export const useCanEditEmail = (emailId?: string): [boolean, boolean] => {
  const user = useCurrentUser();
  const [email] = useEmail(emailId);
  const isEditor = user && email && email.editors?.includes(user.uid);
  const isReviewer = user && email && email.reviewers?.includes(user.uid);
  return [Boolean(isEditor), Boolean(isReviewer)];
};
