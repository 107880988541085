import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Color } from "../../../constants";

export const DRAWER_WIDTH_DESKTOP = 80;
export const DRAWER_WIDTH_MOBILE = 65;

export default makeStyles<Theme>((theme) =>
  createStyles({
    drawer: {
      flexShrink: 0,
      zIndex: theme.zIndex.drawer + 1,
      width: DRAWER_WIDTH_DESKTOP,
      [theme.breakpoints.down("sm")]: {
        width: DRAWER_WIDTH_MOBILE,
      },
    },
    drawerPaper: {
      width: DRAWER_WIDTH_DESKTOP,
      borderRight: "none",
      backgroundColor: Color.MONASH_GREY,
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        width: DRAWER_WIDTH_MOBILE,
      },
    },
    logo: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      width: "70%",
    },
    links: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },

    // Links
    sectionDivider: {
      width: "70%",
      height: 2,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      background: theme.palette.secondary.light,
    },

    // Account
    avatarButton: {
      alignSelf: "center",
      marginBottom: theme.spacing(3),
    },
    avatar: {
      backgroundColor: Color.MONASH_BLUE,
    },

    // Account menu
    menu: {
      minWidth: 200,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginLeft: DRAWER_WIDTH_DESKTOP - theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        marginLeft: DRAWER_WIDTH_MOBILE - theme.spacing(1),
      },
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);

export const useLinkStyles = makeStyles<Theme>((theme) =>
  createStyles({
    button: {
      "width": "100%",
      "padding": theme.spacing(2),
      "&:hover": {
        background: "#0004",
      },
    },
    label: {
      textDecoration: "none",
      textTransform: "none",
      color: "white",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    selected: {
      background: "#0006",
      borderRadius: 0,
      borderRight: "solid 4px #fff4",
    },
  })
);
