import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

import { useBooleanWithState } from "../../../hooks/state";

import TemplatePreviewDialog from "../../dialogs/TemplatePreviewDialog";

import { EmailTemplate } from "../../../../../types";

import useStyles from "./styles";

export interface TemplateSelectorProps {
  /**
   * The list of templates to show.
   */
  templates?: Omit<EmailTemplate, "syncedAt" | "updatedAt">[];

  /**
   * Callback for when a template is selected.
   */
  onSelectTemplate?: (
    template: Omit<EmailTemplate, "syncedAt" | "updatedAt">
  ) => void;
}

/**
 * This component displays a list of templates for the user to choose from.
 * On selection, it will be applied to the currently selected email.
 */
export const TemplateSelector = ({
  templates,
  onSelectTemplate,
}: TemplateSelectorProps) => {
  const classes = useStyles();

  const [template, showPreview, openPreview, closePreview] =
    useBooleanWithState<Omit<EmailTemplate, "syncedAt" | "updatedAt">>();

  return (
    <Grid container spacing={2} className={classes.grid}>
      {templates?.map((template) => {
        const activeVersion = template.versions.find((v) => v.active);
        return (
          <Grid key={template.id} item xs={12} sm={6} lg={4}>
            <Tooltip title="Click to preview template">
              <Card className={classes.card}>
                <CardActionArea
                  className={classes.action}
                  onClick={() => openPreview(template)}
                >
                  <CardMedia
                    className={classes.media}
                    image={`https:${activeVersion?.thumbnailUrl}`}
                    title={template.name}
                  />
                  <CardContent className={classes.templateInfo}>
                    <Typography
                      className={classes.templateName}
                      gutterBottom
                      variant="body1"
                      component="h2"
                    >
                      <b>{template.name}</b>
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Version: {activeVersion?.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Tooltip>
          </Grid>
        );
      })}
      <TemplatePreviewDialog
        open={showPreview}
        template={template?.versions.find((v) => v.active)?.htmlContent ?? ""}
        onClose={closePreview}
        contents={[{ id: "body", text: "Your content here", modified: null as any }]}
        showClose={Boolean(onSelectTemplate)}
        onCloseText="CANCEL"
        onConfirmText="USE THIS TEMPLATE"
        onConfirm={
          onSelectTemplate ? () => template && onSelectTemplate(template) : undefined
        }
      />
    </Grid>
  );
};
