import { useState, useEffect } from "react";
import handlebars from "handlebars";

import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import { SharedUtils, dateUtils, CLogger, TextUtils } from "../../../utils";

import { EmailContent, EmailRecipient } from "../../../../../types";

import useStyles from "./styles";

export interface TemplatePreviewProps {
  /**
   * The template string to render.
   */
  template?: string;

  /**
   * The template contents to render.
   */
  contents?: EmailContent[];

  /**
   * The recipient for personalisation rendering.
   */
  recipient?: EmailRecipient;

  /**
   * If `true`, the recipient's email, CC's and BCC's will be rendered at the top of the template.
   */
  showRecipients?: boolean;

  /**
   * If `true`, the subject will be rendered on top of the template.
   */
  showSubject?: boolean;
}

// Register helpers used in email handlebars templates
handlebars.registerHelper("formatDate", (date: string, format: string) =>
  dateUtils.format(new Date(date), format)
);

const log = CLogger.category("components.editor.TemplatePreview");

/**
 * Renders a given template using the content of the current
 * email from Recoil state.
 */
export const TemplatePreview = ({
  template,
  contents,
  recipient,
  showRecipients,
  showSubject,
}: TemplatePreviewProps) => {
  const classes = useStyles();

  const [renderedTemplate, setRenderedTemplate] = useState(template ?? "");
  const [renderedSubject, setRenderedSubject] = useState("");
  const [error, setError] = useState("");

  // Render template string so that a preview can be rendered in a div
  useEffect(() => {
    // Render an email template for preview using the given list
    // of email contents. For email content to be injected correctly
    // into the template, it matches and replaces tokens in the form
    // `{{{TOKEN_ID}}}` where `TOKEN_ID` matches the content id.

    let renderedTemplate = template ?? "";
    let renderedSubject = "";

    // The subtitution tokens to add to the email
    const substitutions = SharedUtils.generatePersonalisationTokens(recipient);

    // Inject each piece of email content into the template
    contents?.forEach((content) => {
      // Use html content if available, then use text as fallback
      let contentToInject = content.html ?? content.text;

      // If we have rich text, perform the conversion from Draft.js raw state
      if (content.richText)
        contentToInject = SharedUtils.renderRichText(content.richText);

      try {
        // Render the template's content fields
        const renderedContent = handlebars.compile(contentToInject)(substitutions);

        // Inject rendered content by replacing matching tokens
        const re = new RegExp(`{{{${content.id}}}}`, "g");
        renderedTemplate = renderedTemplate.replace(re, renderedContent);

        // Save rendered subject separately
        if (content.id === "subject") renderedSubject = renderedContent;
      } catch (error: any) {
        // An error may be thrown if there are invalid tokens
        setError(error.message);
        log({
          level: "error",
          message: error.message,
        });
      }
    });

    // Remove any placeholders tokens
    renderedTemplate = renderedTemplate.replace(/{{{placeholder}}}/g, "");

    try {
      // Complete rendering of template
      renderedTemplate = handlebars.compile(renderedTemplate)(substitutions);
    } catch (error: any) {
      // An error may be thrown if there are invalid tokens
      setError(error.message);
      log({
        level: "error",
        message: error.message,
      });
    }

    setRenderedTemplate(renderedTemplate);
    setRenderedSubject(renderedSubject);
  }, [recipient, contents, template, setRenderedTemplate]);

  if (error)
    return (
      <Alert severity="error">
        <AlertTitle>
          <b>There was an issue rendering your template.</b>
        </AlertTitle>
        Please ensure that your tokens do not contain spaces. If you have added
        formatting to your tokens, ensure that the entire token has the same style
        applied.
        <Typography variant="body2" color="textSecondary" className={classes.error}>
          Error: {error}
        </Typography>
      </Alert>
    );

  const hasRecipient = Boolean(showRecipients && recipient);
  const hasCC = Boolean(showRecipients && recipient?.cc?.length);
  const hasBCC = Boolean(showRecipients && recipient?.bcc?.length);
  const hasSubject = Boolean(showSubject && renderedSubject);

  const recipientNode = (
    <tr>
      <td className={classes.field}>
        <Typography variant="body2">
          <b>To:</b>
        </Typography>
      </td>
      <td>
        <Typography variant="body2">
          {TextUtils.formatEmailRecipient(recipient)}
        </Typography>
      </td>
    </tr>
  );

  const ccNode = (
    <tr>
      <td className={classes.field}>
        <Typography variant="body2">
          <b>CC:</b>
        </Typography>
      </td>
      <td>
        <Typography variant="body2">
          {recipient?.cc
            ?.map((cc) => TextUtils.formatEmailRecipient({ email: cc }))
            .join(", ")}
        </Typography>
      </td>
    </tr>
  );

  const bccNode = (
    <tr>
      <td className={classes.field}>
        <Typography variant="body2">
          <b>BCC:</b>
        </Typography>
      </td>
      <td>
        <Typography variant="body2">
          {recipient?.bcc
            ?.map((bcc) => TextUtils.formatEmailRecipient({ email: bcc }))
            .join(", ")}
        </Typography>
      </td>
    </tr>
  );

  const subjectNode = (
    <tr>
      <td className={classes.field}>
        <Typography variant="body2">
          <b>Subject:</b>
        </Typography>
      </td>
      <td>
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{ __html: renderedSubject }}
        ></Typography>
      </td>
    </tr>
  );

  return (
    <>
      {(hasRecipient || hasSubject) && (
        <>
          <table className={classes.table}>
            <tbody>
              {hasRecipient ? recipientNode : null}
              {hasCC ? ccNode : null}
              {hasBCC ? bccNode : null}
              {hasSubject ? subjectNode : null}
            </tbody>
          </table>
          <Divider />
        </>
      )}
      <div dangerouslySetInnerHTML={{ __html: renderedTemplate }} />
    </>
  );
};
