import { useEffect } from "react";

import { useAuth } from "../../../contexts/firebase/FirebaseContext";

import Loading from "../../../components/core/Loading";

/**
 * Signs the user out.
 */
export const SignOutPage = () => {
  const auth = useAuth();

  // Sign out
  useEffect(() => {
    auth?.signOut().then(() => {
      window.location.href = "/api/logout";
    });
  }, [auth]);

  return <Loading full message="Signing out..." />;
};
