import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    content: {
      flexShrink: 0,
      flexGrow: 0,
    },
    filter: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
    text: {
      marginBottom: theme.spacing(1),
    },

    // List
    listHeading: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(1),
    },
    list: {
      minHeight: "50vh",
      overflowY: "hidden",
      flexGrow: 1,
    },

    // Empty
    empty: {
      display: "flex",
      flexDirection: "column",
      minHeight: "30vh",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },

    // Task
    loading: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(6),
    },
    alert: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    // Ensure scolling when content is too long when showing parsing errors
    errorAlert: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(2),
      whiteSpace: "pre-line",
      maxHeight: "150px",
      overflowY: "auto"
    },
  })
);
