import { useState, useEffect, useMemo } from "react";

import { SystemUserGroup } from "../../../constants";

import { useUsers } from "../../../hooks/data/users";
import { useCurrentUser } from "../../../state/FirebaseAuthState";

import { useEmailData } from "./utils";
import { useEmailTemplate } from "./template";

import { EmailEditor, EmailEditorRole, User } from "../../../../../types";

/**
 * Retrieve the editors for the specified email.
 *
 * @param emailId The id of the email to retrieve editors for.
 * @param role The editor role to filter results by.
 */
export const useEmailEditors = (
  emailId?: string,
  role?: EmailEditorRole
): [EmailEditor[] | undefined, boolean, Error | undefined] => {
  const [allEditors, error, loading] = useEmailData<EmailEditor>(emailId, "editors");

  const [editors, setEditors] = useState<EmailEditor[]>([]);

  // Filter through editor roles if specified
  useEffect(() => {
    if (!allEditors) return;
    if (!role) setEditors(allEditors);
    else setEditors(allEditors?.filter((e) => e.role === role));
  }, [allEditors, role, setEditors]);

  return [editors, error, loading];
};

/**
 * Retrieve the editors and reviewers for the specified email as users.
 *
 * @param emailId The id of the email to retrieve reviewers for.
 */
export const useEmailEditorsAsUsers = (
  emailId?: string
): [User[], User[], boolean] => {
  const [allUsers, usersLoading] = useUsers();
  const [allEditors, editorsLoading] = useEmailEditors(emailId);

  const [editors, setEditors] = useState<User[]>([]);
  const [reviewers, setReviewers] = useState<User[]>([]);

  // Filter through all editors and return those with a matching user profile
  useEffect(() => {
    const editorIds = allEditors
      ?.filter((r) => r.role === "editor")
      .map((r) => r.editorId);
    const reviewerIds = allEditors
      ?.filter((r) => r.role === "reviewer")
      .map((r) => r.editorId);

    const editors: User[] = [];
    const reviewers: User[] = [];

    editorIds?.forEach((id) => {
      const user = allUsers?.find((u) => u.id === id);
      if (user) editors.push(user);
    });
    reviewerIds?.forEach((id) => {
      const user = allUsers?.find((u) => u.id === id);
      if (user) reviewers.push(user);
    });

    setEditors(editors);
    setReviewers(reviewers);
  }, [allUsers, allEditors, setEditors, setReviewers]);

  return [editors, reviewers, usersLoading || editorsLoading];
};

/**
 * Retrieve the list of users who are available as a reviewer for
 * the given email.
 *
 * @param emailId The id of the email to check.
 */
export const useEmailReviewerOptions = (emailId?: string): [User[], boolean] => {
  const [allUsers, usersLoading] = useUsers();
  const [{ template }, templatesLoading] = useEmailTemplate(emailId);
  const [, editorsLoading] = useEmailEditors(emailId);

  const user = useCurrentUser();

  const users = useMemo(() => {
    // Get the user's groups to filter by
    // If the email template has a group, use that instead
    const allGroups = template?.group ? [template?.group] : user?.groups ?? [];

    // Create a list of approver groups based on the user's groups
    // OR if they are in the ROLE.SUPPORT group

    // We do this by adding "-approvers" to all of their groups
    // unless it already ends with "-apporovers"

    const approverGroups = new Set(
      allGroups.map((group) =>
        group.endsWith("-approvers") || group === SystemUserGroup.SUPPORT
          ? group
          : `${group}-approvers`
      )
    );

    const filteredUsers: Set<User> = new Set();

    // Go through each group which contains approvers
    approverGroups.forEach((g) => {
      // Go through all users
      allUsers?.forEach((user) => {
        // If the user has the group, add to filteredUsers
        if (user.groups.includes(g)) filteredUsers.add(user);
      });
    });

    return Array.from(filteredUsers);
  }, [allUsers, template, user?.groups]);

  return [users, usersLoading || editorsLoading || templatesLoading];
};
