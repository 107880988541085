import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { PageTitleProps } from "./PageTitle";
import { Color } from "../../../constants";

export default makeStyles<Theme, PageTitleProps>((theme) =>
  createStyles({
    root: {
      position: "relative",
    },
    background: ({ height = 280 }) => ({
      "&::before": {
        content: "''",
        position: "absolute",
        display: "block",
        height: height,
        width: "100%",
        zIndex: -1,
        background: Color.GRADIENT,
      },
    }),
    title: {
      color: "white",
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(2),
    },
  })
);
