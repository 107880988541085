import { SystemAlert } from "../../../types";

export enum SystemAlertIdentifier {
  TOS_2021_MAY = "TOS_2021_MAY",
}

/**
 * A list of system alerts the user must be shown when they use the system.
 */
export const SYSTEM_ALERTS: SystemAlert[] = [
  // {
  //   id: SystemAlertIdentifier.TOS_2021_MAY,
  //   type: "system",
  // },
];
