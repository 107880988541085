import { Color } from "../../../constants";

import { ColorPaletteOption } from "./types";

export const textPalette: ColorPaletteOption[] = [
  {
    label: "Default",
    color: Color.EDITOR_DEFAULT,
  },
  {
    label: "Gray",
    color: Color.EDITOR_GRAY,
  },
  {
    label: "Monash Blue",
    color: Color.MONASH_BLUE,
  },
  {
    label: "Red",
    color: Color.EDITOR_RED,
  },
  {
    label: "Green",
    color: Color.EDITOR_GREEN,
  },
];

export const bgPalette: ColorPaletteOption[] = [
  {
    label: "Default",
    color: Color.EDITOR_DEFAULT,
  },
  {
    label: "Light Gray",
    color: Color.EDITOR_LIGHT_GRAY,
  },
  {
    label: "Gray",
    color: Color.EDITOR_GRAY,
  },
  {
    label: "Red",
    color: Color.EDITOR_RED,
  },
  {
    label: "Green",
    color: Color.EDITOR_GREEN,
  },
];
