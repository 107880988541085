import { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { matchSorter } from "match-sorter";

import AddIcon from "@material-ui/icons/Add";

import { CLogger, EmailUtils } from "../../../utils";

import { SelectEmailList } from "../../../state/UserEmails";

import { useCreateDraft } from "../../../hooks/actions/email";
import { useSnack } from "../../../hooks/snack";

import EmailList from "../../../components/editor/EmailList";

import { Email } from "../../../../../types";

export interface EmailListFromStateProps {}

const log = CLogger.category("components.containers.EmailListFromState");

/**
 * Based on a given email state in the path, retrieve the appropriate
 * list of emails to show to the user.
 */
export const EmailListFromState = (props: EmailListFromStateProps) => {
  const { state = "", emailId = "" } = useParams();
  const { pushErrorSnack } = useSnack();
  const navigate = useNavigate();

  const status = useMemo(() => EmailUtils.getEmailStatus(state), [state]);

  const [emails, loading] = useRecoilValue(SelectEmailList(status));

  const [search, setSearch] = useState("");
  const [emailsToShow, setEmailsToShow] = useState<Email[]>();

  // Set emails to show based on search and status
  useEffect(() => {
    if (!emails) return;

    // If there is no search, show all emails
    if (!search) {
      setEmailsToShow(emails);
      return;
    }

    // Sort the emails based on search
    const searchResults = matchSorter(emails, search, {
      keys: ["preview.body", "preview.subject"],
    });

    // Show the filtered emails
    setEmailsToShow(searchResults);
  }, [emails, search, status]);

  const createDraft = useCreateDraft();
  const [creating, setCreating] = useState(false);

  /**
   * Create a new draft and redirect the user to view the draft.
   */
  const createDraftHandler = async () => {
    setCreating(true);
    try {
      const draft = await createDraft();
      navigate(`/emails/draft/${draft?.id}`);
    } catch (error: any) {
      pushErrorSnack(`Failed to create draft. ${error.message}`);
      log({
        level: "error",
        message: `Error while creating draft: ${error.message}`,
        data: error,
      });
    } finally {
      setCreating(false);
    }
  };

  return (
    <EmailList
      title={state === "view" ? "emails" : state}
      loading={loading}
      emails={emailsToShow}
      search={search}
      setSearch={setSearch}
      clearSearch={() => setSearch("")}
      selectedEmails={emailId ? [emailId] : []}
      onSelectEmail={(emailId) => navigate(`/emails/${state}/${emailId}`)}
      actionIcon={state === "draft" && <AddIcon />}
      actionTooltip="Create new draft"
      onAction={createDraftHandler}
      actionDisabled={creating}
    />
  );
};
