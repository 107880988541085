import { EditorState, RichUtils, EntityInstance } from "draft-js";
import clsx from "clsx";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

import { DraftUtils } from "../../../../utils";

import { useToolbarButtonStyles } from "../styles";

export interface EditorToolbarButtonProps {
  className?: string;
  children?: React.ReactNode;

  /**
   * Disable the button.
   */
  disabled?: boolean;

  /**
   * The current editor state.
   */
  editorState?: EditorState;

  /**
   * The currently selected entity if there is one.
   */
  currentEntity?: EntityInstance | null;

  /**
   * Callback function for when the editor state changes.
   */
  onChange?: (newState: EditorState) => void;

  /**
   * Tooltip to show when the user hovers over the button.
   */
  tooltip?: string;

  /**
   * If provided, button will be used to toggle an inline style.
   */
  inlineStyle?: string;

  /**
   * If provided, button will be used to toggle the block type.
   */
  blockType?: string;

  /**
   * If provided, the button will be used to set a value in the
   * block data with the specified key in the current selection.
   */
  blockData?: {
    key: string;
    value: string;
    default?: string;
  };

  /**
   * If provided, the button will initial the flow required to
   * insert the specified entity.
   */
  entity?: {
    type: string;
    activeTooltip?: string;
    onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  };

  /**
   * On click handler.
   */
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

/**
 * Renders a button that can execute an action on the editor state.
 */
export const EditorToolbarButton = ({
  className,
  children,
  disabled,
  editorState,
  onChange,
  tooltip,
  inlineStyle,
  blockType,
  blockData,
  currentEntity,
  entity,
  onClick,
}: EditorToolbarButtonProps) => {
  const classes = useToolbarButtonStyles();

  /**
   * Prevent default focus behaviour as we want the
   * focus to stay on the editor.
   */
  const onMouseDownHandler = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  /**
   * The on click handler performs the appropriate action
   * based on the supplied props.
   */
  const onClickHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onClick?.(e);

    // Set inline style if provided
    if (inlineStyle && editorState)
      return onChange?.(RichUtils.toggleInlineStyle(editorState, inlineStyle));

    // Set block type if provided
    if (blockType && editorState)
      return onChange?.(RichUtils.toggleBlockType(editorState, blockType));

    // Set block data if provided
    if (blockData && editorState) {
      return onChange?.(
        DraftUtils.mergeBlockDataOfCurrentSelection(
          editorState,
          blockData.key,
          // Toggle the value to undefined if it is the same as current value
          DraftUtils.getToggleValueForBlockData(
            editorState,
            blockData.key,
            blockData.value
          )
        )
      );
    }

    // Callback for creating an entity
    if (entity) return entity.onClick(e);
  };

  // Derive the active state of the button based on block data
  let active = false;
  if (editorState) {
    // If it is an inline style, check if the current selection contains the inline style
    if (inlineStyle) {
      active = editorState.getCurrentInlineStyle().has(inlineStyle);
    }
    // If it is a block type, check the current block type
    else if (blockType) {
      const selection = editorState.getSelection();
      const block = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey());
      active = block.getType() === blockType;
    }
    // If it is block data, check the current block data value
    else if (blockData) {
      const currentValue =
        DraftUtils.getCurrentBlockDataValue(editorState, blockData.key) ??
        blockData.default;
      active = currentValue === blockData.value;
    }
    // If it is an entity, check current entity
    else if (entity) {
      active = currentEntity?.getType() === entity.type;
    }
  }

  return (
    <Tooltip
      title={(active ? entity?.activeTooltip ?? tooltip : tooltip) ?? ""}
      placement="top"
    >
      <span className={className}>
        <Button
          disabled={disabled}
          disableRipple={!Boolean(onClick)}
          className={clsx(classes.button, {
            [classes.active]: active,
          })}
          onMouseDown={onMouseDownHandler}
          onClick={onClickHandler}
        >
          {typeof children === "string" ? (
            <Typography className={classes.buttonText}>{children}</Typography>
          ) : (
            children
          )}
        </Button>
      </span>
    </Tooltip>
  );
};
