import { SystemUserGroup } from "../constants";

import { CategoryTag, EmailActionTag, UserActionTag } from "../../../types";

/**
 * Gives a raw tag a user-friendly label.
 * Also allows the tag to be restricted to certain user groups.
 */
export interface TagConfig {
  label: string;
  restrictTo?: SystemUserGroup[];
}

/**
 * Represents the different tag types that could be created by the system.
 */
export enum TagType {
  CATEGORY = "category",
  GROUP = "group",
  USER = "user",
  USER_ACTION = "user-action",
  EMAIL = "email",
  EMAIL_ACTION = "email-action",
  SENDGRID_EVENT = "sendgrid-event",
}

/**
 * Tag types that are created by the system.
 */
export const TAG_TYPES: Record<TagType, TagConfig> = {
  [TagType.CATEGORY]: {
    label: "Category",
  },
  [TagType.GROUP]: {
    label: "User group",
    restrictTo: [SystemUserGroup.SUPPORT],
  },
  [TagType.USER]: {
    label: "User",
  },
  [TagType.USER_ACTION]: {
    label: "User action",
    restrictTo: [SystemUserGroup.SUPPORT],
  },
  [TagType.EMAIL]: {
    label: "Email",
  },
  [TagType.EMAIL_ACTION]: {
    label: "Email action",
  },
  [TagType.SENDGRID_EVENT]: {
    label: "Email event",
  },
};

export const CATEGORY_TAG_VALUES: { [key in CategoryTag]: TagConfig } = {
  "system": {
    label: "System",
    restrictTo: [SystemUserGroup.SUPPORT],
  },
  "user-management": {
    label: "User management",
    restrictTo: [SystemUserGroup.SUPPORT],
  },
  "backups": {
    label: "Backups",
    restrictTo: [SystemUserGroup.SUPPORT],
  },
  "data-cleanup": {
    label: "Data cleanup",
    restrictTo: [SystemUserGroup.SUPPORT],
  },
  "session-cleanup": {
    label: "Session cleanup",
    restrictTo: [SystemUserGroup.SUPPORT],
  },
  "email-lifecycle": {
    label: "Email lifecycle",
  },
  "email-processing": {
    label: "Email processing",
  },
  "email-delivery": {
    label: "Email delivery",
  },
  "templates": {
    label: "Email templates",
  },
  "sendgrid-events": {
    label: "SendGrid events",
  },
  "secret-manager": {
    label: "Secret manager",
    restrictTo: [SystemUserGroup.SUPPORT],
  },
  "notifications": {
    label: "Notifications",
    restrictTo: [SystemUserGroup.SUPPORT],
  },
};

export const EMAIL_ACTION_TAG_VALUES: { [key in EmailActionTag]: TagConfig } = {
  "create": {
    label: "Create email",
  },
  "review": {
    label: "Send email for review",
  },
  "approve": {
    label: "Approve email",
  },
  "reject": {
    label: "Reject email",
  },
  "back-to-draft": {
    label: "Move email back to draft",
  },
  "send": {
    label: "Send email",
  },
  "delete": {
    label: "Delete email",
  },
  "add-attachment": {
    label: "Add attachment",
  },
  "remove-attachment": {
    label: "Remove attachment",
  },
  "upload-personalisations": {
    label: "Upload personalisations",
  },
  "remove-personalisations": {
    label: "Remove personalisations",
  },
  "add-editor": {
    label: "Add editor",
  },
  "remove-editor": {
    label: "Remove editor",
  },
  "add-reviewer": {
    label: "Add reviewer",
  },
  "remove-reviewer": {
    label: "Remove reviewer",
  },
  "receive-event": {
    label: "Receive event",
  },
};

export const USER_ACTION_TAG_VALUES: { [key in UserActionTag]: TagConfig } = {
  activated: {
    label: "User activated",
    restrictTo: [SystemUserGroup.SUPPORT],
  },
  deactivated: {
    label: "User deactivated",
    restrictTo: [SystemUserGroup.SUPPORT],
  },
};

export const SENDGRID_EVENT_TAG_VALUES: { [key: string]: TagConfig } = {
  processed: {
    label: "processed",
  },
  deferred: {
    label: "deferred",
  },
  delivered: {
    label: "delivered",
  },
  open: {
    label: "open",
  },
  click: {
    label: "click",
  },
  bounce: {
    label: "bounce",
  },
  dropped: {
    label: "dropped",
  },
};
