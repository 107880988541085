import React, { useEffect, useState } from "react";
import { matchSorter } from "match-sorter";
import { useRecoilValue } from "recoil";
import { ListChildComponentProps, VariableSizeList } from "react-window";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Divider from "@material-ui/core/Divider";
import LinearProgress from "@material-ui/core/LinearProgress";
import Fade from "@material-ui/core/Fade";

import { useDesktopBreakpoint } from "../../../hooks/breakpoint";

import {
  PersonalisationsState,
  PersonalisationsStateType,
} from "../../../state/Personalisations";

import TemplatePreview from "../../../components/editor/TemplatePreview";

import useStyles from "./styles";

import { FilterOptionsState } from "@material-ui/lab/useAutocomplete";
import { User, EmailRecipient, EmailContent } from "../../../../../types";

export type RecipientInfo = Pick<User, "id" | "name" | "email">;

export interface TemplatePreviewDialogProps {
  /**
   * Whether the dialog is open.
   *
   * @default false
   */
  open?: boolean;

  /**
   * Callback function to close the dialog.
   */
  onClose?: () => void;

  /**
   * Show a loading indicator.
   */
  loading?: boolean;

  /**
   * The placeholder to show on the search text field.
   */
  searchPlaceholder?: string;

  /**
   * The template string to render.
   */
  template?: string;

  /**
   * The template contents to render.
   */
  contents?: EmailContent[];

  /**
   * If provided, a confirm button will be shown.
   */
  onConfirm?: () => any;

  /**
   * The text to show on the confirm button.
   * @default "SELECT TEMPLATE"
   */
  onConfirmText?: string;

  /**
   * If `true`, the close button will always be shown.
   * By default it is only shown on mobile viewports
   * (since the dialog takes up the whole screen).
   */
  showClose?: boolean;

  /**
   * The text to show on the close button.
   * @default "CLOSE"
   */
  onCloseText?: string;

  /**
   * If `true`, show preview for recipients when available.
   */
  showRecipientPreview?: boolean;
}

/**
 * Custom filter for the Autocomplete component.
 * See https://material-ui.com/components/autocomplete/#advanced.
 *
 * @param options The options to render.
 * @param param1 The filter state of the component.
 */
const filterOptions = (
  options: EmailRecipient[],
  { inputValue }: FilterOptionsState<EmailRecipient>
) =>
  matchSorter(options, inputValue, {
    keys: ["name", "email"],
  });

/**
 * Renders a template preview in a dialog.
 */
export const TemplatePreviewDialog = ({
  open = false,
  onClose,
  loading,
  searchPlaceholder = "Select a recipient",
  template,
  contents,
  onConfirm,
  onConfirmText = "SELECT TEMPLATE",
  showClose,
  onCloseText = "CLOSE",
  showRecipientPreview,
}: TemplatePreviewDialogProps) => {
  const classes = useStyles();

  const desktop = useDesktopBreakpoint();
  const recipientsToShow = useRecoilValue(
    PersonalisationsState(PersonalisationsStateType.RECIPIENTS)
  );

  const [chosenRecipient, setChosenRecipient] = useState<EmailRecipient>();

  // When we have recipients, select the first recipient as default
  useEffect(() => {
    if (recipientsToShow.length > 0) setChosenRecipient(recipientsToShow[0]);
    else setChosenRecipient(undefined);
  }, [recipientsToShow]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullScreen={!desktop}>
      <Fade in={loading}>
        <LinearProgress className={classes.loading} />
      </Fade>
      {showRecipientPreview && chosenRecipient && (
        <>
          <DialogContent className={classes.content}>
            <Typography
              variant="button"
              color="textSecondary"
              className={classes.label}
            >
              RECIPIENT TO PREVIEW
            </Typography>
            <Autocomplete
              classes={{ listbox: classes.listbox }}
              onChange={(e, user: EmailRecipient | null) => {
                setChosenRecipient(user ?? recipientsToShow[0]);
              }}
              disableClearable
              getOptionSelected={(option, value) => {
                return option.name + option.email === value.name + value.email;
              }}
              ListboxComponent={
                ListboxComponent as React.ComponentType<
                  React.HTMLAttributes<HTMLElement>
                >
              }
              value={chosenRecipient}
              autoHighlight
              loadingText="Search user by name or email..."
              options={recipientsToShow}
              filterOptions={filterOptions}
              getOptionLabel={(option: Pick<User, "id" | "name" | "email">) =>
                `${option.name} – ${option.email}`
              }
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  size="small"
                  variant="outlined"
                  placeholder={searchPlaceholder}
                />
              )}
              renderOption={(option: Pick<User, "id" | "name" | "email">) => (
                <>
                  <ListItemAvatar>
                    <Avatar className={classes.avatar}>{option.name[0]}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={option.name} secondary={option.email} />
                </>
              )}
            />
          </DialogContent>
          <Divider />
        </>
      )}
      <div className={classes.content}>
        <TemplatePreview
          template={template}
          contents={contents}
          recipient={chosenRecipient}
          showRecipients={showRecipientPreview}
          showSubject={showRecipientPreview}
        />
        {(showClose || !desktop || onConfirm) && (
          <DialogActions>
            {(showClose || !desktop) && (
              <Button onClick={onClose}>{onCloseText}</Button>
            )}
            {onConfirm && (
              <Button onClick={onConfirm} variant="contained" color="primary">
                {onConfirmText}
              </Button>
            )}
          </DialogActions>
        )}
      </div>
    </Dialog>
  );
};

//
// Virtualize autocomplete
// @see https://material-ui.com/components/autocomplete/#virtualization
//

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], { style });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 64;

  function getHeight() {
    if (itemCount > 8) return 8 * itemSize;
    return itemData.map(() => itemSize).reduce((a, b) => a + b, 0);
  }

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight()}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="div"
          itemSize={(index) => itemSize}
          overscanCount={4}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});
