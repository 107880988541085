import { useEffect, useState } from "react";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import PersonalisationIcon from "@material-ui/icons/ContactMail";

import { ColRef } from "../../../utils";

import { useBoolean } from "../../../hooks/state";
import { useLatestEmailTasks } from "../../../hooks/data/email";
import { useSetRecipients } from "../../../hooks/actions/emailData";

import { useFirestore } from "../../../contexts/firebase/FirebaseContext";

import PersonalisationDialog from "../../../components/dialogs/PersonalisationDialog";

import { EmailRecipient } from "../../../../../types";

export interface EditorViewPersonalisationsActionProps {
  /**
   * The id of the email to preview.
   */
  emailId?: string;

  /**
   * Disables the action button.
   */
  disabled?: boolean;

  /**
   * Show the personalisations as read only.
   */
  readOnly?: boolean;
}

/**
 * This action shows a dialog that shows the personalisations for
 * the current email. If the action is not in `readOnly` mode,
 * the user can also upload new personalisations through the dialog.
 */
export const EditorViewPersonalisationsAction = ({
  emailId,
  disabled,
  readOnly,
}: EditorViewPersonalisationsActionProps) => {
  const store = useFirestore();
  const saveRecipients = useSetRecipients(emailId);

  const [tasks] = useLatestEmailTasks(emailId, "save-recipients", 1);
  const task = tasks?.[0];
  const taskReady = !task || task.status === "complete";

  const [recipients, setRecipients] = useState<EmailRecipient[]>();
  const [shouldFetch, setShouldFetch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [open, onOpen, onClose] = useBoolean();

  // Reset recipients when email id changes
  useEffect(() => {
    setRecipients(undefined);
  }, [emailId]);

  // Set should fetch to true when dialog closes
  // or when task state changes
  useEffect(() => {
    if (!open || taskReady) setShouldFetch(true);
  }, [open, taskReady]);

  // Fetch recipients when the dialog opens
  useEffect(() => {
    if (!open) return;
    if (!shouldFetch) return;
    if (!store) return;
    if (!emailId) return;
    if (!taskReady) return;

    setShouldFetch(false);
    if (!recipients) setLoading(true);

    const ref = store.collection(
      `emails/${emailId}/recipients`
    ) as ColRef<EmailRecipient>;

    ref.get().then((snap) => {
      setRecipients(snap.docs.map((doc) => doc.data()));
      setLoading(false);
    });
  }, [store, open, shouldFetch, emailId, recipients, taskReady]);

  return (
    <>
      <Tooltip title="Personalisations" placement="left">
        <div>
          <IconButton onClick={onOpen} disabled={disabled}>
            <PersonalisationIcon />
          </IconButton>
        </div>
      </Tooltip>
      <PersonalisationDialog
        task={task}
        open={open}
        onClose={onClose}
        loading={loading}
        readOnly={readOnly}
        recipients={recipients}
        onSetRecipients={saveRecipients}
      />
    </>
  );
};
