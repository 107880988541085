import Typography, { TypographyProps } from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

import HelpIcon from "@material-ui/icons/HelpOutline";

import useStyles from "./styles";

export interface PropertyProps {
  className?: string;
  classes?: {
    inner?: string;
  };
  children?: React.ReactNode;

  /**
   * The title of the property.
   */
  title?: string;

  /**
   * By default, child elements are rendered inside a `<Typography />`
   * element. Set this to `true` not render it within `<Typography />`.
   */
  disableTypography?: boolean;

  /**
   * Render a help icon that gives a tooltip with the given help text.
   */
  titleHelp?: string;

  /**
   * Render a tooltip when the value is hovered over.
   */
  valueHelp?: string;

  /**
   * The `<Typography />` display variant.
   *
   * @default "body1"
   */
  variant?: TypographyProps["variant"];

  /**
   * The `<Typography />` color.
   *
   * @default "inherit"
   */
  color?: TypographyProps["color"];
}

/**
 * Renders a property title and value with formatting.
 */
export const Property = ({
  className,
  classes: customClasses,
  title,
  children,
  titleHelp,
  valueHelp,
  disableTypography,
  variant = "body1",
  color = "inherit",
}: PropertyProps) => {
  const classes = useStyles();

  let content = children;

  if (!disableTypography) {
    content = (
      <Typography variant={variant} color={color} component="div">
        {children}
      </Typography>
    );
  }

  if (valueHelp) {
    content = (
      <Tooltip title={valueHelp} placement="bottom-start">
        <div>{content}</div>
      </Tooltip>
    );
  }

  return (
    <div className={className}>
      <Typography variant="caption" color="textSecondary" component="div">
        <div className={classes.caption}>
          {title}
          {titleHelp && (
            <Tooltip
              title={<Typography variant="body2">{titleHelp}</Typography>}
              placement="right"
            >
              <HelpIcon className={classes.icon} color="disabled" />
            </Tooltip>
          )}
        </div>
      </Typography>
      <div className={customClasses?.inner}>{content}</div>
    </div>
  );
};
