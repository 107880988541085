import { ColorPaletteOption, EditorConfig, MentionFeedConfig } from "./types";

import { textPalette, bgPalette } from "./palette";

export enum EditorType {
  RICH_TEXT = "rich-text",
}

/**
 * Builds the configuration for CKEditor.
 */
export class ConfigBuilder {
  private config: EditorConfig = {};

  setToolbarItems(items: string[]) {
    if (!this.config.toolbar) this.config.toolbar = {};
    this.config.toolbar.items = items;
    return this;
  }

  setFontSizes(options: (string | number)[]) {
    if (!this.config.fontSize) this.config.fontSize = {};
    this.config.fontSize.options = options;
    return this;
  }

  setFontColors(colors: ColorPaletteOption[]) {
    if (!this.config.fontColor) this.config.fontColor = {};
    this.config.fontColor.colors = colors;
    return this;
  }

  setTableToolbarItems(contentToolbar: string[]) {
    if (!this.config.table) this.config.table = {};
    this.config.table.contentToolbar = contentToolbar;
    return this;
  }

  setTableBorderColors(borderColors: ColorPaletteOption[]) {
    if (!this.config.table) this.config.table = {};
    if (!this.config.table.tableProperties) this.config.table.tableProperties = {};
    this.config.table.tableProperties.borderColors = borderColors;
    return this;
  }

  setTableDefaultProperties(properties: { [key: string]: string }) {
    if (!this.config.table) this.config.table = {};
    if (!this.config.table.tableProperties) this.config.table.tableProperties = {};
    this.config.table.tableProperties.defaultProperties = properties;
    return this;
  }

  setTableBackgroundColors(backgroundColors: ColorPaletteOption[]) {
    if (!this.config.table) this.config.table = {};
    if (!this.config.table.tableProperties) this.config.table.tableProperties = {};
    this.config.table.tableProperties.backgroundColors = backgroundColors;
    return this;
  }

  setTableCellBorderColors(borderColors: ColorPaletteOption[]) {
    if (!this.config.table) this.config.table = {};
    if (!this.config.table.tableCellProperties)
      this.config.table.tableCellProperties = {};
    this.config.table.tableCellProperties.borderColors = borderColors;
    return this;
  }

  setTableCellBackgroundColors(backgroundColors: ColorPaletteOption[]) {
    if (!this.config.table) this.config.table = {};
    if (!this.config.table.tableCellProperties)
      this.config.table.tableCellProperties = {};
    this.config.table.tableCellProperties.backgroundColors = backgroundColors;
    return this;
  }

  setTableCellDefaultProperties(properties: { [key: string]: string }) {
    if (!this.config.table) this.config.table = {};
    if (!this.config.table.tableCellProperties)
      this.config.table.tableCellProperties = {};
    this.config.table.tableCellProperties.defaultProperties = properties;
    return this;
  }

  addMentionFeed(config: MentionFeedConfig) {
    if (!this.config.mention) this.config.mention = {};
    if (!this.config.mention.feeds) this.config.mention.feeds = [];
    this.config.mention.feeds.push(config);
    return this;
  }

  /**
   * Create a default configuration for the specified editor type.
   *
   * @param type The editor type.
   */
  configure(type: EditorType) {
    // Reset the config
    this.config = {};

    // Configure based on specified type
    switch (type) {
      case EditorType.RICH_TEXT:
        this.setToolbarItems([
          "bold",
          "italic",
          "underline",
          "|",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "|",
          "link",
          "insertTable",
          "|",
          "fontSize",
          "fontColor",
          "alignment",
          "|",
          "strikethrough",
          "horizontalLine",
          "removeFormat",
          "|",
          "undo",
          "redo",
        ]);
        this.setFontSizes([12, "default", 16, 20, 24]);
        this.setFontColors(textPalette);
        this.setTableToolbarItems([
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableProperties",
          "tableCellProperties",
        ]);
        this.setTableBorderColors(textPalette);
        this.setTableBackgroundColors(bgPalette);
        this.setTableCellBorderColors(textPalette);
        this.setTableCellBackgroundColors(bgPalette);
        this.setTableDefaultProperties({
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "#555555",
        });
        this.setTableCellDefaultProperties({
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "#555555",
        });
        break;
    }

    return this;
  }

  /**
   * Builds and returns the editor config.
   */
  build(): EditorConfig {
    return this.config;
  }
}
