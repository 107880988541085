import { SnackbarProvider } from "notistack";

import { version } from "../../../version.json";
import { SYSTEM_ALERTS } from "../../../constants";

import { useEditableEmails, useReviewableEmails } from "../../../state/UserEmails";

import NavigationDrawer from "../../../components/core/NavigationDrawer";
import ReleaseAlertManager from "../../../components/alerts/ReleaseAlertManager";
import SystemAlertManager from "../../../components/alerts/SystemAlertManager";
import NotificationAlertManager from "../../../components/alerts/NotificationAlertManager";

import useStyles from "./styles";

export interface AppShellProps {
  children?: React.ReactNode;
}

/**
 * Wraps the core interface components and loads application data.
 */
export const AppShell = ({ children }: AppShellProps) => {
  const classes = useStyles();

  // Retreive user emails
  useEditableEmails("draft");
  useEditableEmails("review");
  useEditableEmails("approved");
  useEditableEmails("sent");
  useReviewableEmails();

  return (
    <SnackbarProvider>
      <div className={classes.root}>
        <NavigationDrawer />
        <ReleaseAlertManager version={version} />
        <SystemAlertManager alerts={SYSTEM_ALERTS} />
        <NotificationAlertManager />
        <div className={classes.main}>{children}</div>
      </div>
    </SnackbarProvider>
  );
};
