import { useCallback, useState } from "react";

/**
 * Create a `useState` hook that returns the state,
 * a function to set the state as true, and a function
 * to set the state as false.
 *
 * @param defaultState Initial value for the state.
 * @returns `[state, setTrue, setFalse]`
 */
export const useBoolean: (
  defaultState?: boolean
) => [boolean, () => void, () => void] = (defaultState = false) => {
  const [state, setState] = useState<boolean>(defaultState);
  const setTrue = useCallback(() => setState(true), [setState]);
  const setFalse = useCallback(() => setState(false), [setState]);
  return [state, setTrue, setFalse];
};

/**
 * Create a `useState` hook that keeps track of a boolean
 * state and a piece of state.
 *
 * Example usage: keep track of a dialog open/close state
 * that requires a piece of data when open.
 *
 * @param defaultData Initial value for the data.
 * @param defaultState Initial value for the state.
 * @returns `[data, state, setTrue, setFalse]`
 */
export const useBooleanWithState = <T = unknown>(
  defaultData?: T,
  defaultState: boolean = false
): [T | undefined, boolean, (data: T) => void, () => void] => {
  const [state, setTrueInternal, setFalseInternal] = useBoolean(defaultState);
  const [data, setData] = useState<T | undefined>(defaultData);

  /**
   * Set state to true with some data.
   */
  const setTrue = useCallback(
    (data: T) => {
      setData(data);
      setTrueInternal();
    },
    [setTrueInternal, setData]
  );

  return [data, state, setTrue, setFalseInternal];
};
