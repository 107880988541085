import { useState, useEffect, useCallback } from "react";
import { useRecoilState } from "recoil";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import WarningIcon from "@material-ui/icons/Warning";
import SaveIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import FilterIcon from "@material-ui/icons/FilterList";

import {
  SharedUtils,
  ParseError,
  ParseWarning,
  ParseNotice,
  TextUtils,
} from "../../../utils";

import {
  PersonalisationsState,
  PersonalisationsStateType,
} from "../../../state/Personalisations";

import { useMenuAnchor } from "../../../hooks/state";
import { useDesktopBreakpoint } from "../../../hooks/breakpoint";
import { useEmailSettings } from "../../../hooks/data/settings";

import Dropzone from "../../../components/core/Dropzone";
import EmailRecipientList from "../../../components/editor/EmailRecipientList";
import PersonalisationFilterField from "../../../components/editor/PersonalisationFilterField";

import useStyles from "./styles";

import { EmailRecipient } from "../../../../../types";

export interface PersonalisationUploadDialogProps {
  /**
   * Whether the dialog is open.
   *
   * @default false
   */
  open?: boolean;

  /**
   * Callback function to close the dialog.
   */
  onClose?: () => void;

  /**
   * Callback function to upload a valid CSV file with recipients.
   */
  onSave?: (file: File) => void;
}

/**
 * Render a dropzone that can read and parse a CSV file for upload.
 */
export const PersonalisationUploadDialog = ({
  open = false,
  onClose,
  onSave,
}: PersonalisationUploadDialogProps) => {
  const classes = useStyles();
  const desktop = useDesktopBreakpoint();
  const [settings, settingsLoading] = useEmailSettings();

  const [file, setFile] = useState<File>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errors, setErrors] = useState<ParseError[]>();
  const [warnings, setWarnings] = useState<ParseWarning[]>();
  const [blocked, setBlocked] = useState(false);
  const [recipients, setRecipients] = useState<EmailRecipient[]>();
  const [notices, setNotices] = useState<ParseNotice[]>();

  const [anchor, openMenu, closeMenu] = useMenuAnchor();
  const [filterType, setFilterType] = useState<
    "all" | "warning" | "error" | "notice"
  >("all");

  const [recipientsToShow, setRecipientsToShow] = useRecoilState(
    PersonalisationsState(PersonalisationsStateType.UPLOAD)
  );

  /**
   * Callback for when a file drop is accepted.
   *
   * @param files The files that were dropped.
   */
  const onDropAccepted = (files: File[]) => {
    // Only accept first file
    setFile(files[0]);
  };

  /**
   * Clear the personalisation state.
   */
  const clear = () => {
    setRecipientsToShow([]);
    setFile(undefined);
    setRecipients(undefined);
    setErrorMessage(undefined);
    setErrors(undefined);
    setWarnings(undefined);
    setBlocked(false);
    setNotices(undefined);
    setFilterType("all");
  };

  /**
   * Handler for when the user commits the personalisations.
   */
  const onSaveHandler = () => {
    // Pass data to callback
    onSave?.(file!);
    clear();
  };

  // When a file is uploaded, parse the data
  useEffect(() => {
    if (!file) return;
    if (!settings) return;

    setRecipients(undefined);
    setErrorMessage(undefined);
    setErrors(undefined);
    setWarnings(undefined);
    setBlocked(false);
    setNotices(undefined);
    setFilterType("all");

    let cancelled = false;

    // Parse data
    SharedUtils.parsePersonalisations(file, {
      worker: true,
      allowExternal: settings.sendExternalEmails,
    }).then((results) => {
      if (cancelled) return;

      // Set results to state
      const { recipients, errorMessage, parseWarnings, parseErrors, parseNotices } =
        results;
      setRecipients(recipients);
      setErrorMessage(errorMessage);
      setErrors(parseErrors);
      setWarnings(parseWarnings);
      setBlocked(parseErrors.length > 0 || parseWarnings.some((w) => w.blocking));
      setNotices(parseNotices);
    });

    return () => {
      cancelled = true;
    };
  }, [
    file,
    settings,
    setErrorMessage,
    setErrors,
    setWarnings,
    setBlocked,
    setNotices,
    setFilterType,
    setRecipients,
  ]);

  // Function to filter recipients for when the user wants to see only recipients with issues
  const filterFn = useCallback(
    (recipient: EmailRecipient, filterValue: string) => {
      const { name, email } = recipient;
      const matches =
        !filterValue ||
        name.toLowerCase().includes(filterValue) ||
        email.toLowerCase().includes(filterValue);

      if (!matches) return false;

      switch (filterType) {
        case "all":
          return true;
        case "warning":
          return Boolean(
            warnings?.find(
              (warning) => !warning.blocking && warning.id === recipient.id
            )
          );
        case "error":
          return Boolean(
            warnings?.find(
              (warning) => warning.blocking && warning.id === recipient.id
            )
          );
        case "notice":
          return Boolean(notices?.find((notice) => notice.id === recipient.id));
      }
    },
    [filterType, warnings, notices]
  );

  const hasSomeError = Boolean(errors?.length || errorMessage);
  const hasSomeWarning = Boolean(warnings?.length);
  const disabled = Boolean(file && !hasSomeError) || settingsLoading;
  const loading = Boolean(file && !recipients && !hasSomeError);
  const hasParseError = Boolean(file && errors?.length);
  const hasErrorMessage = Boolean(file && errorMessage);
  const hasData = Boolean(file && recipients);
  const hasMailingList = Boolean(notices?.some((n) => n.type === "mailing-list"));

  const noRecipientsToShow = (recipientsToShow?.length ?? 0) === 0;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={!desktop}
    >
      <DialogTitle>Upload Personalisations</DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText variant="body2">
          Provide a CSV file with the columns <b>name</b> and <b>email</b>. Extra
          columns can be provided with additional data. The column names used as
          subsitution tokens in the email.
        </DialogContentText>
        {hasSomeWarning && (
          <Alert severity={blocked ? "error" : "warning"}>
            <AlertTitle>
              <b>You have some {blocked ? "errors" : "warnings"} with your CSV</b>
            </AlertTitle>
            <Typography variant="body2">
              {blocked ? (
                <>
                  You cannot save these recipients until the errors (indicated by a
                  red symbol) are resolved. To view the recipients with errors, use
                  the filter button.
                </>
              ) : (
                <>
                  You can still save these recipients, but it is advised that you
                  check the warnings before proceeding. To view the recipients with
                  warnings, use the filter button.
                </>
              )}
            </Typography>
          </Alert>
        )}
        {hasMailingList && (
          <Alert severity="info" className={classes.noticeAlert}>
            <AlertTitle>
              <b>Mailing Lists</b>
            </AlertTitle>
            <Typography variant="body2" component="div">
              You are sending to one or more mailing lists. Make sure the permissions
              on the mailing lists and any nested mailing lists are set to{" "}
              <b>Anyone Can Post</b> or that the FROM email address from this email
              is an <b>external member</b> of the mailing list.
            </Typography>
          </Alert>
        )}
      </DialogContent>
      {(!hasData || hasSomeError) && (
        <Dropzone
          className={classes.dropzone}
          accept=".csv, application/vnd.ms-excel, text/csv"
          fileTypeName="CSV file"
          multiple={false}
          onDropAccepted={onDropAccepted}
          files={file ? [file] : []}
          disabled={disabled}
        >
          {loading && <LinearProgress />}
          {hasSomeError && (
            <div className={classes.message}>
              <WarningIcon color="error" fontSize="large" />
              {hasParseError && (
                <>
                  <Typography variant="body1" color="textSecondary">
                    Failed to parse CSV file
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="div">
                    {errors?.map((error, index) => (
                      <Typography
                        key={index}
                        variant="caption"
                        color="textSecondary"
                        component="div"
                      >
                        {error.row !== undefined && <b>Row {error.row}: </b>}
                        {error.message}
                      </Typography>
                    ))}
                  </Typography>
                </>
              )}
              {hasErrorMessage && (
                <>
                  <Typography variant="body1" color="textSecondary">
                    Failed to parse CSV data
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {errorMessage}
                  </Typography>
                </>
              )}
            </div>
          )}
        </Dropzone>
      )}
      {hasData && !hasSomeError && (
        <>
          <div className={classes.filterContainer}>
            <PersonalisationFilterField
              stateKey={PersonalisationsStateType.UPLOAD}
              className={classes.filterField}
              reset={open}
              recipients={recipients}
              fullWidth
              size="small"
              placeholder="Search recipient by name or email..."
              filterFn={filterFn}
            />
            <Tooltip
              title={(() => {
                switch (filterType) {
                  case "all":
                    return "Filter options";
                  case "warning":
                    return "Showing recipients with warnings";
                  case "error":
                    return "Showing recipients errors";
                  case "notice":
                    return "Showing recipients with notices";
                }
              })()}
              placement="top"
            >
              <IconButton className={classes.filterIcon} onClick={openMenu}>
                <Badge
                  color="primary"
                  variant="dot"
                  invisible={filterType === "all"}
                  overlap="rectangular"
                >
                  <FilterIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu}>
              <MenuItem
                selected={filterType === "all"}
                onClick={() => {
                  closeMenu();
                  setFilterType("all");
                }}
              >
                Show all recipients
              </MenuItem>
              <MenuItem
                selected={filterType === "warning"}
                onClick={() => {
                  closeMenu();
                  setFilterType("warning");
                }}
              >
                Show recipients with warnings
              </MenuItem>
              <MenuItem
                selected={filterType === "notice"}
                onClick={() => {
                  closeMenu();
                  setFilterType("notice");
                }}
              >
                Show recipients with notices
              </MenuItem>
              <MenuItem
                selected={filterType === "error"}
                onClick={() => {
                  closeMenu();
                  setFilterType("error");
                }}
              >
                Show recipients with errors
              </MenuItem>
            </Menu>
          </div>
          <Divider />
          {noRecipientsToShow ? (
            <div className={classes.message}>
              <Typography variant="body1" color="textSecondary">
                No recipients
              </Typography>
            </div>
          ) : (
            <List disablePadding className={classes.list}>
              <EmailRecipientList
                recipients={recipientsToShow}
                getProps={(recipient) => ({
                  warnings: warnings?.filter((w) => w.id === recipient?.id) ?? [],
                  notices: notices?.filter((n) => n.id === recipient?.id) ?? [],
                })}
              />
            </List>
          )}
          <Divider />
        </>
      )}
      {hasData && (
        <List disablePadding>
          <ListItem
            button={!blocked as any}
            classes={{ gutters: classes.listActionItemGutters }}
            onClick={() => {
              if (!blocked) onSaveHandler();
            }}
          >
            <ListItemIcon>
              {blocked ? (
                <WarningIcon color="error" />
              ) : (
                <SaveIcon className={classes.save} />
              )}
            </ListItemIcon>
            <ListItemText
              primary={
                blocked ? (
                  <b>Please resolve issues</b>
                ) : (recipients?.length ?? 0) === 0 ? (
                  <b>Save to clear current personalisations</b>
                ) : (
                  <b>
                    Save{" "}
                    {TextUtils.pluralWithCount(
                      recipients?.length ?? 0,
                      "personalisation"
                    )}{" "}
                    {(warnings?.length ?? 0) > 0 && (
                      <Typography
                        color="textSecondary"
                        component="span"
                        variant="body2"
                      >
                        <b>
                          (
                          {TextUtils.pluralWithCount(
                            warnings?.length ?? 0,
                            "warning"
                          )}
                          )
                        </b>
                      </Typography>
                    )}
                  </b>
                )
              }
            />
          </ListItem>
          <ListItem
            button
            classes={{ gutters: classes.listActionItemGutters }}
            onClick={clear}
          >
            <ListItemIcon>
              <ClearIcon />
            </ListItemIcon>
            <ListItemText secondary="Clear to upload new CSV" />
          </ListItem>
        </List>
      )}
      <DialogContent className={classes.content}>
        <Typography variant="caption" color="textSecondary" component="div">
          Saving new personalisations will <b>replace</b> existing personalisations.{" "}
          {!hasData && (
            <>You will be asked for confirmation before old data is overwritten.</>
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CANCEL</Button>
      </DialogActions>
    </Dialog>
  );
};
