import { useEffect, useState } from "react";
import clsx from "clsx";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Alert from "@material-ui/lab/Alert";

import CheckIcon from "@material-ui/icons/CheckCircle";
import XIcon from "@material-ui/icons/Cancel";

import ReviewDisplay from "../../core/ReviewDisplay";

import { EmailReview, EmailStatus, User } from "../../../../../types";

import useStyles from "./styles";

export type EditorInfo = Pick<User, "id" | "name" | "email">;

export interface EditorSelectDialogProps {
  children?: React.ReactNode;

  /**
   * Whether the dialog is open.
   *
   * @default false
   */
  open?: boolean;

  /**
   * Callback function to close the dialog.
   */
  onClose?: () => void;

  /**
   * The title to show on the dialog.
   */
  title?: string;

  /**
   * The currently selected editors.
   */
  selected?: EditorInfo[];

  /**
   * The users to choose from.
   */
  options?: EditorInfo[];

  /**
   * Callback for when the selected reviewers are updated. Note that this
   * callback is invoked whenever the dialog is closed.
   */
  onUpdate?: (reviewerIds: string[]) => void;

  /**
   * Callback for when the action button is clicked. Note that this callback
   * should also close the dialog as it is not done automatically.
   */
  onAction?: (reviewerIds: string[]) => void;

  /**
   * The reviews that have been applied
   */
  reviews?: EmailReview[] | undefined;

  /**
   * The mode the editor is in.
   */
  mode?: EmailStatus | "pending" | "view";

  /**
   * If `true`, selected participants will be shown but
   * all editing functions will be disabled.
   */
  readOnly?: boolean;

  /**
   * The label to show on the action button.
   * @default "SUBMIT"
   */
  actionLabel?: string;

  /**
   * The placeholder to show on the search text field.
   */
  searchPlaceholder?: string;

  /**
   * The caption to show above the participant list.
   */
  listTitle?: string;

  /**
   * The message to show above the participant list if the list is empty.
   * @default "NONE SELECTED"
   */
  emptyMessage?: string;

  /**
   * The message to show as a tooltip over the remove participant button.
   */
  removeLabel?: string;

  /**
   * Error messages to show on the dialog.
   */
  errors?: string[];
}

/**
 * Renders a dialog that allows the user to select a list of users to
 * add as editors. Callback functions can be provided to customise what
 * happens when an editor is added or removed.
 */
export const EditorSelectDialog = ({
  open = false,
  onClose,
  title = "Editors",
  children,
  selected,
  options,
  reviews,
  onUpdate,
  onAction,
  mode,
  readOnly,
  emptyMessage = "NONE AVAILABLE",
  actionLabel = "SUBMIT",
  errors,
}: EditorSelectDialogProps) => {
  const classes = useStyles();

  const [checked, setChecked] = useState<Set<string>>(new Set());
  const [checkedAll, setCheckedAll] = useState(false);

  const latestReview = reviews?.[0];

  // Initialise checked state
  useEffect(() => {
    if (!selected) return;
    setChecked(new Set(selected.map((e) => e.id)));
  }, [selected]);

  // Change select all when everything is selected/unselected
  useEffect(() => {
    setCheckedAll(checked.size === options?.length);
  }, [checked, options]);

  // Update select all state
  const selectAllHandler = () => {
    setCheckedAll((previousCheckedAll) => !previousCheckedAll);
    if (!options) return;
    if (checked.size === options.length) setChecked(new Set());
    else setChecked(new Set(options.map((e) => e.id)));
  };

  // Persist changes when the dialog is closed
  const onCloseHandler = () => {
    onClose?.();
    if (!readOnly) onUpdate?.(Array.from(checked));
  };

  return (
    <Dialog open={open} onClose={onCloseHandler} maxWidth="xs" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.reviews}>
        <ReviewDisplay reviews={reviews} mode={mode} />
      </DialogContent>
      {children &&
        (typeof children === "string" ? (
          <DialogContent className={classes.children}>
            <DialogContentText className={classes.childrenText}>
              {children}
            </DialogContentText>
          </DialogContent>
        ) : (
          children
        ))}
      {(errors?.length ?? 0) > 0 && (
        <DialogContent className={classes.errors}>
          <Alert severity="error">
            {errors?.map((error, idx) => (
              <Typography key={idx} variant="body2">
                {error}
              </Typography>
            ))}
          </Alert>
        </DialogContent>
      )}
      <DialogContent className={classes.list}>
        <List disablePadding>
          {(options?.length ?? selected?.length ?? 0) === 0 && (
            <ListItem disableGutters>
              <ListItemText
                primary={emptyMessage}
                primaryTypographyProps={{
                  variant: "button",
                  color: "textSecondary",
                }}
              />
            </ListItem>
          )}
          {!readOnly && (options?.length ?? 0) > 0 && (
            <ListItem
              button
              onClick={selectAllHandler}
              selected={checkedAll}
              className={clsx(classes.item, classes.selectAll)}
            >
              <ListItemText secondary="Select all" />
              <ListItemSecondaryAction>
                <Checkbox
                  edge="start"
                  checked={checkedAll}
                  onChange={selectAllHandler}
                />
              </ListItemSecondaryAction>
            </ListItem>
          )}
          {(readOnly ? selected : options)?.map((editor) => {
            const toggle = () => {
              if (readOnly) return;
              setChecked((checked) => {
                const newState = new Set(checked);
                if (checked.has(editor.id)) newState.delete(editor.id);
                else newState.add(editor.id);
                return newState;
              });
            };
            return (
              <ListItem
                key={editor.id}
                className={clsx(classes.item, { [classes.itemPadding]: !readOnly })}
                selected={!readOnly && checked.has(editor.id)}
                onClick={toggle}
                {...(!readOnly
                  ? ({ button: true, disableRipple: true } as any)
                  : null)}
              >
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>{editor.name[0]}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={editor.name} secondary={editor.email} />
                {!readOnly ? (
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="start"
                      name={editor.id}
                      checked={checked.has(editor.id)}
                      onChange={toggle}
                    />
                  </ListItemSecondaryAction>
                ) : latestReview?.reviewedBy === editor.id ? (
                  <ListItemSecondaryAction>
                    {latestReview.approved ? (
                      <CheckIcon className={classes.approved} />
                    ) : (
                      <XIcon className={classes.rejected} />
                    )}
                  </ListItemSecondaryAction>
                ) : null}
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseHandler}>CLOSE</Button>
        {!readOnly && (
          <Button
            color="primary"
            variant="contained"
            disabled={checked.size === 0}
            onClick={() => onAction?.(Array.from(checked))}
          >
            {actionLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
