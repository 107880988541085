import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) =>
  createStyles({
    reviewers: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    reviewTitle: {
      marginBottom: 0,
    },
    reviewHistory: {
      alignSelf: "center",
    },
    icon: {
      minWidth: 40,
    },
    // History dialog
    title: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    approved: {
      color: theme.palette.success.main,
    },
    rejected: {
      color: theme.palette.error.main,
    },
    old: {
      opacity: 0.6,
    },
  })
);
